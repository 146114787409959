import PostGraphileApi from "../../utils/PostGraphileApi"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

// Main function to orchestrate voyage and route insertion
export default async function Pathfinder({
  voyagePlannerPoints,
  selectedDateTime,
  basespeedInput,
  setLoadingPathfinder,
  setPathfinderResponse,
}) {
  // console.log("voyagePlannerPoints", voyagePlannerPoints)
  if (voyagePlannerPoints.features.length < 2) {
    // console.log("voyagePlannerPoints < 2")
    return
  }
  // console.log(dayjs(selectedDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
  const assetPath = "/home/ww-admin/rust/astar/src/assets/"
  const importPath = "/home/ww-admin/data/"
  const departureTime = `${dayjs(selectedDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ")}`
  const departurePoint = `[${voyagePlannerPoints.features[0].geometry.coordinates}]`
  const arrivalPoint = `[${voyagePlannerPoints.features[1].geometry.coordinates}]`
  const basespeedMsec = parseInt(basespeedInput, 10)
  const data = `/home/ww-admin/rust/astar/target/release/astar '{"departure":{"lonlat":${departurePoint},"time":"${departureTime}"},"arrival":{"lonlat":${arrivalPoint}},"weights":{"distance":0.0,"time":1.0},"vessel":{"basespeed_msec":${basespeedMsec}},"water_layer":{"shallowwater_malus":15.0},"wind_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,5.0,10.0,15.0,20.0,25.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"GFS/GFS_U_V_merged.nc","active":true,"danger_greater_kmh":50.0,"danger_greater_malus":1.0},"wave_layer":{"speedprofile_rows_angles":[0.0,45.0,90.0,135.0,180.0,225.0,270.0,315.0,360.0],"speedprofile_columns_strengths":[0.0,1.0,2.0,3.0,4.0,5.0],"speedprofile_percent_speeds":[[100.0,100.0,100.0,95.0,85.0,75.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,102.0,105.0,110.0],[100.0,100.0,100.0,100.0,102.0,105.0],[100.0,100.0,100.0,100.0,100.0,100.0],[100.0,100.0,100.0,100.0,90.0,85.0],[100.0,100.0,100.0,95.0,85.0,75.0]],"file":"NWW3/NWW3_W-HSSW_W-DMEAN_merged.nc","active":true,"danger_greater_meter":4.0,"danger_greater_malus":1.0},"current_layer":{"file":"HYCOM/HYCOM_UCUR_VCUR_merged.nc","active":true,"danger_greater_kmh":75.0,"danger_greater_malus":1.0},"routeanalysis":{"waypoints":false},"config":{"asset_path":"${assetPath}","import_path":"${importPath}"}}'`
  // console.log(data)

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  }

  PostGraphileApi.fetchRequest("http://192.168.10.237:5000/pathfinder", request, "Error: ")
    .then((response) => {
      setLoadingPathfinder(false)
      setPathfinderResponse(response)
      // console.log("Pathfinder response:", response)
    })
    .catch((error) => {
      setLoadingPathfinder(false)
      console.error("Request failed:", error)
    })
}
