import React, { useMemo } from "react"
import { Typography } from "@mui/material"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

function formatDate(sliderPosition, sliderTicks) {
  if (sliderPosition.value.length !== 0) {
    const date = dayjs.utc(sliderPosition.label).format("ddd D MMM YYYY HH:mm UTC")
    // console.log("formatDate", sliderPosition.label, date)
    return date
  } else if (sliderTicks) {
    const date = dayjs.utc(sliderTicks[sliderTicks.length - 1].label).format("ddd D MMM YYYY HH:mm UTC")
    // console.log("formatDate", sliderPosition.label, date)
    return date
  }
}

export default function SliderDate({ sliderPosition, sliderTicks }) {
  const formattedDate = useMemo(() => {
    // console.log("sliderDate", sliderPosition)
    return formatDate(sliderPosition, sliderTicks)
  }, [sliderPosition])

  return (
    <Typography variant="h6" sx={{ color: "#004f7d", whiteSpace: "nowrap", ml: 2, mr: 2 }}>
      {formattedDate}
    </Typography>
  )
}
