import React, { useCallback } from "react"
import { GeoJSON, LayerGroup } from "react-leaflet"
import RouteHelper from "../../utils/routeHelper"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

export default function RouteLines({ vesselRoutes, mapBounds }) {
  const getStyle = useCallback(({ feature, routeType, isStroke }) => {
    if (routeType === "real") {
      let dashArray =
        feature.properties.analyse.wp2.properties &&
        feature.properties.analyse.wp2.properties.analyse.is_gap_time_by_timestamps
          ? "5,5"
          : "0"
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor(routeType),
        weight: isStroke ? 10 : 8,
        opacity: isStroke ? 1 : 0.6,
        dashArray: dashArray,
        pane: "markerPane",
        zIndex: isStroke ? 1020 : 1020,
        stroke: true,
      }
    } else if (routeType === "plan" || routeType === "suggest") {
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor(routeType), // White stroke, green line
        weight: isStroke ? 10 : 8, // Thicker stroke, thinner green line on top
        opacity: isStroke ? 1 : 0.6, // Fully opaque stroke and line
        dashArray: "0", // Solid line
        pane: "markerPane",
        zIndex: isStroke ? 1010 : 1010, // Ensure stroke is below the green line
        stroke: true,
      }
    } else if (routeType === "Pathfinder") {
      return {
        color: isStroke ? "white" : RouteHelper.findRouteColor("Pathfinder"),
        weight: isStroke ? 10 : 8,
        opacity: isStroke ? 1 : 0.6,
        dashArray: "0",
        pane: "markerPane",
        zIndex: isStroke ? 1000 : 1010,
        stroke: true,
      }
    }
  }, [])

  return vesselRoutes === null ? null : (
    <LayerGroup>
      {vesselRoutes.map((route) => {
        let routeLine = route.lines
        let lineGeojson = routeLine.geojson

        return Object.keys(routeLine).length === 0 ? null : (
          <>
            {/* Render the white stroke */}
            <GeoJSON
              key={`RouteLines-Stroke-${routeLine.routeId}-${mapBounds}-${JSON.stringify(routeLine.geojson.features)}`}
              interactive={false}
              data={lineGeojson}
              style={(feature) => getStyle({ feature, routeType: routeLine.routeType, isStroke: true })}
            />
            {/* Render the green line on top */}
            <GeoJSON
              key={`RouteLines-Line-${routeLine.routeId}-${mapBounds}-${JSON.stringify(routeLine.geojson.features)}`}
              interactive={false}
              data={lineGeojson}
              style={(feature) => getStyle({ feature, routeType: routeLine.routeType, isStroke: false })}
            />
          </>
        )
      })}
    </LayerGroup>
  )
}
