import { createTileLayerComponent, updateGridLayer, withPane } from "@react-leaflet/core"
import { TileLayer } from "leaflet"

// Function to fetch images with header support
async function fetchImage(url, headers) {
  const fetchOptions = {
    method: "GET",
    headers: headers,
    mode: "cors",
  }
  const response = await fetch(url, fetchOptions)

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response.blob()
}

// Custom TileLayer for handling WMS services with header support
const LWMSTileLayerWithHeader = TileLayer.WMS.extend({
  initialize: function (url, options) {
    const { headers, ...props } = options
    TileLayer.WMS.prototype.initialize.call(this, url, props)
    this.headers = headers
  },
  createTile: function (coords, done) {
    // return tile
    const url = this.getTileUrl(coords)
    const img = document.createElement("img")
    img.setAttribute("role", "presentation")

    // Fetch the image and set the src attribute
    fetchImage(url, this.headers)
      .then((blob) => {
        // console.log("blob", blob)
        const reader = new FileReader()
        reader.onload = () => {
          img.src = reader.result
          // console.log("reader", url, img)
          done(null, img)
        }
        reader.onerror = (e) => {
          console.error(`FileReader error at URL: ${url}`, e)
          done(e, img)
        }
        reader.readAsDataURL(blob)
      })
      .catch((error) => {
        console.error(`Fetch error at URL: ${url}`, error)
        done(error, img)
      })

    return img
  },
})

// React component factory for the custom WMS TileLayer with headers
export const WMSTileLayerWithHeader = createTileLayerComponent(
  function createWMSTileLayer({ params = {}, url, ...options }, context) {
    return {
      instance: new LWMSTileLayerWithHeader(url, {
        ...params,
        ...withPane(options, context),
      }),
      context,
    }
  },
  function updateWMSTileLayer(layer, props, prevProps) {
    updateGridLayer(layer, props, prevProps)

    if (props.params != null && props.params !== prevProps.params) {
      layer.setParams(props.params)
    }
  }
)

// // Constants for concurrency control and retry logic
// const MAX_CONCURRENT_REQUESTS = 500
// const RETRY_ATTEMPTS = 2

// let activeRequests = 0
// const requestQueue = []

// // Function to fetch images with abort functionality using the Fetch API
// async function fetchImage(url, callback, headers, abortSignal, retries = RETRY_ATTEMPTS) {
//   const controller = new AbortController()
//   const fetchOptions = {
//     method: "GET",
//     headers: headers,
//     mode: "cors",
//     signal: abortSignal ? abortSignal : controller.signal,
//   }

//   const executeRequest = () => {
//     if (activeRequests < MAX_CONCURRENT_REQUESTS) {
//       activeRequests++
//       attemptFetch(0)
//     } else {
//       requestQueue.push(executeRequest)
//     }
//   }

//   const processQueue = () => {
//     if (requestQueue.length > 0) {
//       const nextRequest = requestQueue.shift()
//       nextRequest()
//     }
//   }

//   async function attemptFetch(attempt) {
//     try {
//       // console.log(url)
//       const response = await fetch(url, fetchOptions)
//       activeRequests--
//       processQueue()

//       if (!response.ok) {
//         console.error(`HTTP error! status: ${response.status}, url: ${url}`)
//         throw new Error(`HTTP error! status: ${response.status}`)
//       }

//       const contentType = response.headers.get("Content-Type")
//       if (contentType && contentType.includes("application/vnd.ogc.se_xml")) {
//         const text = await response.text()
//         console.error("OGC Service Exception:", text)
//         if (attempt < retries) {
//           console.warn(`Retrying request after contentType ${contentType}: ${url}, attempts left: ${retries - attempt}`)
//           setTimeout(() => attemptFetch(attempt + 1), 0) // Optional: add delay before retrying
//         } else {
//           activeRequests--
//           processQueue()
//           callback(null, new Error("OGC Service Exception"))
//         }
//         return
//       }

//       const blob = await response.blob()
//       // if (blob.size < 1785) {
//       //   console.warn(`Request after small blob.size ${blob.size}: ${url}`)
//       //   if (attempt < retries) {
//       //     console.warn(
//       //       `Retrying request after small blob.size ${blob.size}: ${url}, attempts left: ${retries - attempt}`
//       //     )
//       //     setTimeout(() => attemptFetch(attempt + 1), 0) // Optional: add delay before retrying
//       //   } else {
//       //     activeRequests--
//       //     processQueue()
//       //     // callback(null, new Error("OGC Service Exception"))
//       //   }
//       // }
//       callback(blob)
//     } catch (error) {
//       console.error("Fetch error:", error, `url: ${url}, attempt: ${attempt}`)
//       if (attempt < retries) {
//         console.warn(`Retrying request after error: ${url}, attempts left: ${retries - attempt}`)
//         setTimeout(() => attemptFetch(attempt + 1), 0) // Optional: add delay before retrying
//       } else {
//         activeRequests--
//         processQueue()
//         callback(null, error)
//       }
//     }
//   }
//   executeRequest()
// }

// // Custom TileLayer for handling WMS services with header support and abort functionality
// const LWMSTileLayerWithHeader = TileLayer.WMS.extend({
//   initialize: function (url, options) {
//     const { headers, abortSignal, ...props } = options
//     TileLayer.WMS.prototype.initialize.call(this, url, props)
//     this.headers = headers
//     this.abortSignal = abortSignal
//   },
//   createTile: function (coords, done) {
//     const url = this.getTileUrl(coords)

//     const img = document.createElement("img")
//     img.setAttribute("role", "presentation")

//     fetchImage(
//       url,
//       (resp, err) => {
//         if (err) {
//           console.error(`Fetch error at URL: ${url}`, err)
//           done(err, img)
//           return
//         }

//         const reader = new FileReader()
//         reader.onload = () => {
//           img.src = reader.result
//           done(null, img)
//         }
//         reader.onerror = (e) => {
//           console.error(`FileReader error at URL: ${url}`, e)
//           done(e, img)
//         }
//         reader.readAsDataURL(resp)
//       },
//       this.headers,
//       this.abortSignal
//     )

//     return img
//   },
// })

// // React component factory for the custom WMS TileLayer with headers
// export const WMSTileLayerWithHeader = createTileLayerComponent(
//   function createWMSTileLayer({ params = {}, url, ...options }, context) {
//     return {
//       instance: new LWMSTileLayerWithHeader(url, {
//         ...params,
//         ...withPane(options, context),
//       }),
//       context,
//     }
//   },
//   function updateWMSTileLayer(layer, props, prevProps) {
//     updateGridLayer(layer, props, prevProps)

//     if (props.params != null && props.params !== prevProps.params) {
//       layer.setParams(props.params)
//     }
//   }
// )

// // import { createTileLayerComponent, updateGridLayer, withPane } from "@react-leaflet/core"
// // import { TileLayer } from "leaflet"

// // // Function to fetch images with abort functionality using the Fetch API
// // async function fetchImage(url, callback, headers, abortSignal) {
// //   const controller = new AbortController() // Create a new abort controller
// //   const fetchOptions = {
// //     method: "GET", // HTTP method for fetching data
// //     headers: headers, // Custom headers for authentication or other purposes
// //     mode: "cors", // Ensure requests adhere to Cross-Origin Resource Sharing (CORS) rules
// //     signal: abortSignal ? abortSignal : controller.signal, // Use provided abort signal, or controller's signal
// //   }

// //   try {
// //     const response = await fetch(url, fetchOptions) // Execute the fetch request
// //     if (!response.ok) {
// //       // Check if the server response was successful
// //       throw new Error(`HTTP error! status: ${response.status}`) // Throw an error if not successful
// //     }
// //     const contentType = response.headers.get("Content-Type")
// //     console.log("tile response with content type:", contentType)
// //     if (contentType && contentType.includes("application/vnd.ogc.se_xml")) {
// //       // If the response is an OGC Service Exception, log the error
// //       const text = await response.text()
// //       console.error("OGC Service Exception:", text)
// //       console.log(url, fetchOptions)

// //       callback(null, new Error("OGC Service Exception"))
// //       return
// //     }

// //     const blob = await response.blob() // Convert the response to a Blob object
// //     // console.log(blob)

// //     // // Create an object URL for the blob
// //     // const objectUrl = URL.createObjectURL(blob)

// //     // // Create an anchor element and set the href attribute to the object URL
// //     // const a = document.createElement("a")
// //     // a.href = objectUrl
// //     // a.download = "src/assets/image.png" // Set the desired file name
// //     // document.body.appendChild(a) // Append the anchor element to the DOM
// //     // a.click() // Programmatically click the anchor to trigger the download
// //     // document.body.removeChild(a) // Remove the anchor element from the DOM

// //     // // Revoke the object URL after the download
// //     // URL.revokeObjectURL(objectUrl)
// //     callback(blob) // Pass the blob to the callback function
// //   } catch (error) {
// //     console.error("Fetch error:", error) // Log any errors to the console
// //     callback(null, error) // Pass errors through the callback for handling in the UI
// //   }
// // }

// // // Custom TileLayer for handling WMS services with header support and abort functionality
// // const LWMSTileLayerWithHeader = TileLayer.WMS.extend({
// //   initialize: function (url, options) {
// //     const { headers, abortSignal, ...props } = options // Extract headers and abortSignal from options, pass the rest to TileLayer
// //     TileLayer.WMS.prototype.initialize.call(this, url, props) // Initialize the parent WMS layer with the remaining properties
// //     this.headers = headers // Store headers for later use in requests
// //     this.abortSignal = abortSignal // Store the abort signal for canceling requests
// //   },
// //   createTile: function (coords, done) {
// //     const url = this.getTileUrl(coords) // Construct the URL for the tile based on the coordinates

// //     const img = document.createElement("img") // Create an image element to load the tile
// //     img.setAttribute("role", "presentation") // Set role for accessibility, indicating this is not a key image

// //     fetchImage(
// //       url,
// //       (resp, err) => {
// //         if (err) {
// //           console.error(`Fetch error at URL: ${url}`, err)
// //           done(err, img) // Handle errors by calling the done function with the error
// //           return
// //         }
// //         const reader = new FileReader()
// //         reader.onload = () => {
// //           img.src = reader.result // Set the image source to the data URL read by FileReader
// //           done(null, img) // Indicate successful tile creation with no errors
// //         }
// //         reader.onerror = (e) => {
// //           console.error(`FileReader error at URL: ${url}`, e)
// //           done(e, img) // Handle FileReader errors
// //         }
// //         reader.readAsDataURL(resp) // Read the response Blob as a data URL
// //       },
// //       this.headers,
// //       this.abortSignal
// //     )

// //     return img // Return the image element for placement in the map
// //   },
// // })

// // // React component factory for the custom WMS TileLayer with headers
// // export const WMSTileLayerWithHeader = createTileLayerComponent(
// //   // Create the custom tile layer instance with provided options and context from React-Leaflet
// //   function createWMSTileLayer({ params = {}, url, ...options }, context) {
// //     return {
// //       instance: new LWMSTileLayerWithHeader(url, {
// //         ...params, // Spread any specific WMS parameters
// //         ...withPane(options, context), // Integrate with pane options for layer control
// //       }),
// //       context,
// //     }
// //   },
// //   function updateWMSTileLayer(layer, props, prevProps) {
// //     updateGridLayer(layer, props, prevProps) // Update the underlying grid layer with new properties

// //     // Update WMS parameters if they have changed
// //     if (props.params != null && props.params !== prevProps.params) {
// //       layer.setParams(props.params)
// //     }
// //   }
// // )
