import React, { useEffect, useState } from "react"
import { ImageOverlay, useMap } from "react-leaflet"
import L from "leaflet"

const ImageOverlayWithHeader = ({ url, bounds, headers, onLoad, ...options }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const map = useMap()

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(url, {
          headers: headers,
        })
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        setImageUrl(objectURL)
        if (onLoad) {
          onLoad()
        }
      } catch (error) {
        console.error("Error fetching image:", error)
      }
    }

    fetchImage()
  }, [url, headers, onLoad])

  if (!imageUrl) return null

  return <ImageOverlay url={imageUrl} bounds={bounds} {...options} />
}

export default ImageOverlayWithHeader
