// VS Code now reports Typed Javascript types and type errors //@ts-check
// https://dev.to/sumansarkar/how-to-use-jsdoc-annotations-with-vscode-for-intellisense-7co
// https://code.visualstudio.com/docs/languages/javascript#_jsdoc-support

// -------------------------------------------------------------------------------------------------------

// Def: route moving data :<-> unordered set of waypoints (s. Marine Informatics, Page X)

// Def: antimeridian waypoints :<-> klar

// Def: trajectory segments :<-> klar (siehe Marine Informatics, Page X)

// Def: (simple) line(string/segment) :<-> line(string) between to neighbor route waypoints

// Def: greatcircle linesegment :<-> simple line in greatcircle linestring by nPoints

// Def: original route :<-> route with legtype in {'greatcircle', 'rhumbline'}

// Def: extended route :<-> original route waypoints + rhumbline antimeridian points + greatcircle linesegments points (hires nPoints)

// Def: routen typen/klassen :<-> real/plan/suggest/reanalyse_streckenwetter/forecast_streckenwetter/warn/info,... routen

// Def: extended legtypes in {'greatcircle', 'rhumbline', 'greatcircle_linesegment', 'greatcircle_antimeridian', 'rhumbline_antimeridian'}

// Def: legtype handler
//  - 'legtype' = legtype beachten
//  - 'rhumbline' = legtype ignorieren/nur rhumblines

// Bem: antimeridian waypoints <-> no characteristic points!!!

// Bem: extended route
//   <-> z.b. fuer route rendern
//   <-> legtype oft irrelevant

// Bem: route length=0 or 1 -> FERTIG
// Bem: vessel brauchen keine bounding box
// Bem: vessel class/type/object/(geo-)json/component -> 'route geojson with one waypoint'

// Bem: raster to isolinien/iospolygone -> marching squares algorithmus

// Bem: 'Visual Analytics of Movement' buch kap 3 ...

// Bem: CII – Carbon Intensity Indicator
//   https://www.dnv.com/maritime/insights/topics/CII-carbon-intensity-indicator/index.html
//   https://www.imo.org/en/OurWork/Environment/Pages/Improving%20the%20energy%20efficiency%20of%20ships.aspx

// pathfinder/multiband
// gegeben: vessel geplanter vessel-speed + current-speed
// -> bei fester zeit dann vektor addition der beiden vessel und current distanzen
// -> ergibt neuen geschätzten/'realen' vessel speed
// -> auf raster in pathfinder/multiband anwenden
// -> diese N8-schritte im pathfinder raster bewerten für speed/verbrauch mit geschätzten/'realen' vessel-speed und geplantem vessel-speed

// meteodb:
// aus wetter raster die globalen hochaufgelösten isolinien/polygonen berechnen
// -> NACHTRÄGLICH als naechster pipeline schritt diese isolinien/polygone zurechtschneiden mit boundingbox ('weather cutter')

// Bem: 'Visual Analytics of Movement' buch: interpolate, resample, simplify, filter, ... funktionen

// -------------------------------------------------------------------------------------------------------
// TODOs
// -------------------------------------------------------------------------------------------------------

// neues analyse-feld 'parent_waypoint_indices' fuer eingefuegte greatcircle_linesegment und rhumbline_antimeridian points

// ACHTUNG/routen edit???:
// in postgresDB gespeicherte (teil-)routen sind nicht mehr aenderbar, da sonst die gesamten (teil-)routen neu analysiert/berechnet werden muessen
// -> arbeiten mit (teil-)routen kopien bei aenderungen???
// -> komplette routen neuberechnung mit shifing window on complete route???

// ACHTUNG/postgres load teilroute???:
// loadPostgresSubRouteByTime(fromDate,toDate,...)
// -> moeglichst grosse teilroute in triton laden (wegen performance) (->diese timerange in route als globaler analyse-key speichern)
// erst wenn triton gui timerange ausserhalb dieser grossen timerange, wieder route neu aus db laden

// SlowStopRasterCluster() in DB mit trigger???

// vermehrtes/alternatives speichern von waypoint indices in analyse objekten?

// funktionen zusammenfassen???
// in JSON: true vs false/null (performance?)?? -> nein! wegen neuberechnungen und ruecksetzungen auf false

// setAnalyseParameter(route,waypoint,key,value) // i.e. temperature, wind, current, ... <- z.b. streckenwetter query
// setInterpolateAnalyseParameterOnRouteWaypoints(route,fromPoint,toPoint,parameterKey) obsolet, wenn alle punkte ergaenzt in route+streckenwetter query???

// addPointsToRoute(route,points) // points = array of waypoints, i.e. characteristic points and simplify points or boundingBoxCutPoints

// ??? routePoints=RouteAnalyser.markSubRoute(waypoint1,waypoint2) <-> markBetweenTimeRange()

// postgres trigger function: meteodb reanalyse inserts on new route waypoints -> postgres function with shifting/sliding window

// moveVesselToNearestRoutePoint() -> turf.nearestPointOnLine()?

// TODO wegen streckenwetter: addEquidistantRoutePointsByTime(route,starttime,timestep) -> mit setRouteInterpolatePointByTime()

// TODO addRealWaypointToRoute(route,real_waypoint)
// -> aktuelle vessel position (z.b. aus daily report) in plan-/suggestroute einzufuegen als startpunkt für weitere analyse ab real_waypoint
// -> suche kuerzeste Distanz zur route!!!
// -> TODO RouteAnalyser.getNearestPointOnLine(route,waypoint)
//   -> turf.nearestPointOnLine() liefert tupel (index!!!,distance,location)
// -> add real_waypoint nach index in route mit legtype='real'
// Bem: erwartbare!!! real_waypoint/IST Abweichungen von planroute/SOLL wegen ungeplanten kurswechseln oder speed aenderungen
// Bem: real_waypoint hat eigenen, von route UNABHAENGIGEN timestamp und eigene UNABHAENGIGE position!!!
// Bem: vessel kann sogar mehrere routen waypoints durch hohen speed 'ueberholt' haben!!! (oder weit zurueckgefallen sein)
// Bem: ab real_waypoint sind nachfolgende timestamps problematisch:
//   -> ENTWEDER zeitlich an naechsten waypoint anpasssen durch speed aenderung (sofern noch moeglich)
//      ODER alle folgenden timestamps verschieben sich (z.b. wenn vessel waypoints 'ueberholt' hat wegen hohem nicht geplantem speed)
//   -> ausserdem die original timestamps in waypoints 'retten'
// Bem: original planroute durch entfernen aller waypoints mit legtype='real' UND wiederherstellung aller in waypoints gespeicherten, ALTEN timestamps rekonstuierbar
// Bem: weitere Probleme: eingefuegter real_waypoint ist zu dicht oder gleich naechster routen waypoint, fahrt über Land, ...

// setRelativeTimestampsByPositions(routePoints)??? bySog???

// ??? markMapViewport(???bbox/RouteAnalyser.BBOX_MAP_TEST) -> key is_map_viewport <- calculate from other bboxes (alternative fuer within, overlap)

// routePoints=RouteAnalyser.markTypeXCharacteristicPoints(routePoints,...)

// -------------------------------------------------------------------------------------------------------

import { List, Map, Set, fromJS } from "immutable" // ,Map,getIn,isList
import Ajv from "ajv" // JSON schema validator
import * as turf from "@turf/turf" // GIS/GeoJSON library
import splitGeoJSON from "geojson-antimeridian-cut" // antimeridian cut for rhumbline
import IntervalTree from "@flatten-js/interval-tree" // interval binary search tree according to Cormen et al.
import { isArray } from "lodash"

import REPORT_ROUTES from "./test_routes/TestReportRoutes1"
//import PLAN_ROUTES from "./TestPlanRoutes"
//import FORCAST_ROUTES from "./TestForecastRoutes"

// dayjs imports and plugins:
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(utc)
dayjs.extend(isBetween)

// -------------------------------------------------------------------------------------------------------

const RouteAnalyser = {
  /**
   * THRESHOLDS constants
   * - seg_distance_in_km -> greatcircle nPoints * linesegments
   */
  THRESHOLDS: {
    gap_distance: 0.1,
    low_distance: 0.1,
    gap_time: 901.0,
    low_time: 300,
    low_speed_by_sog: 1.0,
    low_speed_by_distance_timestamps: 1.0,
    seg_distance_in_km: 1, //1.0,
  },

  BBOX_MAP_TEST: turf.polygon([
    [
      [-180, -90],
      [180, -90],
      [180, 90],
      [-180, 90],
      [-180, -90],
    ],
  ]),

  // murks!!!: CHARACTERISTIC_KEYS:Set(["is_simplify", "is_antimeridian"]), //], is_gap_distance, is_low_distance, is_gap_time, is_low_time, is_low_speed_by_sog, is_low_speed_by_distance_timestamps]),

  // JSON schema for a single route:
  SCHEMA_REPORT_ROUTE: {
    //    type:"array",
    //    items: {
    type: "object",
    properties: {
      routeId: { type: "integer" }, // 'global' route key
      routeType: { type: "string" }, // 'global' route key
      vesselName: { type: "string" }, // 'global' route key
      geojson: {
        type: "object",
        properties: {
          type: { type: "string" },
          features: {
            type: "array",
            items: {
              type: "object",
              properties: {
                type: { type: "string" },
                geometry: {
                  type: "object",
                  properties: {
                    type: { type: "string" },
                    coordinates: {
                      type: "array",
                      items: { type: "number" },
                    },
                  },
                  required: ["type", "coordinates"],
                  additionalProperties: false,
                },
                properties: {
                  type: "object",
                  properties: {
                    id: { type: "number" }, // id is number ('local' waypoint key)
                    type: { type: "string" }, // ('local' waypoint key)
                    timestamp: { type: "string", pattern: "^[0-9T:+-]+$" },
                    legtype: { type: "string", pattern: "^greatcircle|rhumbline$" }, // legtype in {'greatcircle', 'rhumbline'} ('local' waypoint key)
                  },
                  required: [
                    "id",
                    "name",
                    "active",
                    "timestamp",
                    "type",
                    "lonlat",
                    "number",
                    "legtype",
                    "heading",
                    "sog",
                  ],
                  additionalProperties: true,
                },
              },
              required: ["type", "geometry", "properties"],
              additionalProperties: true,
            },
          },
        },
        required: ["type", "features"],
        additionalProperties: true,
      },
    },
    required: ["routeId", "routeType", "vesselName", "geojson"],
    additionalProperties: true,
    //    }
  },

  testIntervalTree() {
    let tree = new IntervalTree()

    //let intervals = [[6,8],[1,4],[5,12],[1,1],[5,7]];
    let intervals = [
      [1, 2],
      [2, 3],
    ]

    // Insert interval as a key and string "val0", "val1" etc. as a value
    for (let i = 0; i < intervals.length; i++) {
      tree.insert(intervals[i], "val" + i)
    }

    // Get array of keys sorted in ascendant order
    let sorted_intervals = tree.keys //  expected array [[1,1],[1,4],[5,7],[5,12],[6,8]]
    //console.log("sorted_intervals="+JSON.stringify(sorted_intervals,null,2))

    // Search items which keys intersect with given interval, and return array of values
    //let values_in_range = tree.search([2,3]);     //  expected array ['val1']
    //let values_in_range = tree.search([2,2]);     //  expected array ['val1']
    let values_in_range = tree.search([0, 0]) //  expected array []
    //console.log("values_in_range="+JSON.stringify(values_in_range,null,2)) // =val1 und! val2
  },

  testLineSegments() {
    const start = turf.point([179, 48])
    const end = turf.point([-179, 48])
    const greatCircle = turf.greatCircle(start, end, { npoints: 4, properties: { name: "Seattle to DC" } })
    //console.log("greatCircle="+JSON.stringify(greatCircle,null,2))
    //const polygon = turf.lineString([[-50, 5], [-40, -10], [-50, -10], [-40, 5], [-50, 5]]);
    const segments = turf.lineSegment(greatCircle)
    //console.log("line segments="+JSON.stringify(segments,null,2))
    const pushlist = List([1, 2, 3, 4]).merge([5, 6]) //push(...[5,6])
    //console.log("pushlist="+JSON.stringify(pushlist,null,2))
  },

  runTest() {
    RouteAnalyser.testIntervalTree()
    //RouteAnalyser.testLineSegments()
    const ROUTE_INDEX = 0
    const routesGeojson = REPORT_ROUTES
    const routeValid = RouteAnalyser.validateRoute(routesGeojson[ROUTE_INDEX])
    const routes = RouteAnalyser.analyseRoute(routeValid) // not immutable objects!

    routes.points["geojson"]["analyse"]["timestamp_intervaltree"] = "nix"
    //console.log("xxx route/points analysed="+JSON.stringify(routes.points,null,2))

    routes.lines["geojson"]["analyse"]["timestamp_intervaltree"] = "nix"
    //console.log("xxx route/lines analysed="+JSON.stringify(routes.lines,null,2))

    //Route.analyseRoute()
  },

  /**
   * validateRoute function
   * @param {Object} route - GeoJSON
   * @return {Object} - route or null (GeoJSON)
   */
  validateRoute(route) {
    const startDate = Date.now()
    const isRouteValid = RouteAnalyser.validateSchemaRouteReport(route)
    if (!isRouteValid) {
      console.error("route is invalid")
    }
    const endDate = Date.now()
    // console.log(`AJV Execution time: ${endDate - startDate} ms`);
    return isRouteValid ? route : null // route invalid -> return null (-> crash!)
  },

  /**
   * analyseRoute function
   * - test function calls for route analyses
   * @param {Object} route - GeoJSON
   * @return {Object} - route waypoints and linesegments
   */
  analyseRoute(route) {
    let routePoints
    const analyse_start = Date.now()
    const routeImmutable = fromJS(route)
    //console.log("routeImmutable="+routeImmutable)
    if (route.routeType === "real") {
      routePoints = RouteAnalyser.sortWaypointsByTimestamp(routeImmutable)
    } else {
      routePoints = RouteAnalyser.sortWaypointsByNumber(routeImmutable)
    }
    routePoints = RouteAnalyser.setEmptyAnalyseObjects(routePoints)

    if (routePoints.getIn(["geojson", "features"]).size < 2) {
      return { points: routePoints, lines: null }
    }

    // --------------------------------------------------------------------------------------------
    // route error detection (-> remove or corrections) functions
    // -> zuerst im importer, dann in shippingdb -> trigger function with shifting/sliding window -> dann hier im code
    // --------------------------------------------------------------------------------------------

    // ...
    // TODO legtype muss rhumbline oder greatcircle sein
    // TODO sog>100 -> fehlt oder ausserhalb range (-1 problem)
    // TODO heading>360 -> fehlt oder ausserhalb range (-1 problem)
    // TODO timestamp test???
    // TODO lat,lon test

    // --------------------------------------------------------------------------------------------
    // (basic) route analyse 'set'-functions, local between neighbor waypoints
    // --------------------------------------------------------------------------------------------

    routePoints = RouteAnalyser.setDistance(routePoints)
    routePoints = RouteAnalyser.setTimeByTimestamps(routePoints)
    routePoints = RouteAnalyser.setSpeedByDistanceTimestamps(routePoints)
    routePoints = RouteAnalyser.setSpeedBySog(routePoints)

    // --------------------------------------------------------------------------------------------
    // (basic) route analyse 'accumulate'-functions, local between neighbor waypoints
    // --------------------------------------------------------------------------------------------

    routePoints = RouteAnalyser.setSumDistance(routePoints)
    routePoints = RouteAnalyser.setRemainingDistance(routePoints)
    routePoints = RouteAnalyser.setSumTimeByTimestamps(routePoints)
    routePoints = RouteAnalyser.setRemainingTimeByTimestamps(routePoints)
    routePoints = RouteAnalyser.setMaxSpeedByDistanceTimestamps(routePoints)
    routePoints = RouteAnalyser.setMaxSpeedBySog(routePoints)
    routePoints = RouteAnalyser.setSumSpeedByDistanceTimestamps(routePoints)
    routePoints = RouteAnalyser.setSumSpeedBySog(routePoints)
    routePoints = RouteAnalyser.setAvgSpeedByDistanceTimestamps(routePoints)
    routePoints = RouteAnalyser.setAvgSpeedBySog(routePoints)

    // --------------------------------------------------------------------------------------------
    // (basic) route analyse 'mark'-functions, local between neighbor waypoints
    // --------------------------------------------------------------------------------------------

    routePoints = RouteAnalyser.markGapDistance(routePoints, RouteAnalyser.THRESHOLDS.gap_distance)
    routePoints = RouteAnalyser.markLowDistance(routePoints, RouteAnalyser.THRESHOLDS.low_distance)
    routePoints = RouteAnalyser.markGapTime(routePoints, RouteAnalyser.THRESHOLDS.gap_time)
    routePoints = RouteAnalyser.markLowTime(routePoints, RouteAnalyser.THRESHOLDS.low_time)
    routePoints = RouteAnalyser.markLowSpeedBySog(routePoints, RouteAnalyser.THRESHOLDS.low_speed_by_sog)
    routePoints = RouteAnalyser.markLowSpeedByDistanceTimestamps(
      routePoints,
      RouteAnalyser.THRESHOLDS.low_speed_by_distance_timestamps
    )
    // routePoints=RouteAnalyser.markBetweenTimeRange(routePoints,dayjs("2023-01-01T01:02:03+00:00"),dayjs("2023-02-01T11:12:13+00:00"))

    routePoints = RouteAnalyser.setMarkerClass(routePoints, "is_low_distance")
    // console.log(routePoints.toJS());
    routePoints = RouteAnalyser.filterRoute(routePoints, (wp, i, a) => {
      // if((wp.getIn(["properties","analyse","is_low_distance_class"]) !== "inner" && wp.getIn(["properties","analyse","distance"])[0] !== 0) &&
      // if(wp.getIn(["properties","analyse","is_low_distance_class"]) !== "inner" &&
      //  !(wp.getIn(["properties","analyse","is_low_distance_class"]) === "last" && wp.getIn(["properties","analyse","distance"])[0] === 0 )
      if (
        wp.getIn(["properties", "analyse", "is_low_distance_class"]) !== "inner" &&
        wp.getIn(["properties", "analyse", "is_low_distance_class"]) !== "first" &&
        wp.getIn(["properties", "analyse", "is_low_distance_class"]) !== "last" &&
        wp.getIn(["properties", "analyse", "is_low_distance_class"]) !== "single"
      ) {
        return true
      }
    })
    // console.log(routePoints.toJS());

    // if route has only 1 feature or first and last WPs have same coordinates (+filter out first)
    if (
      routePoints.toJS().geojson.features.length < 2 ||
      JSON.stringify(routePoints.toJS().geojson.features[0].geometry.coordinates) ===
        JSON.stringify(
          routePoints.toJS().geojson.features[routePoints.toJS().geojson.features.length - 1].geometry.coordinates
        )
    ) {
      // console.log(routePoints.toJS());
      routePoints = RouteAnalyser.filterRoute(routePoints, (wp, i, a) => {
        if (i === a.size - 1) {
          return true
        }
      })
      // console.log(routePoints.toJS());
      return { points: routePoints.toJS() }
    }

    // --------------------------------------------------------------------------------------------
    // histograms analyse on route, global for route
    // --------------------------------------------------------------------------------------------

    // routePoints=RouteAnalyser.setDistanceHistogram(routePoints)
    // routePoints=RouteAnalyser.setTimeByTimestampsHistogram(routePoints)
    // routePoints=RouteAnalyser.setSpeedBySogHistogram(routePoints)
    // routePoints=RouteAnalyser.setSpeedByDistanceTimestampsHistogram(routePoints)

    // ---------------------------------------------------------------------------------------------
    // extended route waypoints
    // insert rhumbline+greatcircle antimeridian+highres greatcircle points to (original) route
    // ---------------------------------------------------------------------------------------------

    if (route.routeType === "real") {
      routePoints = RouteAnalyser.setAntimeridianRhumblinePoints(routePoints, "legtype") // -> postgres trigger javascript function with shifting/sliding window
      routePoints = RouteAnalyser.setGreatCircleLineSegments(
        routePoints,
        RouteAnalyser.THRESHOLDS.seg_distance_in_km,
        "legtype"
      ) // -> postgres trigger javascript function with shifting/sliding window
      routePoints = RouteAnalyser.extendRoute(routePoints, RouteAnalyser.THRESHOLDS.seg_distance_in_km) // -> postgres trigger javascript function with shifting/sliding window
    }
    // ---------------------------------------------------------------------------------------------
    // bbox 'marker'-functions for route waypoints, only for performance
    // ---------------------------------------------------------------------------------------------

    // bbox functions for waypoints:
    // const bboxName="complete_route"
    // const filterRoute=RouteAnalyser.filterRoute(routePoints,wp=>true) // true=complete route or timerange waypoint bbox; wp=>wp.getIn(["properties","analyse",is_time_range"]) and or ...
    // routePoints=RouteAnalyser.setBoundingBoxPolygon(routePoints,bboxName,filterRoute); // "is_gap_distance"
    // routePoints=RouteAnalyser.markWithinBbox(
    //   routePoints,
    //   routePoints.getIn(["geojson","analyse","bbox_polygon",bboxName]),
    //   RouteAnalyser.BBOX_MAP_TEST,
    //   bboxName
    // );
    // routePoints=RouteAnalyser.markOverlapBbox(
    //   routePoints,
    //   routePoints.getIn(["geojson","analyse","bbox_polygon",bboxName]),
    //   RouteAnalyser.BBOX_MAP_TEST,
    //   bboxName
    // );
    // RouteAnalyser.markPointsInBbox(routePoints,RouteAnalyser.BBOX_MAP_TEST,'is_in_map') // nur im overlap fall

    // ---------------------------------------------------------------------------------------------
    // simplify 'marker'-functions for route waypoints
    // ---------------------------------------------------------------------------------------------

    routePoints = RouteAnalyser.setRouteLinestring(routePoints) //  -> (local between neighbor waypoints) -> postgres function with shifting/sliding window
    routePoints = RouteAnalyser.setSimplifyLinestringDouglasPeucker(routePoints, 0.01)
    routePoints = RouteAnalyser.setSimplifyPointset(routePoints)
    routePoints = RouteAnalyser.markSimplifyPoints(routePoints)

    // --------------------------------------------------------------------------------------------
    // vessel functions, for vessel position interpolation+extrapolation
    // --------------------------------------------------------------------------------------------

    // routePoints=RouteAnalyser.setTimeIntervalTree(routePoints)
    // let vessel=RouteAnalyser.setVesselInterpolatePointByTime(routePoints,routePoints,"2023-05-27T00:00:01+00:00","rhumbline")
    // vessel=RouteAnalyser.setVesselBearing(vessel,routePoints,"rhumbline")
    // vessel=RouteAnalyser.setVesselExtrapolatePositionByTime(vessel,routePoints,dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZ'),"point","rhumbline")

    // --------------------------------------------------------------------------------------------
    // interpolate on (extended) route -> insert new interpolated point (with 'on route') and legtype='xxx'
    // --------------------------------------------------------------------------------------------

    // routePoints=RouteAnalyser.setRouteInterpolatePointByTime(routePoints,"2023-05-27T00:00:01+00:00","rhumbline")

    // --------------------------------------------------------------------------------------------
    // create route line segments from (extended/filtered/manipulated) route waypoints,
    // with full waypoint geojsons in 'wp1' and 'wp2'
    // --------------------------------------------------------------------------------------------

    // waypoints2linesegments functions (with filter):
    // let filterRoute3=RouteAnalyser.filterRoute(routePoints,wp=>true) // true=complete route or timerange lines or ...
    // let routeLines=RouteAnalyser.linestringSegments(filterRoute3,RouteAnalyser.THRESHOLDS.seg_distance_in_km) // with greatcircle and antimeridian points

    // ---------------------------------------------------------------------------------------------
    // bbox 'marker'-functions for linesegments, only for performance
    // ---------------------------------------------------------------------------------------------

    // const filterRoute2=RouteAnalyser.filterRoute(routePoints,wp=>true) // true=complete route or timerange waypoint bbox; wp=>wp.getIn(["properties","analyse",is_time_range"]) and or ...
    // routeLines=RouteAnalyser.setBoundingBoxPolygon(routeLines,bboxName,filterRoute2); // "is_gap_distance"
    // routeLines=RouteAnalyser.markWithinBbox(
    //   routeLines,
    //   routeLines.getIn(["geojson","analyse","bbox_polygon",bboxName]),
    //   RouteAnalyser.BBOX_MAP_TEST,
    //   bboxName
    // );
    // routeLines=RouteAnalyser.markOverlapBbox(
    //   routeLines,
    //   routeLines.getIn(["geojson","analyse","bbox_polygon",bboxName]),
    //   RouteAnalyser.BBOX_MAP_TEST,
    //   bboxName
    // );
    // RouteAnalyser.markLinesInBbox(routeLines,RouteAnalyser.BBOX_MAP_TEST,'is_in_map')  // nur im overlap fall

    // --------------------------------------------------------------------------------------------
    // ready
    // --------------------------------------------------------------------------------------------

    const analyse_end = Date.now()
    // console.log(`RouteAnalyser.analyseRoute() execution time: ${analyse_end - analyse_start} ms`)

    //console.log("route/points analysed="+routePoints)//JSON.stringify(routes.points,null,2)) // ACHTUNG: stringify+routeWithoutIntervalTree -> crash!
    //console.log("route/lines analysed="+routeLines)//JSON.stringify(routes.lines,null,2))

    // return { points: routePoints.toJS(), lines: routeLines.toJS() }
    return { points: routePoints.toJS() }
  },

  // -----------------------------------------------------------------------------------------------------------
  // route analyse functions
  // -----------------------------------------------------------------------------------------------------------

  /**
   * setAntimeridianRhumblinePoints function
   * - set antimeridian waypoints for rhumbline waypoints in keys ["properties","analyse","antimeridian","rhumbline_point1",0] and ["properties","analyse","antimeridian","rhumbline_point2",0]
   * - antimeridian waypoints -> no characteristic points
   * - https://macwright.com/2016/09/26/the-180th-meridian.html
   * - https://github.com/Turfjs/turf/issues/782
   * - https://gitlab.com/jonathan.derrough/geojson-antimeridian-cut
   * @param {Object} route - GeoJSON
   * @return {Object} - extended new route (GeoJSON)
   */
  setAntimeridianRhumblinePoints(route, legtypeHandler = "legtype") {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (wp.getIn(["properties", "legtype"]) === "greatcircle") {
          return wp
        }

        if (i === a.size - 1) {
          //wp=wp.setIn(["properties","analyse","antimeridian","rhumbline_point1",0],[]) // OBSOLET: 0 index norm <- setAntimeridianWaypointsGreatCircle()
          //wp=wp.setIn(["properties","analyse","antimeridian","rhumbline_point2",0],[])
          return wp
        }

        const point1 = wp.getIn(["geometry", "coordinates"]).toJS()
        const point2 = a
          .get(i + 1)
          .getIn(["geometry", "coordinates"])
          .toJS()

        if (Math.abs(point2[0] - point1[0]) >= 180.0) {
          const lineString = turf.lineString([point1, point2]) // turf.lineString([[179.5,50], [-179.5,50]])
          const splitty = splitGeoJSON(lineString)
          const antimeridianPoint1 = splitty.geometry.coordinates[0][splitty.geometry.coordinates[0].length - 1]
          const antimeridianPoint2 = splitty.geometry.coordinates[1][0]
          let newWaypoint1 = fromJS(turf.point(antimeridianPoint1, { legtype: "rhumbline_antimeridian", analyse: {} }))
          let newWaypoint2 = fromJS(turf.point(antimeridianPoint2, { legtype: "rhumbline_antimeridian", analyse: {} }))
          newWaypoint1 = RouteAnalyser.setInterpolateTimeByPoints(newWaypoint1, wp, a.get(i + 1), "rhumbline")
          newWaypoint2 = RouteAnalyser.setInterpolateTimeByPoints(newWaypoint2, wp, a.get(i + 1), "rhumbline") // bem: beide timestamps muessen gleich sein
          wp = wp.setIn(["properties", "analyse", "antimeridian", "rhumbline_point1"], newWaypoint1)
          wp = wp.setIn(["properties", "analyse", "antimeridian", "rhumbline_point2"], newWaypoint2)
        }

        return wp
      })
    )
    return newRoute
  },

  /**
   * setGreatCircleLineSegments function
   * - set greatcircle segment linestrings with n points in ["properties","analyse","greatcircle_line"] key
   * - https://macwright.com/2016/09/26/the-180th-meridian.html
   * - https://github.com/Turfjs/turf/issues/782
   * - https://gitlab.com/jonathan.derrough/geojson-antimeridian-cut
   * - pre condition: setDistance(route)
   * @param {Object} route - GeoJSON
   * @param {number} seg_distance_in_km - x
   * @return {Object} - extended new route (GeoJSON)
   */
  setGreatCircleLineSegments(
    route,
    seg_distance_in_km = RouteAnalyser.THRESHOLDS.seg_distance_in_km,
    legtypeHandler = "legtype"
  ) {
    //console.log("setGreatCircleLineSegments()")
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (wp.getIn(["properties", "legtype"]) === "rhumbline") {
          return wp
        }

        if (i === a.size - 1) {
          // optimize by distance/nPoints
          //console.log("wp="+wp)
          wp = wp.setIn(["properties", "analyse", "greatcircle_line"], List()) // {} statt [] ???
          return wp
        }

        const point1 = wp.getIn(["geometry", "coordinates"]).toJS()
        const point2 = a
          .get(i + 1)
          .getIn(["geometry", "coordinates"])
          .toJS()
        const dist = a.get(i + 1).getIn(["properties", "analyse", "distance", 0])
        //console.log("dist="+dist)
        let nPoints = Math.floor(dist / seg_distance_in_km) + 2 // point1 and point2 -> +2;  turf.distance(point1,point2,{units:"kilometers"})
        //console.log("nPoints="+nPoints)
        //if (nPoints<2) { nPoints=2} // seg_distance_in_km>=distance(point1,point2)

        if (nPoints <= 2) {
          // optimize by distance/nPoints
          wp = wp.setIn(["properties", "analyse", "greatcircle_line"], List()) // {} statt [] ???
          return wp
        }
        //console.log("xxxxx nPoints="+nPoints)
        const greatCircle = turf.greatCircle(point1, point2, {
          npoints: nPoints,
          properties: { legtype: "greatcircle_linesegment", name: "Seattle to DC" },
        }) // npoints 0 -> many points; npoints=2 -> point1 and point2; offset in options: offset controls the likelyhood that lines will be split which cross the dateline. The higher the number the more likely.
        //console.log("type="+greatCircle.geometry.type+" greatCircle="+JSON.stringify(greatCircle,null,2))
        if (greatCircle.geometry.type === "MultiLineString") {
          greatCircle.geometry.coordinates[0][greatCircle.geometry.coordinates[0].length - 1]["properties"]["legtype"] =
            "greatcircle_antimeridian"
          greatCircle.geometry.coordinates[1][0]["properties"]["legtype"] = "greatcircle_antimeridian"
        }

        // timestamps interpolieren:
        const innerPoints = List(fromJS(turf.explode(greatCircle)["features"]))
          .pop()
          .skip(1)
        // const newInnerPoints=innerPoints.map() // forall innerpoints
        //}
        const startPoint = wp
        const endPoint = a.get(i + 1)
        const newInnerPoints = innerPoints.map((wp2, i_egal, a_egal) => {
          const newWaypoint = RouteAnalyser.setInterpolateTimeByPoints(wp2, startPoint, endPoint, "rhumbline")
          return newWaypoint
        })

        const newWp = wp.setIn(["properties", "analyse", "greatcircle_line"], newInnerPoints)
        //console.log("newInnerPoints="+newWp.getIn(["properties","analyse","greatcircle_line"]))
        return newWp
      })
    )
    return newRoute
  },

  /**
   * setIntervalTree function
   * - private method
   * @param {Object} unixTimeIntervalArray - zipped array of unix time intervals in seconds
   * @return {Object} - interval tree
   */
  setIntervalTree(unixTimeIntervalArray) {
    const timeIntervalTree = new IntervalTree()
    // Insert interval as a key and value
    unixTimeIntervalArray.forEach((timeInterval, i, a) => {
      //console.log("i="+i+" w="+isArray(timeInterval))
      timeIntervalTree.insert(timeInterval, i)
    })

    //timeIntervalTree.forEach((key, value) => console.log(key));
    //const a = timeIntervalTree.search([1685145601, 1685145601]);
    //console.log("interval search="+a);
    return timeIntervalTree
  },

  /**
   * setTimeIntervalTree function
   * - pre condition: nix (bzw. alle route waypoints timestamps muessen gesetzt sein)
   * - 'helper data'
   * - set date interval tree with date intervals in seconds between route waypoints
   * - ACHTUNG: muss wieder neu aufgerufen werden, wenn route waypoints veraendert wurden
   * - https://alexbol99.github.io/flatten-interval-tree/
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON with interval tree in global analysis
   */
  setTimeIntervalTree(route) {
    const unixTimeArray = route.getIn(["geojson", "features"]).map((waypoint, i, a) => {
      const sec = dayjs(waypoint.getIn(["properties", "timestamp"])).unix() // unix timestamp (is in UTC) in seconds
      //console.log("sec="+sec+" day="+dayjs.unix(sec).utc().format('YYYY-MM-DDTHH:mm:ssZ'))
      return sec
    })
    const unixTimeIntervalArray = unixTimeArray.pop().zip(unixTimeArray.skip(1)) //.toJS()
    //console.log("zipUnixTimeArray="+JSON.stringify(unixTimeIntervalArray,null,2))
    const timeIntervalTree = RouteAnalyser.setIntervalTree(unixTimeIntervalArray)
    //console.log("timeIntervalTree="+JSON.stringify(intervalArray,null,2))

    return route.setIn(["geojson", "analyse", "timestamp_intervaltree"], timeIntervalTree) // as [object Object]
  },

  /**
   * interpolateOnRoute function
   * - private method
   * - pre condition: setTimeIntervalTree()
   * - pre condition: benoetigt zusaetzlich nur timestamp in waypoints!!!
   * - for original or extended routes (with legtypes and simplify)
   * - Bem: turf.along benutzen auf gefundenem linesegment?
   * - neuer Parameter legtypeHandler:
   * -   'legtype' = legtype beachten
   * -   'rhumbline' = legtype ignorieren/nur rhumblines
   * - greatcircle/rhumbline interpolation mit turf.destination(point,distance,bearing,'km')/turf.rhumbDestination(point,distance,bearing,'km')
   * @param {Object} vessel - is route with one waypoint
   * @param {Object} route - x
   * @param {Object} interpolateTimestamp - x
   * @param {String} legtypeHandler - x
   * @return {Object} - new vessel
   */
  interpolateOnRoute(interpolateTimestamp, route, legtypeHandler) {
    let intervaltreeMatch = "nix"
    let interpolateTime = dayjs(interpolateTimestamp)
    const firstRouteTimestamp = route.getIn(["geojson", "features", 0, "properties", "timestamp"])
    const lastRouteTimestamp = route.getIn([
      "geojson",
      "features",
      route.getIn(["geojson", "features"]).size - 1,
      "properties",
      "timestamp",
    ])
    let realTimeStr = null
    if (interpolateTime < dayjs(firstRouteTimestamp)) {
      //console.log("timeStr vor route start")
      realTimeStr = firstRouteTimestamp
      intervaltreeMatch = "before_route"
    } else if (interpolateTime > dayjs(lastRouteTimestamp)) {
      //console.log("timeStr nach route ende")
      realTimeStr = lastRouteTimestamp
      intervaltreeMatch = "after_route"
    } else {
      //console.log("timeStr in route")
      realTimeStr = interpolateTimestamp
      intervaltreeMatch = "on_route"
    }

    // test:
    //timeIntervalTree.forEach((key, value) => console.log(key));
    //const a = timeIntervalTree.search([1685145601, 1685145601]);
    //console.log("interval search="+a);

    const timeIntervalTree = route.getIn(["geojson", "analyse", "timestamp_intervaltree"]) // performance???
    interpolateTime = dayjs(realTimeStr)
    const interpolateSeconds = interpolateTime.unix() // unix timestamp (is in UTC) in seconds   dayjs.unix(interpolateSeconds).utc().format('YYYY-MM-DDTHH:mm:ssZ')
    const timeInterval = timeIntervalTree.search([interpolateSeconds, interpolateSeconds])
    const startIntervalTimeIndex = timeInterval[timeInterval.length - 1]

    const startWaypoint = route.getIn(["geojson", "features", startIntervalTimeIndex])
    const endWaypoint = route.getIn(["geojson", "features", startIntervalTimeIndex + 1]) // last index???
    const vector1 = startWaypoint.getIn(["geometry", "coordinates"]).toJS()
    const vector2 = endWaypoint.getIn(["geometry", "coordinates"]).toJS()
    //const differenceVector = [vector2[0]-vector1[0], vector2[1]-vector1[1]];

    const startTime = dayjs(startWaypoint.getIn(["properties", "timestamp"])).unix()
    const endTime = dayjs(endWaypoint.getIn(["properties", "timestamp"])).unix()
    const diffTime = interpolateSeconds - startTime
    const timeBetweenStartAndEndWaypoints = endTime - startTime
    const timeFactor = diffTime / timeBetweenStartAndEndWaypoints
    //console.log("timeFactor="+timeFactor)

    // TODO ohne turf beenden, wenn interpolateTimestamp ausserhalb der route ist:
    //if (intervaltreeMatch==="in_route") {
    //const interpolatedPoint=[vector1[0]+differenceVector[0]*timeFactor,vector1[1]+differenceVector[1]*timeFactor]
    let interpolatedPoint = null
    if (legtypeHandler !== "rhumbline" && startWaypoint.getIn(["properties", "legtype"]) === "greatcircle") {
      interpolatedPoint = turf.destination(
        vector1,
        turf.distance(vector1, vector2) * timeFactor,
        turf.bearing(vector1, vector2)
      )
    } else {
      // startWaypoint.getIn(["properties","legtype"]) in ("rhumbline","greatcircle_linesegment",'greatcircle_antimeridian',"rhumbline_antimeridian")
      interpolatedPoint = turf.rhumbDestination(
        vector1,
        turf.rhumbDistance(vector1, vector2) * timeFactor,
        turf.rhumbBearing(vector1, vector2)
      )
    }
    return { interpolatedPoint, intervaltreeMatch, startIntervalTimeIndex, vector1, vector2 }
  },

  /**
   * setVesselInterpolatePointByTime function
   * - pre condition: setTimeIntervalTree()
   * - pre condition: benoetigt zusaetzlich nur timestamp in waypoints!!!
   * - for original or extended routes (with legtypes and simplify)
   * @param {Object} vessel - is route with one waypoint
   * @param {Object} route - x
   * @param {Object} interpolateTimestamp - x
   * @param {String} legtypeHandler - x
   * @return {Object} - new vessel
   */
  setVesselInterpolatePointByTime(vessel, route, interpolateTimestamp, legtypeHandler = "rhumbline") {
    // if (vessel.type!=="vessel") { console.log("kein vessel"); return vessel }

    const { interpolatedPoint, intervaltreeMatch, startIntervalTimeIndex, vector1, vector2 } =
      RouteAnalyser.interpolateOnRoute(interpolateTimestamp, route, legtypeHandler)

    //console.log("interpolatedPoint="+fromJS(interpolatedPoint))

    const last_point = vessel.getIn(["geojson", "features", 0, "geometry", "coordinates"])
    let newVessel = vessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "last_point"],
      last_point
    ) // as immutable List [ 1.99, 40.8856 ]
    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "geometry", "coordinates"],
      fromJS(interpolatedPoint.geometry.coordinates)
    ) // as immutable List [ 1.99, 40.8856 ]

    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "actual_timestamp"],
      interpolateTimestamp
    )
    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "intervaltree_match"],
      intervaltreeMatch
    )

    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "intervaltree_index"],
      startIntervalTimeIndex
    ) // -> all route waypoint properties
    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "start_linesegment_point"],
      fromJS(vector1)
    ) // as immutable List [ 1.99, 40.8856 ]
    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "end_linesegment_point"],
      fromJS(vector2)
    ) // as immutable List [ 1.99, 40.8856 ]
    newVessel = newVessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "interpolate_data", "foreignkey_route_id"],
      route.getIn(["routeId"])
    )
    // console.log("newVessel.foreignkey_route_id="+newVessel.getIn(["geojson","features",0,"properties","analyse","interpolate_data","foreignkey_route_id"]))
    //console.log("newVessel="+newVessel)

    return newVessel
  },

  /**
   * setRouteInterpolatePointByTime function
   * - pre condition: CALL AFTER extendRoute(), weil diese interpolation auf greatcircle linesegments oder antimeridian moeglich ist!
   * - pre condition: setTimeIntervalTree()
   * - pre condition: benoetigt zusaetzlich nur timestamp in waypoints!!!
   * - for original or extended routes (with legtypes and simplify)
   * @param {Object} vessel - is route with one waypoint
   * @param {Object} route - x
   * @param {String} interpolateTimestamp - date string in utc
   * @param {String} legtypeHandler - x
   * @return {Object} - new route with new extended legtype
   */
  setRouteInterpolatePointByTime(route, interpolateTimestamp, legtypeHandler = "rhumbline") {
    // if (vessel.type!=="route") { console.log("keine route"); return route }

    const { interpolatedPoint, intervaltreeMatch, startIntervalTimeIndex, vector1, vector2 } =
      RouteAnalyser.interpolateOnRoute(interpolateTimestamp, route, legtypeHandler)

    if (intervaltreeMatch !== "on_route") {
      //console.log("interpolated point is not on route -> no new route waypoint")
      return route
    }

    //console.log("interpolatedPoint="+fromJS(interpolatedPoint))
    let newWp = fromJS(interpolatedPoint)
    newWp = newWp.setIn(["properties", "legtype"], "time_interpolated_point")
    newWp = newWp.setIn(["properties", "timestamp"], interpolateTimestamp)

    newWp = newWp.setIn(["properties", "analyse", "interpolate_data", "actual_timestamp"], interpolateTimestamp)
    newWp = newWp.setIn(["properties", "analyse", "interpolate_data", "intervaltree_match"], intervaltreeMatch)

    newWp = newWp.setIn(["properties", "analyse", "interpolate_data", "intervaltree_index"], startIntervalTimeIndex) // -> all route waypoint properties
    newWp = newWp.setIn(["properties", "analyse", "interpolate_data", "start_linesegment_point"], fromJS(vector1)) // as immutable List [ 1.99, 40.8856 ]
    newWp = newWp.setIn(["properties", "analyse", "interpolate_data", "end_linesegment_point"], fromJS(vector2)) // as immutable List [ 1.99, 40.8856 ]
    //newWp=newWp.setIn(["properties","analyse","interpolate_data","foreignkey_route_id"],route.getIn(["routeId"]))
    // console.log("newWp.foreignkey_route_id="+newWp.getIn(["properties","analyse","interpolate_data","foreignkey_route_id"]))
    //console.log("newWp="+newWp)

    // insert newWp into route:
    //console.log("x="+List([1,2,3]).insert(1,"w"))
    const newFeatures = route.getIn(["geojson", "features"]).insert(startIntervalTimeIndex + 1, newWp)
    const newRoute = route.setIn(["geojson", "features"], newFeatures)

    return newRoute
  },

  /**
   * setInterpolateTimeByPoints function
   * - pre condition: setDistance(route), setTimeByTimestamps(route)
   * @param {Object} innerWp - original waypoint with legtype in {"rhumbline","greatcircle"} and timestamp
   * @param {Object} startWp - original waypoint with legtype in {"rhumbline","greatcircle"} and timestamp
   * @param {Object} innerWp - extendend waypoint with legtype in {?} and without timestamp
   * @param {String} legtypeHandler - "legtype" or "rhumbline"
   * @return {String} - new innerWp with new linear interpolated timestamp
   */
  setInterpolateTimeByPoints(innerWp, startWp, endWp, legtypeHandler = "rhumbline") {
    //const distance=endWp.getIn(["properties","analyse","distance",0]) // -> stattdessen erneut dist(startWp,endWp)
    //const timestamp=endWp.getIn(["properties","timestamp"])

    const distance = RouteAnalyser.distanceBetweenPoints(startWp, endWp, legtypeHandler)
    const d = RouteAnalyser.distanceBetweenPoints(startWp, innerWp, legtypeHandler)
    const factor = d / distance

    const startTime = dayjs(startWp.getIn(["properties", "timestamp"])).unix()
    const endTime = dayjs(endWp.getIn(["properties", "timestamp"])).unix()
    const innerWpTime = factor * (endTime - startTime)
    const interpolateTime = dayjs
      .unix(startTime + innerWpTime)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZ")
    const newInnerWp = innerWp.setIn(["properties", "timestamp"], interpolateTime)
    return newInnerWp
  },

  /**
   * setVesselBearing function
   * - for original or extended routes (with legtypes and simplify)
   * - Parameter legtypeHandler:
   * -   'legtype' = legtype beachten
   * -   'rhumbline' = legtype ignorieren/nur rhumblines
   * - pre condition: setVesselInterpolatePointByTime()
   * - pre condition: benoetigt zusaetzlich: nix!!!
   * @param {Object} vessel - is route with one waypoint
   * @param {Object} route - extended route
   * @param {String} legtypeHandler - x
   * @return {Object} - new vessel GeoJSON
   */
  setVesselBearing(vessel, route, legtypeHandler = "rhumbline") {
    const p1 = vessel
      .getIn(["geojson", "features", 0, "properties", "analyse", "interpolate_data", "start_linesegment_point"])
      .toJS()
    const p2 = vessel
      .getIn(["geojson", "features", 0, "properties", "analyse", "interpolate_data", "end_linesegment_point"])
      .toJS()
    const intervaltree_index = vessel.getIn(["geojson", "features", 0, "properties", "analyse", "intervaltree_index"]) // -> route waypoint legtype
    let bearing = null
    if (
      legtypeHandler !== "rhumbline" &&
      route.getIn(["geojson", "features", intervaltree_index, "properties", "legtype"]) === "greatcircle"
    ) {
      bearing = turf.bearing(p1, p2)
    } else {
      // route.getIn(["geojson","features",intervaltree_index,"properties","legtype"]) in ("rhumbline","greatcircle_linesegment",'greatcircle_antimeridian',"rhumbline_antimeridian")
      bearing = turf.rhumbBearing(p1, p2)
    }

    //const bearing=turf.rhumbBearing(p1,p2)
    //const bearing=turf.rhumbBearing([1,1],[2,2])
    //console.log("p1="+p1+" p2="+p2+" bearing="+bearing)
    const newVessel = vessel.setIn(["geojson", "features", 0, "properties", "analyse", "rhumbline_bearing"], bearing)
    return newVessel
  },

  /**
   * setVesselExtrapolatePositionByTime function
   * - extrapolate vessel position from last route waypoint to now date
   * - with legtype of last route waypoint
   * - with turf.destination()/thurf.rhumbDestination()
   * - pre condition: setTimeIntervalTree(), setVesselInterpolatePointByTime(), setVesselBearing()
   * - Bem: type="line" problematisch -> nur korrekt auf original route, nicht extended route!!! -> legtypeHandler
   * - plus parameter waypoint???
   * - antimeridian extrapolation???
   * @param {Object} vessel - is route with one waypoint
   * @param {Object} route - xxx
   * @param {Object} time - xxx
   * @param {Object} type - 'line' or 'point'
   * @param {String} legtypeHandler - x
   * @return {Object} - new vessel GeoJSON
   */
  setVesselExtrapolatePositionByTime(vessel, route, timeStr, type = "point", legtypeHandler = "rhumbline") {
    //console.log("timeStr="+timeStr+" type="+type+" legtypeHandler="+legtypeHandler)
    const time = dayjs(timeStr).unix() // unix timestamp (is in UTC) in seconds
    const route_last_index = vessel.getIn(["geojson", "features"]).size - 1
    //console.log("route_last_index="+route_last_index)

    const time_p2 = dayjs(route.getIn(["geojson", "features", route_last_index, "properties", "timestamp"])).unix() // unix timestamp (is in UTC) in seconds
    const time_now = time // dayjs().utc().unix() // unix timestamp (is in UTC) in seconds
    const time_diff_in_hour = (time_now - time_p2) / 3600.0 // in hours

    const p2 = route.getIn(["geojson", "features", route_last_index, "geometry", "coordinates"]).toJS()

    let bearing = null
    if (type === "line") {
      const p1 = route.getIn(["geojson", "features", route_last_index - 1, "geometry", "coordinates"]).toJS()
      const legtype_p1 = route.getIn(["geojson", "features", route_last_index - 1, "properties", "legtype"])
      if (legtypeHandler !== "rhumbline" && legtype_p1 === "greatcircle") {
        bearing = turf.bearing(p1, p2)
      } else {
        // legtype_p1 in ("rhumbline","greatcircle_linesegment",'greatcircle_antimeridian',"rhumbline_antimeridian")
        bearing = turf.rhumbBearing(p1, p2)
      }
    } else {
      // type==="point"
      bearing = route.getIn(["geojson", "features", route_last_index, "properties", "heading"]) // 0 degree=north???
    }

    let speed = null
    if (type === "line") {
      speed = route.getIn([
        "geojson",
        "features",
        route_last_index,
        "properties",
        "analyse",
        "speed_by_distance_timestamps",
        0,
      ]) // in km/h
    } else {
      // type==="point"
      speed = route.getIn(["geojson", "features", route_last_index, "properties", "analyse", "speed_by_sog", 0]) * 1.852 // nm/h=knots -> km
    }

    let distance = speed * time_diff_in_hour
    //console.log("time_diff_in_hour="+time_diff_in_hour+" speed="+speed+" distance="+distance)

    let extrapolate_point = null
    const legtype_p2 = route.getIn(["geojson", "features", route_last_index, "properties", "legtype"])
    if (legtypeHandler !== "rhumbline" && legtype_p2 === "greatcircle") {
      extrapolate_point = turf.destination(p2, distance, bearing, { units: "kilometers" }) //.geometry.coordinates
    } else {
      // legtype_p2 in ("rhumbline","greatcircle_linesegment",'greatcircle_antimeridian',"rhumbline_antimeridian")
      extrapolate_point = turf.rhumbDestination(p2, distance, bearing, { units: "kilometers" }) //.geometry.coordinates
    }

    //const bearing=turf.rhumbBearing([1,1],[2,2])
    //console.log("p1="+p1+" p2="+p2+" bearing="+bearing)
    const newVessel = vessel.setIn(
      ["geojson", "features", 0, "properties", "analyse", "extrapolate_point"],
      extrapolate_point
    )
    return newVessel
  },

  /**
   * setRouteLinestring function
   * - 'helper data'
   * - set route linestring (from waypoints) in ["geojson","analyse","linestring_complete"] key, only used for setSimplifyPointset()
   * - pre condition: waypoints are sorted by timestamp
   * - pro: trajectory segments parallizable
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setRouteLinestring(route) {
    const waypointArray = route.getIn(["geojson", "features"]).map((waypoint, i, a) => {
      return waypoint.getIn(["geometry", "coordinates"])
    })
    const linestring = turf.lineString(waypointArray.toJS())
    return route.setIn(["geojson", "analyse", "linestring_complete"], linestring)
  },

  /**
   * setSimplifyPointset function
   * - 'helper data'
   * - pre condition: setSimplifyLinestringDouglasPeucker()
   * - set key ['geojson','analyse','simplify_pointset'] with SET OF waypoints (as strings) from simplified linestring
   * - performance?
   * @param {Object} route - GeoJSON
   * @return {Object} - extended new route (GeoJSON)
   */
  setSimplifyPointset(route) {
    const pointStrings = route
      .getIn(["geojson", "analyse", "simplify_linestring", "geometry", "coordinates"])
      .map((point, i, a) => {
        return JSON.stringify(point)
      })
    const pointStringSet = Set(pointStrings)
    //console.log("is Set="+Set.isSet(pointStringSet)+" in [-2.1954,36.4288]="+pointStringSet.includes(JSON.stringify([-2.1954,36.4288])))
    return route.setIn(["geojson", "analyse", "simplify_pointset"], pointStringSet)
  },

  /**
   * markSimplifyPoints function
   * - pre condition: setSimplifyPointset()
   * - mark simplified waypoints in key ["properties","analyse","is_simplify"]
   * - 'pseudo characteristic' waypoints
   * @param {Object} route - GeoJSON
   * @return {Object} - extended new route (GeoJSON)
   */
  markSimplifyPoints(route) {
    const simplifyPointset = route.getIn(["geojson", "analyse", "simplify_pointset"])
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        const is_in_simplifyPointset = simplifyPointset.includes(JSON.stringify(wp.getIn(["geometry", "coordinates"]))) // is route waypoint (as string) in simplifyPointset? (performance?)
        return wp.setIn(["properties", "analyse", "is_simplify"], is_in_simplifyPointset)
      })
    )
    return newRoute
  },

  /**
   * markAntimeridianRhumbline function
   * - OBSOLET
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markAntimeridianRhumbline(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        //if (p2===undefined) { console.log("wp="+wp) }
        if (wp.getIn(["properties", "analyse", "antimeridian", "rhumbline_point1"]).length !== 0) {
          wp = wp.setIn(["properties", "analyse", "antimeridian", "is_antimeridian"], true)
        } else if (
          i > 0 &&
          a.get(i - 1).getIn(["properties", "analyse", "antimeridian", "rhumbline_point1"]).length !== 0
        ) {
          wp = wp.setIn(["properties", "analyse", "antimeridian", "is_antimeridian"], true)
        } else {
          wp = wp.setIn(["properties", "analyse", "is_antimeridian"], false)
        }
        return wp
      })
    )
    return newRoute
  },

  /**
   * extendRoute function
   * - add antimeridian points
   * @param {Object} route - GeoJSON
   * @return {Object} - new route lines GeoJSON
   */
  extendRoute(route, seg_distance_in_km = RouteAnalyser.THRESHOLDS.seg_distance_in_km) {
    let features = List()
    //const r=
    route.getIn(["geojson", "features"]).forEach(
      (waypoint, i, a) => {
        //      if (i<a.size-1) {
        //const point1= waypoint.getIn(["geometry","coordinates"])
        features = features.push(waypoint) //a.get(i))

        //const point2= a.get(i+1).getIn(["geometry","coordinates"])

        if (waypoint.getIn(["properties", "legtype"]) === "rhumbline") {
          const antimeridianPoint1 = waypoint.getIn(["properties", "analyse", "antimeridian", "rhumbline_point1"])
          const antimeridianPoint2 = waypoint.getIn(["properties", "analyse", "antimeridian", "rhumbline_point2"])
          //console.log("p1="+JSON.stringify(p1)+" p2="+JSON.stringify(p2)+" antimeridianPoint1="+JSON.stringify(antimeridianPoint1)+" antimeridianPoint2="+JSON.stringify(antimeridianPoint2))
          if (antimeridianPoint1 === null || antimeridianPoint1 === undefined) {
            // -> waypoint.hasIn()
            //const line=turf.lineString([point1,point2],{analyse: {wp1:waypoint,wp2:a.get(i+1)}})
            //console.log("jgjhg line="+JSON.stringify(antimeridianPoint1))
            //features=features.push(line)
          } else {
            //const newWaypoint1=fromJS(turf.point(antimeridianPoint1,{legtype: 'rhumbline_antimeridian',analyse: {}}))
            features = features.push(antimeridianPoint1)
            //const newWaypoint2=fromJS(turf.point(antimeridianPoint2,{legtype: 'rhumbline_antimeridian',analyse: {}}))
            features = features.push(antimeridianPoint2)
          }
        }

        if (waypoint.getIn(["properties", "legtype"]) === "greatcircle") {
          //console.log("greatcircle")
          const greatcircle_line = waypoint.getIn(["properties", "analyse", "greatcircle_line"])
          //console.log("greatcircle_line.size==="+greatcircle_line.size)
          if (greatcircle_line.size > 0) {
            features = features.merge(greatcircle_line) //push(...coords)  List([ 1, 2, 3, 4 ]).merge([5,6])  turf.getCoords(segments)
          } else {
            // console.log("info: List()-Size===0 ") // +waypoint
          }
        }
      }
      //    }
    )
    const r = route.setIn(["geojson", "features"], features)
    //console.log("r="+r)
    return r
  },

  /**
   * linestringSegments function
   * - pre condition: filterRoute()
   * - add antimeridian points
   * - ignore linestring between neighbor antimeridian points
   * @param {Object} route - GeoJSON
   * @return {Object} - new route lines GeoJSON
   */
  linestringSegments(route, seg_distance_in_km = RouteAnalyser.THRESHOLDS.seg_distance_in_km) {
    let features = List()
    //const r=
    route.getIn(["geojson", "features"]).forEach((waypoint, i, a) => {
      if (i < a.size - 1) {
        const legtype1 = waypoint.getIn(["properties", "legtype"])
        const legtype2 = a.get(i + 1).getIn(["properties", "legtype"])
        const type1 = waypoint.getIn(["properties", "type"])
        const type2 = a.get(i + 1).getIn(["properties", "type"])
        if (
          !(
            (legtype1 === "rhumbline_antimeridian" && legtype2 === "rhumbline_antimeridian") ||
            (legtype1 === "greatcircle_antimeridian" && legtype2 === "greatcircle_antimeridian") ||
            (type1 === "antimeridian_point" && type2 === "antimeridian_point")
          )
        ) {
          const point1 = waypoint.getIn(["geometry", "coordinates"])
          const point2 = a.get(i + 1).getIn(["geometry", "coordinates"])
          //console.log("p1="+JSON.stringify(p1)+" p2="+JSON.stringify(p2)+" antimeridianPoint1="+JSON.stringify(antimeridianPoint1)+" antimeridianPoint2="+JSON.stringify(antimeridianPoint2))
          const line = fromJS(turf.lineString([point1, point2], { analyse: { wp1: waypoint, wp2: a.get(i + 1) } }))
          //console.log("jgjhg line="+JSON.stringify(line))
          features = features.push(line)
        }
      }
    })
    return route.setIn(["geojson", "features"], features)
  },

  /**
   * markWithinBbox function
   * - pre condition: setBoundingBoxPolygon()
   * - https://stackoverflow.com/questions/46451548/check-if-one-polygon-crosses-another-polygon-in-turf-js?rq=4
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markWithinBbox(route, bboxPolygon1, bboxPolygon2, markKey = "complete_route") {
    const b = turf.booleanWithin(bboxPolygon1, bboxPolygon2)
    return route.setIn(["geojson", "analyse", "bbox", "is_" + markKey + "_within_map"], b)
  },

  /**
   * markOverlapBbox function
   * - pre condition: setBoundingBoxPolygon()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markOverlapBbox(route, bboxPolygon1, bboxPolygon2, markKey = "complete_route") {
    const b = turf.booleanOverlap(bboxPolygon1, bboxPolygon2)
    return route.setIn(["geojson", "analyse", "bbox", "is_" + markKey + "_overlap_map"], b)
  },

  /**
   * markPointsInBbox function
   * - pre condition: markOverlapBbox()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markPointsInBbox(route, bboxPolygon, markKey = "is_in_map") {
    //console.log("markPointsInBbox()")
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp) => {
        //console.log("wp=xxx"+wp) // suche [-180,40.740214
        const b = turf.booleanPointInPolygon(wp.toJS(), bboxPolygon) // NO: && wp.getIn(["properties","analyse",markKey])
        return wp.setIn(["properties", "analyse", markKey], b)
      })
    )
    return newRoute
  },

  /**
   * markLinesInBbox function
   * @param {Object} routeLines - GeoJSON
   * @return {Object} - GeoJSON
   */
  markLinesInBbox(routeLines, bboxPolygon, markKey = "is_in_map") {
    const newRoute = routeLines.updateIn(["geojson", "features"], (arr) =>
      arr.map(function (line) {
        // line is no immutable object!!!
        //console.log("line=" + JSON.stringify(line.geometry.coordinates, null, 2));
        const b = turf.booleanCrosses(line.toJS(), bboxPolygon) || turf.booleanWithin(line.toJS(), bboxPolygon)
        return fromJS(line).setIn(["properties", "analyse", markKey], b)
      })
    )
    return newRoute
  },

  /**
   * filterRoute function
   * - filter waypoints by filterFunc
   * @param {Object} route - GeoJSON
   * @return {Object} - extended new route (GeoJSON)
   */
  filterRoute(route, filterFunc) {
    //if (markKey!=="is_complete_route") {
    route = route.updateIn(["geojson", "features"], (arr) =>
      arr.filter((wp, i, a) => {
        const b = filterFunc(wp, i, a)
        //console.log("b="+JSON.stringify(b))
        return b
      })
    )
    // }
    return route
  },

  /**
   * setBoundingBoxPolygon function
   * - pre condition: filterRoute()
   * - bbox by waypoints, not by lineStrings
   * - bbox without antimeridian points
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setBoundingBoxPolygon(route, markKey = "complete_route", filterRoute) {
    //console.log("bbox route len="+route.getIn(["geojson","features"]).size)
    const bbox = turf.bbox(filterRoute.getIn(["geojson"]).toJS())
    const bboxPolygon = turf.bboxPolygon(bbox)
    return route.setIn(["geojson", "analyse", "bbox_polygon", markKey], bboxPolygon)
  },

  /**
   * validateSchemaRoute function
   * @param {Object} routeSchema - comment
   * @param {Object} route - comment
   * @return {Object} - comment
   */
  validateSchemaRoute(routeSchema, route) {
    const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}
    const validate = ajv.compile(routeSchema) // -> performance: compile only once
    const isValid = validate(route)
    if (!isValid) {
      console.log(validate.errors)
    }
    return isValid
  },

  validateSchemaRouteReport(route) {
    return RouteAnalyser.validateSchemaRoute(RouteAnalyser.SCHEMA_REPORT_ROUTE, route)
  },

  validateSchemaRoutePlan(route) {
    return RouteAnalyser.validateSchemaRoute(RouteAnalyser.SCHEMA_REPORT_ROUTE, route)
  },

  validateSchemaRouteSuggest(route) {
    return RouteAnalyser.validateSchemaRoute(RouteAnalyser.SCHEMA_REPORT_ROUTE, route)
  },

  /**
   * sortWaypointsByTimestamp function
   * @param {Object} route - GeoJSON
   * @return {Object} - new sorted route by timestamp ascending (GeoJSON)
   */
  sortWaypointsByTimestamp(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) => {
      return arr.sort((wp1, wp2) => {
        return wp1.getIn(["properties", "timestamp"]).localeCompare(wp2.getIn(["properties", "timestamp"])) // *(-1) <- descending
      })
    })
    return newRoute
  },

  /**
   * sortWaypointsByNumber function
   * @param {Object} route - GeoJSON
   * @return {Object} - new sorted route by number and extended_number ascending (GeoJSON)
   */
  sortWaypointsByNumber(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) => {
      return arr.sort((wp1, wp2) => {
        // Extract 'number' and 'extended_number' from wp1 properties
        const number1 = wp1.getIn(["properties", "number"])
        const extendedNumber1 = wp1.getIn(["properties", "extended_number"])

        // Extract 'number' and 'extended_number' from wp2 properties
        const number2 = wp2.getIn(["properties", "number"])
        const extendedNumber2 = wp2.getIn(["properties", "extended_number"])

        // Primary sort by 'number'
        if (number1 !== number2) {
          return number1 - number2
        }

        // Secondary sort by 'extended_number' when 'number' is equal
        return extendedNumber1 - extendedNumber2
      })
    })
    return newRoute
  },

  /**
   * setSimplifyLinestringDouglasPeucker function
   * - set simplify linestring (=subset of route) in key ['geojson','analyse','simplify_linestring']
   * - inclusive zooms with greatcircle linesegments (zooms without greatcircle nPoints!!!)
   * - pre condition: setRouteLinestring()
   * @param {Object} route - GeoJSON
   * @param {Object} tolerance - inclusive zoom factor!!!
   * @return {Object} - extended new route (GeoJSON)
   */
  setSimplifyLinestringDouglasPeucker(route, tolerance) {
    const simplifyLinestring = turf.simplify(route.getIn(["geojson", "analyse", "linestring_complete"]), {
      tolerance: tolerance,
      highQuality: false,
      mutate: false,
    }) // mutate:true -> overwrites linestring_complete!!!
    const newRoute = route.setIn(["geojson", "analyse", "simplify_linestring"], simplifyLinestring)
    //console.log(
    //  "route-length="+newRoute.getIn(['geojson','features']).size
    //  +" simplifyDouglasPeucker-length="+newRoute.getIn(['geojson','analyse','simplify_linestring',"geometry","coordinates"]).length
    //+" simplifyDouglasPeucker="+JSON.stringify(newRoute.getIn(['geojson','analyse','simplify_linestring']),null,2)
    //)
    return newRoute
  },

  /**
   * setEmptyAnalyseObjects function
   * - forall waypoints: insert empty ["properties","analyse"] keys
   * - optional/unnecessary
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setEmptyAnalyseObjects(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        return wp.setIn(["properties", "analyse"], Map()) // Map() statt {} !!!
      })
    )
    return newRoute
  },

  /**
   * setDistance function
   * - distance between two neighbor waypoints p_n and p_n+1
   * - post condition: key 'distance' with type [number,'km]
   * - -> legtypeHandler
   * @param {Object} route - with type GeoJSON
   * @param {String} legtypeHandler - x
   * @return {Object} - new route with type GeoJSON
   */
  setDistance(route, legtypeHandler = "legtype") {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "distance"], [0, "km"])
        }

        const distance = RouteAnalyser.distanceBetweenPoints(a.get(i - 1), wp, legtypeHandler)
        return wp.setIn(["properties", "analyse", "distance"], [distance, "km"])
      })
    )
    return newRoute
  },

  /**
   * distanceBetweenPoints function
   * - pre condition: ???
   * @param {Object} startWp - original waypoint with legtype in {"rhumbline","greatcircle"} and timestamp
   * @param {String} legtypeHandler - "legtype" or "rhumbline"
   * @return {String} - ???
   */
  distanceBetweenPoints(startWp, endWp, legtypeHandler = "rhumbline") {
    //console.log("startWp="+startWp)
    const point1 = startWp.getIn(["geometry"]).toJS()
    const point2 = endWp.getIn(["geometry"]).toJS()
    let distance = null
    if (legtypeHandler !== "rhumbline" && startWp.getIn(["properties", "legtype"]) === "greatcircle") {
      distance = turf.distance(point1, point2, { units: "kilometers" })
    } else {
      distance = turf.rhumbDistance(point1, point2, { units: "kilometers" })
    }
    return distance
  },

  /**
   * setTimeByTimestamps function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setTimeByTimestamps(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "time_by_timestamps"], [0, "seconds"])
        }
        return wp.setIn(
          ["properties", "analyse", "time_by_timestamps"],
          [
            dayjs(wp.getIn(["properties", "timestamp"])).diff(
              // -> dayjs (diff()?) slow
              dayjs(a.get(i - 1).getIn(["properties", "timestamp"])),
              "seconds"
            ),
            "seconds",
          ]
        )
      })
    )
    return newRoute
  },

  /**
   * setSpeedByDistanceTimestamps function
   * - pre condition: setDistance(), setTimeByTimestamps()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSpeedByDistanceTimestamps(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "speed_by_distance_timestamps"], [0, "km/h"])
        }
        return wp.setIn(
          ["properties", "analyse", "speed_by_distance_timestamps"],
          [
            wp.getIn(["properties", "analyse", "distance", 0]) /
              (wp.getIn(["properties", "analyse", "time_by_timestamps", 0]) / 3600),
            "km/h",
          ]
        )
      })
    )
    return newRoute
  },

  /**
   * setSpeedBySog function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSpeedBySog(route) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "speed_by_sog"], [0, "nm/h=knots"])
        }
        return wp.setIn(["properties", "analyse", "speed_by_sog"], [wp.getIn(["properties", "sog"]), "nm/h=knots"])
      })
    )
    return newRoute
  },

  /**
   * setMarkerClass function
   * - pre condition: markLowX()
   * - pre condition: forall waypoints: is_low_distance in {true,false}
   * - nur sinnvoll fuer markLowX()???
   * ACHTUNG: wp1-wp2 -> is_low_distance NUR IN wp2 gespeichert!!!
   * @param {Object} route - GeoJSON
   * @param {Object} markerKey - z.b. 'is_low_distance'
   * @return {Object} - new route GeoJSON
   */
  setMarkerClass(route, markerKey) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (!(0 < i && i < a.size - 1 && wp.toJS().properties.type != "antimeridian_point")) {
          return wp
        } // ACHTUNG: erster und letzter wp haben kein markerKey!!! (z.b. distanz=0 am letzten waypoint) (distanz=0 am ersten waypoint ist sowieso nur platzhalter ohne bedeutung!!!)
        const pre = a.get(i - 1).getIn(["properties", "analyse", markerKey])
        const now = wp.getIn(["properties", "analyse", markerKey])
        const post = a.get(i + 1).getIn(["properties", "analyse", markerKey])
        let classMarker = null // xxx
        if (pre && now && post) {
          classMarker = "inner"
        } else if (!pre && now && post) {
          classMarker = "first"
        } else if (pre && now && !post) {
          classMarker = "last"
        } else if (!pre && now && !post) {
          classMarker = "single"
        } else {
          //console.log("info: kein markerKey treffer in setMarkerClass()");
          return wp
        }
        return wp.setIn(["properties", "analyse", markerKey + "_class"], classMarker)
      })
    )
    return newRoute
  },

  /**
   * markGapDistance function
   * - pre condition: setDistance()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markGapDistance(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_gap_distance"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_gap_distance"],
          wp.getIn(["properties", "analyse", "distance", 0]) >= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markLowDistance function
   * - pre condition: setDistance()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markLowDistance(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_low_distance"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_low_distance"],
          wp.getIn(["properties", "analyse", "distance", 0]) <= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markGapTime function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markGapTime(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_gap_time_by_timestamps"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_gap_time_by_timestamps"],
          wp.getIn(["properties", "analyse", "time_by_timestamps", 0]) >= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markLowTime function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markLowTime(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_low_time_by_timestamps"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_low_time_by_timestamps"],
          wp.getIn(["properties", "analyse", "time_by_timestamps", 0]) <= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markLowSpeedBySog function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markLowSpeedBySog(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_low_speed_by_sog"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_low_speed_by_sog"],
          wp.getIn(["properties", "analyse", "speed_by_sog", 0]) <= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markLowSpeedByDistanceTimestamps function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markLowSpeedByDistanceTimestamps(route, threshold) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        if (i === 0) {
          return wp.setIn(["properties", "analyse", "is_low_speed_by_distance_timestamps"], false)
        }
        return wp.setIn(
          ["properties", "analyse", "is_low_speed_by_distance_timestamps"],
          wp.getIn(["properties", "analyse", "speed_by_distance_timestamps", 0]) <= threshold
        )
      })
    )
    return newRoute
  },

  /**
   * markBetweenTimeRange function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  markBetweenTimeRange(route, start_date, end_date) {
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        // To use `year` granularity pass the third parameter
        //('2010-10-20').isBetween('2010-10-19', dayjs('2010-10-25'), 'year')

        // Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
        // '()' excludes start and end date (default)
        // '[]' includes start and end date
        // '[)' includes the start date but excludes the stop
        // Granuality offers the precision on start and end inclusive checks.
        // For example including the start date on day precision you should use 'day' as 3rd parameter.
        const testy = dayjs("2016-10-30").isBetween("2023-05-26T23:50:00+00:00", "2016-10-30", "second", "[)")
        const b = dayjs(wp.getIn(["properties", "timestamp"])).isBetween(start_date, end_date, "second", "[)")

        return wp.setIn(["properties", "analyse", "is_in_timerange"], b)
      })
    )
    return newRoute
  },

  /**
   * setDistanceHistogram function
   * - security information for vessel operator!
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setDistanceHistogram(route) {
    const histogram = {}
    /*const x=*/ route.getIn(["geojson", "features"]).forEach((wp) => {
      const m = parseInt(wp.getIn(["properties", "analyse", "distance", 0]))
      const key = `${m}-${m + 1} km`
      const value = histogram[key]
      histogram[key] = !(key in histogram) ? 1 : value + 1
    })
    return route.setIn(["geojson", "analyse", "histogram", "distance"], histogram)
  },

  /**
   * setTimeByTimestampsHistogram function
   * - security information for vessel operator!
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setTimeByTimestampsHistogram(route) {
    const histogram = {}
    /*const x=*/ route.getIn(["geojson", "features"]).forEach((wp) => {
      const minute = parseInt(wp.getIn(["properties", "analyse", "time_by_timestamps", 0]) / 60)
      const key = `${minute}-${minute + 1} minutes`
      const value = histogram[key]
      histogram[key] = !(key in histogram) ? 1 : value + 1
    })
    return route.setIn(["geojson", "analyse", "histogram", "time_by_timestamps"], histogram)
  },

  /**
   * setSpeedBySogHistogram function
   * - security information for vessel operator!
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSpeedBySogHistogram(route) {
    const histogram = {}
    /*const x=*/ route.getIn(["geojson", "features"]).forEach((wp) => {
      const m = parseInt(wp.getIn(["properties", "analyse", "speed_by_sog", 0]))
      const key = `${m}-${m + 1} nm/h=knots`
      const value = histogram[key]
      histogram[key] = !(key in histogram) ? 1 : value + 1
    })
    return route.setIn(["geojson", "analyse", "histogram", "speed_by_sog"], histogram)
  },

  /**
   * setSpeedByDistanceTimestampsHistogram function
   * - security information for vessel operator!
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSpeedByDistanceTimestampsHistogram(route) {
    const histogram = {}
    /*const x=*/ route.getIn(["geojson", "features"]).forEach((wp) => {
      const m = parseInt(wp.getIn(["properties", "analyse", "speed_by_distance_timestamps", 0]))
      const key = `${m}-${m + 1} nm/h=knots`
      const value = histogram[key]
      histogram[key] = !(key in histogram) ? 1 : value + 1
    })
    return route.setIn(["geojson", "analyse", "histogram", "speed_by_distance_timestamps"], histogram)
  },

  /**
   * setSumDistance function
   * - pre condition: setDistance()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSumDistance(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(["geojson", "features", i, "properties", "analyse", "sum_distance"], [0, "km"])
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_distance"],
          [
            newRoute.getIn(["geojson", "features", i - 1, "properties", "analyse", "sum_distance", 0]) +
              route.getIn(["geojson", "features", i, "properties", "analyse", "distance", 0]),
            "km",
          ]
        )
      }
    }
    return newRoute
  },

  /**
   * setRemainingDistance function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setRemainingDistance(route) {
    const sum_distance = route.getIn([
      "geojson",
      "features",
      route.getIn(["geojson", "features"]).size - 1,
      "properties",
      "analyse",
      "sum_distance",
      0,
    ])
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        return wp.setIn(
          ["properties", "analyse", "remaining_distance"],
          [sum_distance - wp.getIn(["properties", "analyse", "sum_distance", 0]), "km"]
        )
      })
    )
    return newRoute
  },

  /**
   * setSumTimeByTimestamps function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSumTimeByTimestamps(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_time_by_timestamps"],
          [0, "seconds"]
        )
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_time_by_timestamps"],
          [
            newRoute.getIn(["geojson", "features", i - 1, "properties", "analyse", "sum_time_by_timestamps", 0]) +
              route.getIn(["geojson", "features", i, "properties", "analyse", "time_by_timestamps", 0]),
            "seconds",
          ]
        )
      }
    }
    return newRoute
  },

  /**
   * setRemainingTimeByTimestamps function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setRemainingTimeByTimestamps(route) {
    const sum_time_by_timestamps = route.getIn([
      "geojson",
      "features",
      route.getIn(["geojson", "features"]).size - 1,
      "properties",
      "analyse",
      "sum_time_by_timestamps",
      0,
    ])
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        return wp.setIn(
          ["properties", "analyse", "remaining_time_by_timestamps"],
          [sum_time_by_timestamps - wp.getIn(["properties", "analyse", "sum_time_by_timestamps", 0]), "seconds"]
        )
      })
    )
    return newRoute
  },

  /**
   * setAvgSpeedByDistanceTimestamps function
   * - pre condition: setSumSpeedByDistanceTimestamps()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setAvgSpeedByDistanceTimestamps(route) {
    const n = route.getIn(["geojson", "features"]).size
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        return wp.setIn(
          ["properties", "analyse", "avg_speed_by_distance_timestamps"],
          [wp.getIn(["properties", "analyse", "sum_speed_by_distance_timestamps", 0]) / n, "km/h"]
        )
      })
    )
    return newRoute
  },

  /**
   * setMaxSpeedByDistanceTimestamps function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setMaxSpeedByDistanceTimestamps(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "max_speed_by_distance_timestamps"],
          [0, "km/h"]
        )
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "max_speed_by_distance_timestamps"],
          [
            Math.max(
              newRoute.getIn([
                "geojson",
                "features",
                i - 1,
                "properties",
                "analyse",
                "max_speed_by_distance_timestamps",
                0,
              ]),
              route.getIn(["geojson", "features", i, "properties", "analyse", "speed_by_distance_timestamps", 0])
            ),
            "km/h",
          ]
        )
      }
    }
    return newRoute
  },

  /**
   * setAvgSpeedBySog function
   * - pre condition: setSumSpeedBySog()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setAvgSpeedBySog(route) {
    const n = route.getIn(["geojson", "features"]).size
    const newRoute = route.updateIn(["geojson", "features"], (arr) =>
      arr.map((wp, i, a) => {
        return wp.setIn(
          ["properties", "analyse", "avg_speed_by_sog"],
          [wp.getIn(["properties", "analyse", "sum_speed_by_sog", 0]) / n, "nm/h=knots"]
        )
      })
    )
    return newRoute
  },

  /**
   * setMaxSpeedBySog function
   * - pre condition: setSpeedBySog()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setMaxSpeedBySog(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "max_speed_by_sog"],
          [0, "nm/h=knots"]
        )
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "max_speed_by_sog"],
          [
            Math.max(
              newRoute.getIn(["geojson", "features", i - 1, "properties", "analyse", "max_speed_by_sog", 0]),
              route.getIn(["geojson", "features", i, "properties", "analyse", "speed_by_sog", 0])
            ),
            "nm/h=knots",
          ]
        )
      }
    }
    return newRoute
  },

  /**
   * setSumSpeedBySog function
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSumSpeedBySog(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_speed_by_sog"],
          [0, "nm/h=knots"]
        )
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_speed_by_sog"],
          [
            newRoute.getIn(["geojson", "features", i - 1, "properties", "analyse", "sum_speed_by_sog", 0]) +
              route.getIn(["geojson", "features", i, "properties", "analyse", "speed_by_sog", 0]),
            "nm/h=knots",
          ]
        )
      }
    }
    return newRoute
  },

  /**
   * setSumSpeedByDistanceTimestamps function
   * - pre condition: setSpeedByDistanceTimestamps()
   * @param {Object} route - GeoJSON
   * @return {Object} - new route GeoJSON
   */
  setSumSpeedByDistanceTimestamps(route) {
    let newRoute = route
    for (let i = 0; i < route.getIn(["geojson", "features"]).size; i++) {
      if (i === 0) {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_speed_by_distance_timestamps"],
          [0, "km/h"]
        )
      } else {
        newRoute = newRoute.setIn(
          ["geojson", "features", i, "properties", "analyse", "sum_speed_by_distance_timestamps"],
          [
            newRoute.getIn([
              "geojson",
              "features",
              i - 1,
              "properties",
              "analyse",
              "sum_speed_by_distance_timestamps",
              0,
            ]) + route.getIn(["geojson", "features", i, "properties", "analyse", "speed_by_distance_timestamps", 0]),
            "km/h",
          ]
        )
      }
    }
    return newRoute
  },
}

// -----------------------------------------------------------------------------------------------------------
// Route class
// -----------------------------------------------------------------------------------------------------------

// JavaScript: How to create chainable functions:
// https://aparnajoshi.netlify.app/javascript-how-to-create-chainable-functions

class Route {
  static analyseRoute() {
    //console.log("Route.analyse()")
    const route = new Route(REPORT_ROUTES[0])
      .sortWaypointsByTimestamp()
      .setEmptyAnalyseObjects()
      .setRouteLinestring()
      .setSimplifyLinestringDouglasPeucker()
      .setDistance()
      .setTimeByTimestamps()
      .setSpeedByDistanceTimestamps()
      .setSpeedBySog()
      .setSumDistance()
      .setRemainingDistance()
      .setSumTimeByTimestamps()
      .setRemainingTimeByTimestamps()
      .setMaxSpeedByDistanceTimestamps()
      .setMaxSpeedBySog()
      .setSumSpeedByDistanceTimestamps()
      .setSumSpeedBySog()
      .setAvgSpeedByDistanceTimestamps()
      .setAvgSpeedBySog()
      .markGapDistance(RouteAnalyser.THRESHOLDS.gap_distance)
      .markLowDistance(RouteAnalyser.THRESHOLDS.low_distance)
      .markGapTime(RouteAnalyser.THRESHOLDS.gap_time)
      .markLowTime(RouteAnalyser.THRESHOLDS.low_time)
      .markLowSpeedBySog(RouteAnalyser.THRESHOLDS.low_speed_by_sog)
      .markLowSpeedByDistanceTimestamps(RouteAnalyser.THRESHOLDS.low_speed_by_distance_timestamps)

      .setDistanceHistogram()
      .setTimeByTimestampsHistogram()
      .setSpeedBySogHistogram()
      .setSpeedByDistanceTimestampsHistogram()

      .setAntimeridianRhumblinePoints()
      .setAntimeridianWaypointsGreatCircle(RouteAnalyser.THRESHOLDS.seg_distance_in_km)
    //.extendRoute(RouteAnalyser.THRESHOLDS.seg_distance_in_km)

    //.filterRoute(RouteAnalyser.filterRoute(route,"is_complete_route"))
    //.setBoundingBoxPolygon("is_complete_route")
    //.markWithinBbox(RouteAnalyser.BBOX_MAP_TEST,"is_complete_route")
    //.markOverlapBbox(RouteAnalyser.BBOX_MAP_TEST,"is_overlap_complete_route")
    //.markPointsInBbox(RouteAnalyser.BBOX_MAP_TEST,"is_overlap_complete_route")

    //let routeLines=RouteAnalyser.linestringSegments()
    //.setBoundingBoxPolygon()
    //.markWithinBbox(RouteAnalyser.BBOX_MAP_TEST)
    //.markOverlapBbox(RouteAnalyser.BBOX_MAP_TEST)
    //.markLinesInBbox()

    return route
  }

  constructor(route) {
    //this.route=JSON.parse(JSON.stringify(route)); // deep copy, .clone() faster
    this.value = fromJS(route) // deep copy
  }

  sortWaypointsByTimestamp() {
    this.value = RouteAnalyser.sortWaypointsByTimestamp(this.value)
    return this
  }

  setAntimeridianRhumblinePoints() {
    this.value = RouteAnalyser.setAntimeridianRhumblinePoints(this.value)
    return this
  }

  setAntimeridianWaypointsGreatCircle() {
    this.value = RouteAnalyser.setAntimeridianWaypointsGreatCircle(this.value)
    return this
  }

  setEmptyAnalyseObjects() {
    this.value = RouteAnalyser.setEmptyAnalyseObjects(this.value)
    return this
  }

  setDistance() {
    this.value = RouteAnalyser.setDistance(this.value)
    return this
  }

  setTimeByTimestamps() {
    this.value = RouteAnalyser.setTimeByTimestamps(this.value)
    return this
  }

  setSpeedByDistanceTimestamps() {
    this.value = RouteAnalyser.setSpeedByDistanceTimestamps(this.value)
    return this
  }

  setSpeedBySog() {
    this.value = RouteAnalyser.setSpeedBySog(this.value)
    return this
  }

  setSumDistance() {
    this.value = RouteAnalyser.setSumDistance(this.value)
    return this
  }

  setRemainingDistance() {
    this.value = RouteAnalyser.setRemainingDistance(this.value)
    return this
  }

  setSumTimeByTimestamps() {
    this.value = RouteAnalyser.setSumTimeByTimestamps(this.value)
    return this
  }

  setRemainingTimeByTimestamps() {
    this.value = RouteAnalyser.setRemainingTimeByTimestamps(this.value)
    return this
  }

  setMaxSpeedByDistanceTimestamps() {
    this.value = RouteAnalyser.setMaxSpeedByDistanceTimestamps(this.value)
    return this
  }

  setMaxSpeedBySog() {
    this.value = RouteAnalyser.setMaxSpeedBySog(this.value)
    return this
  }

  setSumSpeedByDistanceTimestamps() {
    this.value = RouteAnalyser.setSumSpeedByDistanceTimestamps(this.value)
    return this
  }

  setSumSpeedBySog() {
    this.value = RouteAnalyser.setSumSpeedBySog(this.value)
    return this
  }

  setAvgSpeedByDistanceTimestamps() {
    this.value = RouteAnalyser.setAvgSpeedByDistanceTimestamps(this.value)
    return this
  }

  setAvgSpeedBySog() {
    this.value = RouteAnalyser.setAvgSpeedBySog(this.value)
    return this
  }

  markGapDistance(threshold) {
    this.value = RouteAnalyser.markGapDistance(this.value, threshold)
    return this
  }

  markLowDistance(threshold) {
    this.value = RouteAnalyser.markLowDistance(this.value, threshold)
    return this
  }

  markGapTime(threshold) {
    this.value = RouteAnalyser.markGapTime(this.value, threshold)
    return this
  }

  markLowTime(threshold) {
    this.value = RouteAnalyser.markLowTime(this.value, threshold)
    return this
  }

  markLowSpeedBySog(threshold) {
    this.value = RouteAnalyser.markLowSpeedBySog(this.value, threshold)
    return this
  }

  markLowSpeedByDistanceTimestamps(threshold) {
    this.value = RouteAnalyser.markLowSpeedByDistanceTimestamps(this.value, threshold)
    return this
  }

  setDistanceHistogram() {
    this.value = RouteAnalyser.setDistanceHistogram(this.value)
    return this
  }

  setTimeByTimestampsHistogram() {
    this.value = RouteAnalyser.setTimeByTimestampsHistogram(this.value)
    return this
  }

  setSpeedBySogHistogram() {
    this.value = RouteAnalyser.setSpeedBySogHistogram(this.value)
    return this
  }

  setSpeedByDistanceTimestampsHistogram() {
    this.value = RouteAnalyser.setSpeedByDistanceTimestampsHistogram(this.value)
    return this
  }

  setBoundingBoxPolygon() {
    this.value = RouteAnalyser.setBoundingBoxPolygon(this.value)
    return this
  }

  setRouteLinestring() {
    this.value = RouteAnalyser.setRouteLinestring(this.value)
    return this
  }

  setSimplifyLinestringDouglasPeucker() {
    this.value = RouteAnalyser.setSimplifyLinestringDouglasPeucker(this.value)
    return this
  }

  markWithinBbox(bbox, markKey = "is_complete_route") {
    this.value = RouteAnalyser.markWithinBbox(this.value, bbox, markKey)
    return this
  }

  markOverlapBbox(bbox, markKey = "is_complete_route") {
    this.value = RouteAnalyser.markOverlapBbox(this.value, bbox, markKey)
    return this
  }

  markPointsInBbox(bbox, markKey = "is_overlap_complete_route") {
    this.value = RouteAnalyser.markPointsInBbox(this.value, bbox, markKey)
    return this
  }

  markLinesInBbox() {
    this.value = RouteAnalyser.markLinesInBbox(this.value)
    return this
  }

  linestringSegments(seg_distance_in_km = RouteAnalyser.THRESHOLDS.seg_distance_in_km) {
    this.value = RouteAnalyser.linestringSegments(this.value, seg_distance_in_km)
    return this
  }
}

// -----------------------------------------------------------------------------------------------------------

export { RouteAnalyser, Route }
