import React, { useState, useEffect } from 'react';

const GeolocationWatcher = () => {
  const [currentPosition, setCurrentPosition] = useState(null);
/*
  useEffect(() => {
    const geoId = navigator.geolocation.watchPosition(
      (position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => console.log(error),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
    );

    return () => navigator.geolocation.clearWatch(geoId);
  }, []);
*/
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          (error) => console.log(error),
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
        );
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {currentPosition && (
        <p>
          Current Position: ({currentPosition.lat.toFixed(10)},{' '}
          {currentPosition.lng.toFixed(10)})
        </p>
      )}
    </div>
  );
};

export default GeolocationWatcher;
