import * as React from 'react';
import L from "leaflet";
import { useMap  } from 'react-leaflet'
//import { useEffect } from 'react';
import terminator from '@joergdietrich/leaflet.terminator'

// https://www.npmjs.com/package/@joergdietrich/leaflet.terminator
export default function MyTerminator() {
    //const [x,setX]=React.useState(null)
    const map = useMap();

    React.useEffect(() => {
        if (!map) return;
        //if (x) return;
        //if (x.current) return;
        const d=terminator({fillOpacity:0.1,interactive:false,color:'#fff0'}) // interactive:false -> keine mouse events
        //console.log("MyTerminator.useEffect add x="+JSON.stringify(d));
        d.addTo(map) // https://joergdietrich.github.io/Leaflet.Terminator/ -> color+fillColor
        //setX(d)
        
        const intervalID=setInterval(function() {
          //console.log("terminator timer")
          d.setTime();
        }, 60000); // Every minute
        //map.addEventListener('zoomstart movestart popupopen', function(e) {
        //  d.setTime();
        //});

        //const f=x
        //console.log("MyTerminator.useEffect unmount start xxxx "+JSON.stringify(f))
        return () => {
          //console.log("MyTerminator.useEffect unmount"+d)
            //componentWillUnmount.current = true
            //console.log("MyTerminator.useEffect unmount start "+JSON.stringify(d))
           //if (!map && !x && !x.current) { map.clearLayers(x.current) }
           clearInterval(intervalID);
           d.removeFrom(map)
        }
      }, 
      [map]
    );

    return null;
  }