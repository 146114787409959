import * as React from "react"
import L from "leaflet"
import { useMap } from "react-leaflet"
//import { useEffect } from 'react';
import "leaflet.polylinemeasure/Leaflet.PolylineMeasure.css"
import PolylineMeasure from "leaflet.polylinemeasure"
//import "../libs/polylinemeasure/Leaflet.PolylineMeasure-master/Leaflet.PolylineMeasure.css"
//import PolylineMeasure from '../libs/polylinemeasure/Leaflet.PolylineMeasure-master/Leaflet.PolylineMeasure';

export default function MyPolylineMeasure() {
  const map = useMap()

  React.useEffect(() => {
    if (!map) return
    const polylineMeasureControl = new PolylineMeasure({
      position: "bottomright",
      unit: "nauticalmiles",
      unitControlTitle: {
        // Title texts to show on the Unit Control
        text: "Change Units",
        kilometres: "kilometres",
        landmiles: "land miles",
        nauticalmiles: "nautical miles",
      },
      unitControlLabel: {
        // Unit symbols to show in the Unit Control and measurement labels
        metres: "m",
        kilometres: "km",
        feet: "ft",
        landmiles: "mi",
        nauticalmiles: "nm",
      },
      tooltipTextFinish: "Click to <b>finish line</b><br>",
      tooltipTextDelete: "Press SHIFT plus - and click to <b>delete point</b>",
      tooltipTextMove: "Click and drag to <b>move point</b><br>",
      tooltipTextResume: "<br>Press CTRL plus + and click to <b>resume line</b>",
      tooltipTextAdd: "Press CTRL and click to <b>add point</b>",
      // language dependend labels for point's tooltips
      measureControlTitleOn: "Turn on Voyage Planner", // Title for the control going to be switched on
      measureControlTitleOff: "Turn off Voyage Planner", // Title for the control going to be switched off
      showBearings: true,
      clearMeasurementsOnStop: true,
      showClearControl: false,
      showUnitControl: true,
      startCircle: {
        // Style settings for circle marker indicating the starting point of the polyline
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#0f0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      intermedCircle: {
        // Style settings for all circle markers between startCircle and endCircle
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#0f0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      currentCircle: {
        // Style settings for circle marker indicating the latest point of the polyline during drawing a line
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#0f0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      endCircle: {
        // Style settings for circle marker indicating the last point of the polyline
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#0f0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
    })
    polylineMeasureControl.addTo(map)

    // Add the control to your map
    map.addControl(polylineMeasureControl)
    // After the control has been added to the map, modify its style
    // document.querySelector(".leaflet-control-polylineMeasure").style.bottom = "50px"

    function debugevent(e) {
      console.debug(e.type, e, polylineMeasureControl._currentLine)
      // console.log(e.type)
    }
    /*
      map.on('polylinemeasure:toggle', debugevent);
      map.on('polylinemeasure:start', debugevent);
      map.on('polylinemeasure:resume', debugevent);
      map.on('polylinemeasure:finish', debugevent);
      map.on('polylinemeasure:change', debugevent);
      map.on('polylinemeasure:clear', debugevent);
      map.on('polylinemeasure:add', debugevent);
      map.on('polylinemeasure:insert', debugevent);
      map.on('polylinemeasure:move', debugevent);
      map.on('polylinemeasure:remove', debugevent);
*/
    return () => {
      //console.log("MyPolylineMeasure.useEffect unmount"+d)
      //componentWillUnmount.current = true
      //console.log("MyLatlngGraticule.useEffect unmount start "+JSON.stringify(d))
      //if (!map && !x && !x.current) { map.clearLayers(x.current) }

      /*         
         map.on('polylinemeasure:toggle', null);
         map.on('polylinemeasure:start', null);
         map.on('polylinemeasure:resume', null);
         map.on('polylinemeasure:finish', null);
         map.on('polylinemeasure:change', null);
         map.on('polylinemeasure:clear', null);
         map.on('polylinemeasure:add', null);
         map.on('polylinemeasure:insert', null);
         map.on('polylinemeasure:move', null);
         map.on('polylinemeasure:remove', null);
*/

      map.removeControl(polylineMeasureControl)
    }
  }, [map])
  return null
}
