import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles" // adaptV4Theme
import axios from "axios"

import { AccessContextProvider } from "./modules/main/AccessContext"
import environment from "./modules/main/environment"
import MainComponent from "./MainComponent"
import LoginForm from "./components/LoginForm/LoginForm"
import theme from "./modules/main/theme"
import PostGraphileApi from "./modules/main/PostGraphileApiZeus"

const applicationTheme = createTheme(theme) //adaptV4Theme(theme))

function buildConfig(payload, dataApi) {
  let config = {
    // TODO Vollzugriff auf alle Daten bei folgenden Rechten vs Sicherheit? (2022-03-16)
    customerIdFix:
      payload.access.includes("sc_service") || payload.access.includes("sc_all") ? undefined : payload["customerId"],
  }
  return config
}

const Application = () => {
  const { t } = useTranslation()
  const [access, setAccess] = useState({ loggedIn: false })
  const [error, setError] = useState({ msg: "" })
  const [appl] = useState({ title: process.env.REACT_APP_NAME, version: process.env.REACT_APP_VERSION })
  function logout() {
    setAccess({ loggedIn: false })
  }

  useEffect(() => {
    document.title = appl.title
  }, [appl])

  async function login(credentials) {
    let api = axios.create({ baseURL: environment.auth.url })
    let accessToken = undefined
    let status = undefined
    let payload = undefined
    let config = undefined
    let pages = undefined

    await api
      .post(environment.auth.path, { name: credentials.name, password: credentials.password })
      .then((result) => {
        accessToken = result.data.accessToken
        let splitted = accessToken.split(".")
        if (splitted.length === 3) {
          payload = JSON.parse(atob(splitted[1]))
          // Check if access_key 'meteotracker' is available
          if (!payload.access.includes("meteotracker")) {
            setError((currentError) => ({ ...currentError, msg: t("login_failed") }))
            status = 500
            setAccess({ loggedIn: false })
            return
          }
          // Kunden Daten aus der SC-Datenbank
          let customerPromise = new Promise((resolve, reject) => {
            const query =
              "{ customers (condition: {id: " +
              payload["customerId"] +
              "}) { nodes {firstName,lastName,organisationName }} }"
            PostGraphileApi.fetchRequest(
              environment.sc_api.url,
              PostGraphileApi.httpRequestData(query, accessToken),
              "customer data after login, customer id: " + payload["customerId"]
            )
              .then((response) => {
                if (response?.data?.customers?.nodes?.length === 1) {
                  resolve(response.data.customers.nodes[0])
                } else {
                  reject("customer not found")
                }
              })
              .catch((err) => {
                reject("customer error: " + err)
              })
          })

          Promise.all([customerPromise]).then(
            (values) => {
              // customerPromise result: customer data
              let customer = values[0]
              payload["customerName"] = customer.organisationName
                ? customer.organisationName
                : customer.firstName + customer.lastName

              config = buildConfig(payload, null)
              setError((currentError) => ({ ...currentError, msg: "" }))
              status = 200
              setAccess((currentAccess) => ({
                ...currentAccess,
                configuration: config,
                environment: environment,
                pages: pages,
                loggedIn: true,
                status: status,
                token: accessToken,
                payload: payload,
              }))
            },
            (reason) => {
              // TODO für produktiv Betrieb err ändern / rausnehmen
              // setError( (currentError) => ( {...currentError, msg: t("login_failed_sorry") + " (" + reason + ")"} ) )
              setError((currentError) => ({ ...currentError, msg: t("login_failed_sorry") }))
              status = 500
            }
          )
        } else {
          // TODO für produktiv Betrieb err ändern / rausnehmen
          // let err = "invalid access token"
          // console.log(err)
          // setError( (currentError) => ( {...currentError, msg: t("login_failed") + " (" + err + ")"} ) )
          setError((currentError) => ({ ...currentError, msg: t("login_failed") }))
          status = 500
        }
      })
      .catch((err) => {
        setError((currentError) => ({ ...currentError, msg: t("login_failed") }))
        if (err.response) {
          // console.log(err)
          status = err.response.status
        } else {
          status = 500
        }
      })
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={applicationTheme}>
        {access.loggedIn ? (
          <AccessContextProvider values={access}>
            {/* <LandingPage logout={logout} version={appl.version} /> */}
            <MainComponent logout={logout} access={access} />
          </AccessContextProvider>
        ) : (
          <LoginForm login={login} error={error} />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Application
