import { Slider, Box, Grid, IconButton, Typography } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import StopIcon from "@mui/icons-material/Stop"
import { styled } from "@mui/system"

import * as React from "react"
import { useState, useEffect } from "react"
import SliderDate from "./sliderDate"
import ExpandVesselGraph from "./ExpandVesselGraph"
import VesselGraph from "../vessel/VesselGraph"

const CustomSlider = styled(Slider)({
  "& .MuiSlider-valueLabel": {
    backgroundColor: "white",
    color: "black",
    borderRadius: "4px",
    padding: "4px 8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  },
})

export default function TimeSlider({
  vesselRealRoutes,
  switchReported,
  switchPlan,
  switchSuggest,
  sliderPosition,
  setSliderPosition,
  sliderTicks,
  setShowChart,
}) {
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        handleNext()
      }, 300)
      return () => clearInterval(interval)
    }
  }, [isPlaying, sliderPosition])

  useEffect(() => {
    if (!switchReported && !switchPlan && !switchSuggest) {
      setIsPlaying(false)
    }
  }, [switchReported, switchPlan, switchSuggest])

  // function handleChange(event, newSliderPosition) {
  //   console.log("slider change", sliderPosition, sliderTicks, newSliderPosition)
  //   setSliderPosition(sliderTicks[newSliderPosition])
  // }
  function handleChange(event, newSliderPosition) {
    // console.log("slider change", sliderPosition, sliderTicks, newSliderPosition)
    // Find the tick object in sliderTicks that matches the newSliderPosition
    const newTick = sliderTicks.find((tick) => tick.value === newSliderPosition)
    // console.log("newTick", newTick)
    // Check if we found a matching tick
    if (newTick) {
      setSliderPosition(newTick)
    } else {
      console.warn("No matching tick found for position:", newSliderPosition)
    }
  }

  function snapToNearestTick(value, ticks) {
    // Find the tick that is closest to the current slider value
    let closestTick = ticks[0]
    let smallestDifference = Math.abs(value - ticks[0].value)

    for (let i = 1; i < ticks.length; i++) {
      const currentDifference = Math.abs(value - ticks[i].value)
      if (currentDifference < smallestDifference) {
        smallestDifference = currentDifference
        closestTick = ticks[i]
      }
    }

    // Return the value of the closest tick
    return closestTick.value
  }

  function handleBack() {
    const currentIndex = sliderTicks.findIndex((tick) => tick.value === sliderPosition.value)
    let nextIndex = currentIndex - 1
    if (nextIndex < 0) {
      nextIndex = sliderTicks.length - 1
    }
    setSliderPosition(sliderTicks[nextIndex])
  }

  function handleNext() {
    const currentIndex = sliderTicks.findIndex((tick) => tick.value === sliderPosition.value)
    let nextIndex = currentIndex + 1
    if (nextIndex >= sliderTicks.length) {
      nextIndex = 0
    }
    setSliderPosition(sliderTicks[nextIndex])
  }

  function togglePlay() {
    setIsPlaying(!isPlaying)
  }

  function filterTicksForMarks(sliderTicks) {
    return sliderTicks
      .filter((tick) => {
        const date = new Date(tick.label)
        const hours = date.getUTCHours()
        return hours === 0
      })
      .map((tick) => ({
        ...tick,
        label: formatLabel(tick.label),
      }))
  }

  function formatLabel(label) {
    const date = new Date(label)
    const options = { weekday: "short", day: "numeric", timeZone: "UTC" }
    let formattedDate = date.toLocaleDateString("en-US", options)
    formattedDate = formattedDate.replace(/[.,]/g, "")
    return formattedDate
  }

  const labels = filterTicksForMarks(sliderTicks)

  if (vesselRealRoutes !== null && (switchReported || switchPlan || switchSuggest)) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <div style={{ display: "flex", alignItems: "center", width: "130px", flexShrink: 0 }}>
          <IconButton sx={{ color: "#004f7d" }} onClick={togglePlay}>
            {isPlaying ? <StopIcon sx={{ ml: 1 }} /> : <PlayArrowIcon sx={{ ml: 1 }} />}
          </IconButton>
          <IconButton sx={{ color: "#004f7d" }} onClick={handleBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton sx={{ color: "#004f7d" }} onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
        {/* <div style={{ position: "relative", width: "100%" }}> */}
        <div style={{ position: "relative", width: "100%", paddingLeft: "50px", paddingRight: "50px" }}>
          {/* Container to hold both the Slider and ExpandVesselGraph */}
          {/* <ExpandVesselGraph
            switchReported={switchReported}
            setShowChart={setShowChart} // Pass the necessary props, define setShowChart state if not yet defined
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          /> */}
          <CustomSlider
            key={"slider" + sliderTicks[sliderTicks.length - 1].value}
            onChange={handleChange}
            value={sliderPosition.value} // pass the numeric value here
            valueLabelDisplay="on"
            defaultValue={sliderTicks[sliderTicks.length - 1].value}
            marks={labels}
            steps={null}
            min={sliderTicks[0].value}
            max={sliderTicks[sliderTicks.length - 1].value}
            sx={{ color: "#004f7d" }} // This allows the slider to take up remaining space
            valueLabelFormat={(value) => {
              const currentTick = sliderTicks.find((tick) => tick.value === value)
              if (currentTick) {
                const date = new Date(currentTick.label)
                return date.toUTCString().slice(17, 22) // Extracts "hh:mm" from the UTC string
              }
              return ""
            }}
          />

          {/* <VesselGraph vesselRealRoutes={vesselRealRoutes} switchReported={switchReported} showChart={showChart} /> */}
        </div>
        {/* <div style={{ width: "280px", flexShrink: 0 }}>
          <SliderDate sliderPosition={sliderPosition} sliderTicks={sliderTicks} />
        </div> */}
      </Box>
    )
  }
}
// https://stackoverflow.com/questions/66983676/control-the-material-ui-slider-with-a-play-and-stop-buttons-in-react-js
