import { useState, useEffect } from "react"

// Function to recursively convert XML nodes to a JavaScript object
function xmlNodeToObject(node) {
  // Base case for text nodes
  if (node.nodeType === 3) {
    return node.nodeValue
  }
  let obj = {}
  if (node.attributes) {
    for (let attr of node.attributes) {
      obj[attr.nodeName] = attr.nodeValue
    }
  }
  // Process child nodes
  let hasChildren = false
  node.childNodes.forEach((child) => {
    hasChildren = true
    const childObj = xmlNodeToObject(child)
    // Check if the child has a tag name and add it to the object
    if (child.tagName) {
      if (!obj[child.tagName]) {
        obj[child.tagName] = []
      }
      obj[child.tagName].push(childObj)
    } else if (typeof childObj === "string") {
      obj["text"] = childObj.trim()
    }
  })
  if (!hasChildren) {
    return obj
  }
  return obj
}

export const useFetchGeoServer = ({
  GEOSERVER_URL,
  availableModels,
  checkedWMSLayers,
  setCheckedWMSLayers,
  username,
  password,
}) => {
  const [geoServerCapabilities, setGeoServerCapabilities] = useState(null)

  useEffect(() => {
    // Ensure the necessary data is provided before making the request
    if (!GEOSERVER_URL || !availableModels || !username || !password) return

    const fetchDataForModel = async (model) => {
      const fullGeoServerUrl = `${GEOSERVER_URL}${model}/wms?request=GetCapabilities`
      const base64Credentials = btoa(`${username}:${password}`)
      try {
        // console.log(fullGeoServerUrl)
        const response = await fetch(fullGeoServerUrl, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
            "Content-Type": "application/xml",
          },
        })

        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`) // or handle errors
        }

        const textData = await response.text() // Get the response as text

        // Parse the XML text
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(textData, "application/xml")

        // Convert the XML document to a JavaScript object
        const dataObj = xmlNodeToObject(xmlDoc.documentElement)

        // Initialize an empty object to hold the parsed data
        let parsedData = {}
        let newData = {}

        // Assume 'Layer' is the parent tag that contains each 'Name', 'Dimension', and 'Style'
        const layers = Array.from(xmlDoc.querySelectorAll("Layer > Layer"))

        layers.forEach((layer) => {
          // Extracting the 'Name' text content to use as a key
          const nameElement = layer.querySelector("Name")
          const name = nameElement ? nameElement.textContent : null
          const splitname = name ? name.split("_")[0] : null

          // Extracting 'Dimension' as an array of timestamps
          const dimensionElement = layer.querySelector("Dimension")
          const dimensionText = dimensionElement ? dimensionElement.textContent : null
          const dimensions = dimensionText ? dimensionText.split(",") : []

          // Extracting EX_GeographicBoundingBox details
          const geoBBoxElement = layer.getElementsByTagName("EX_GeographicBoundingBox")[0]
          const eastBoundLongitude = geoBBoxElement?.getElementsByTagName("eastBoundLongitude")[0]?.textContent
          const westBoundLongitude = geoBBoxElement?.getElementsByTagName("westBoundLongitude")[0]?.textContent
          const northBoundLatitude = geoBBoxElement?.getElementsByTagName("northBoundLatitude")[0]?.textContent
          const southBoundLatitude = geoBBoxElement?.getElementsByTagName("southBoundLatitude")[0]?.textContent
          const geographicBoundingBox = {
            eastBoundLongitude,
            westBoundLongitude,
            northBoundLatitude,
            southBoundLatitude,
          }

          // Initialize the parent key if it doesn't exist
          if (!newData[splitname]) {
            newData[splitname] = {}
          }
          // Create or update the child key with the extracted details
          newData[splitname][name] = {
            Dimension: dimensions,
            Style: Array.from(layer.querySelectorAll("Style > Name")).map((styleNameElement) => {
              return styleNameElement.textContent
            }),
            BoundingBox: geographicBoundingBox,
          }

          if (parsedData[splitname]) {
            // If the splitname already exists, add the new styles to the existing ones
            parsedData[splitname].Style.push(
              ...Array.from(layer.querySelectorAll("Style > Name")).map((styleNameElement) => {
                return styleNameElement.textContent
              })
            )
          } else {
            // If the splitname does not exist, create a new entry
            parsedData[splitname] = {
              Name: splitname,
              Dimension: dimensions,
              Style: Array.from(layer.querySelectorAll("Style > Name")).map((styleNameElement) => {
                return styleNameElement.textContent
              }),
              BoundingBox: geographicBoundingBox,
            }
          }
        })
        // console.log("parseData", parsedData)
        // console.log("newData", newData)

        return { [model]: newData }
      } catch (error) {
        console.error("Failed to fetch info from GeoServer:", error)
        return null
      }
    }

    const fetchAllData = async () => {
      let allData = {}
      for (const model of availableModels) {
        const modelData = await fetchDataForModel(model)
        if (modelData) {
          allData = { ...allData, ...modelData }
        }
      }
      // console.log("useFetchGeoServer", allData)
      setGeoServerCapabilities(allData)
    }

    fetchAllData()
  }, [GEOSERVER_URL, availableModels])

  return { geoServerCapabilities: geoServerCapabilities }
}
