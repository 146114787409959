import * as React from "react"
import L from "leaflet"
import { useMap } from "react-leaflet"
import latlngGraticule from "leaflet-graticule"

export default function MyLatlngGraticule() {
  const map = useMap()

  React.useEffect(() => {
    if (!map) return
    //if (x) return;
    //if (x.current) return;
    const d = L.latlngGraticule({
      showLabel: true,
      zoomInterval: [
        // {start: 2, end: 3, interval: 30},
        // {start: 4, end: 4, interval: 10},
        // {start: 5, end: 7, interval: 5},
        // {start: 8, end: 10, interval: 1}
        { start: 2, end: 2, interval: 30 },
        { start: 2, end: 3, interval: 20 },
        { start: 4, end: 4, interval: 10 },
        { start: 5, end: 5, interval: 5 },
        { start: 6, end: 7, interval: 2 },
        { start: 8, end: 10, interval: 1 },
      ],
    }).addTo(map)
    //setX(d)

    //const f=x
    //console.log("MyLatlngGraticule.useEffect unmount start xxxx "+JSON.stringify(f))
    return () => {
      //console.log("MyLatlngGraticule.useEffect unmount"+d)
      //componentWillUnmount.current = true
      //console.log("MyLatlngGraticule.useEffect unmount start "+JSON.stringify(d))
      //if (!map && !x && !x.current) { map.clearLayers(x.current) }
      d.removeFrom(map)
    }
  }, [map])

  React.useEffect(() => {
    if (!map) return
  }, [map])
  return null
}
