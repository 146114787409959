import * as React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Helper from "../../utils/Helper"
import { Chip } from "@mui/material"

export default function VesselAutocomplete({
  tritonRealWaypointsGeojson,
  vesselPlanRoutes,
  setCenterLatLonMap,
  markedVesselNames,
  setMarkedVesselNames,
  setSwitchReported,
  setSwitchPlan,
  setSwitchSuggest,
  setSwitchForecast,
  setSwitchReanalyse,
}) {
  const [realRoutesEndpoints, setRealRoutesEndpoints] = React.useState([])

  React.useEffect(() => {
    if (tritonRealWaypointsGeojson) {
      const realRoutes = tritonRealWaypointsGeojson.tritonWaypointsGeojson.nodes.filter(
        (item) => item.routeType === "real"
      )
      const endpoints = realRoutes.map((item) => ({
        vesselName: item.vesselName,
        routeType: item.routeType,
        lonlat: item.geojson.features[item.geojson.features.length - 1].geometry.coordinates,
      }))
      setRealRoutesEndpoints(endpoints)
    }
  }, [tritonRealWaypointsGeojson])

  React.useEffect(() => {
    if (!Helper.planRouteExists(markedVesselNames, vesselPlanRoutes)) {
      setSwitchPlan(false)
    }
  }, [markedVesselNames, vesselPlanRoutes, setSwitchPlan])

  const options = React.useMemo(() => {
    return realRoutesEndpoints.sort((a, b) => -b.vesselName.localeCompare(a.vesselName))
  }, [realRoutesEndpoints])

  const handleChange = (event, newValue) => {
    if (newValue.length === 0) {
      setSwitchReported(false)
      setSwitchPlan(false)
      setSwitchSuggest(false)
      setSwitchForecast(false)
      setSwitchReanalyse(false)
    }
    if (newValue.length > 0) {
      setCenterLatLonMap([newValue[newValue.length - 1].lonlat[1], newValue[newValue.length - 1].lonlat[0]])
    }
    setMarkedVesselNames(newValue)
    const bottomControls = document.querySelectorAll(".leaflet-bottom")
    bottomControls.forEach((control) => {
      if (newValue.length === 0) {
        control.style.bottom = "0px" // Reset when the switch is turned off
      }
    })
  }

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        id="vessel-autocomplete"
        value={markedVesselNames}
        onChange={handleChange}
        options={options}
        isOptionEqualToValue={(option, value) => option.vesselName === value.vesselName}
        getOptionLabel={(option) => option.vesselName}
        filterSelectedOptions
        sx={{ width: "241.719px" }} // from DatePicker
        componentsProps={{
          paper: {
            sx: {
              width: "100%",
            },
          },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index })
            return <Chip key={option.vesselName} label={option.vesselName} {...tagProps} />
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Vessel Selection"
            placeholder="Vessels"
            inputProps={{
              ...params.inputProps,
              style: {
                display: "block", // Ensure the input text is displayed as a block element
                width: "100%", // Ensure the input field takes the full width
              },
            }}
          />
        )}
      />
    </React.Fragment>
  )
}
