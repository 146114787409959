import * as React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Paper,
} from "@mui/material"

export default function ToggleButtonsWMSLayers({ geoServerCapabilities, checkedWMSLayers, setCheckedWMSLayers }) {
  // console.log(geoServerCapabilities)

  const handleToggle = (model, parameter, layers, part) => {
    // Units, projection, and intervall
    const units = {
      T: "DegC",
      FF: "KN",
      PPP: "hPa",
      VV: "KM",
    }
    const projection = 3857
    const intervall = false

    const filterStyles = (styles) => {
      if (!Array.isArray(styles)) return []

      const unitValues = Object.values(units)

      // Check if any styles include units
      const hasUnits = styles.some((style) => unitValues.some((unit) => style.includes(unit)))

      let filteredStyles = styles
      if (hasUnits) {
        // Filter styles based on units
        filteredStyles = styles.filter((style) => unitValues.some((unit) => style.includes(unit)))
      }

      // Further filter based on projection, but keep original if none include projection
      const projectionFilteredStyles = filteredStyles.filter((style) => style.includes(projection))
      filteredStyles = projectionFilteredStyles.length > 0 ? projectionFilteredStyles : filteredStyles

      // If the part is _RColor and there are multiple styles, apply interval filtering
      if (part === "_RColor" && filteredStyles.length > 1) {
        filteredStyles = filteredStyles.filter((style) =>
          intervall ? style.includes("Inter") : !style.includes("Inter")
        )
      }

      // Ensure only one style per unit if units are present
      if (hasUnits) {
        const selectedStyles = []
        unitValues.forEach((unit) => {
          const unitStyle = filteredStyles.find((style) => style.includes(unit))
          if (unitStyle) {
            selectedStyles.push(unitStyle)
          }
        })

        // Include styles without units if no styles with units are selected
        if (selectedStyles.length === 0) {
          selectedStyles.push(...filteredStyles.filter((style) => !unitValues.some((unit) => style.includes(unit))))
        }

        return selectedStyles
      }

      return filteredStyles
    }

    let updatedChecked = { ...checkedWMSLayers }
    if (model === "hurricane_data") {
      const hurricaneStyle = part
      const currentlySelected = checkedWMSLayers[model]?.[parameter]?.[layers] || []
      const isCurrentlyChecked = currentlySelected.includes(hurricaneStyle)

      updatedChecked = {
        ...updatedChecked,
        [model]: {
          ...updatedChecked[model],
          [parameter]: {
            ...updatedChecked[model]?.[parameter],
            [layers]: isCurrentlyChecked
              ? currentlySelected.filter((style) => style !== hurricaneStyle)
              : [...currentlySelected, hurricaneStyle],
          },
        },
      }
    } else {
      // Get the related layer based on the part being toggled
      const relatedLayer = Object.keys(layers).find((key) => key.includes(part))
      let relatedStyles = relatedLayer ? filterStyles(layers[relatedLayer].Style) : []

      // Check if the related styles are currently selected
      const currentlySelected = checkedWMSLayers[model]?.[parameter]?.[relatedLayer] || []
      const isCurrentlyChecked = relatedStyles.every((style) => currentlySelected.includes(style))

      if (part === "_RColor" || part === "_PColor") {
        // Ensure only one _RColor or _PColor checkbox is checked at a time
        Object.keys(updatedChecked).forEach((modelKey) => {
          Object.keys(updatedChecked[modelKey] || {}).forEach((paramKey) => {
            Object.keys(updatedChecked[modelKey][paramKey] || {}).forEach((layerKey) => {
              const styles = Array.isArray(updatedChecked[modelKey][paramKey][layerKey])
                ? updatedChecked[modelKey][paramKey][layerKey]
                : []
              updatedChecked[modelKey][paramKey][layerKey] = styles.filter(
                (style) => !(style.includes("_RColor") || style.includes("_PColor"))
              )
              if (updatedChecked[modelKey][paramKey][layerKey].length === 0) {
                delete updatedChecked[modelKey][paramKey][layerKey]
              }
            })
            if (Object.keys(updatedChecked[modelKey][paramKey]).length === 0) {
              delete updatedChecked[modelKey][paramKey]
            }
          })
        })
      }

      updatedChecked = {
        ...updatedChecked,
        [model]: {
          ...updatedChecked[model],
          [parameter]: {
            ...updatedChecked[model]?.[parameter],
            [relatedLayer]: isCurrentlyChecked
              ? currentlySelected.filter((style) => !relatedStyles.includes(style))
              : [...new Set([...currentlySelected, ...relatedStyles])],
          },
        },
      }

      if (part === "_Label") {
        // Handle additional non-standard layers
        const nonStandardLayers = Object.keys(layers).filter(
          (key) =>
            !(key.includes("_PColor") || key.includes("_RColor") || key.includes("_Contour") || key.includes("_Label"))
        )
        nonStandardLayers.forEach((layer) => {
          const nonStandardStyles = filterStyles(layers[layer].Style)
          const nonStandardCurrentlySelected = checkedWMSLayers[model]?.[parameter]?.[layer] || []
          const nonStandardIsChecked = nonStandardStyles.every((style) => nonStandardCurrentlySelected.includes(style))

          updatedChecked = {
            ...updatedChecked,
            [model]: {
              ...updatedChecked[model],
              [parameter]: {
                ...updatedChecked[model]?.[parameter],
                [layer]: nonStandardIsChecked
                  ? nonStandardCurrentlySelected.filter((style) => !nonStandardStyles.includes(style))
                  : [...new Set([...nonStandardCurrentlySelected, ...nonStandardStyles])],
              },
            },
          }

          if (updatedChecked[model][parameter][layer].length === 0) {
            delete updatedChecked[model][parameter][layer]
          }
        })
      }

      if (updatedChecked[model][parameter][relatedLayer]?.length === 0) {
        delete updatedChecked[model][parameter][relatedLayer]
      }
      if (Object.keys(updatedChecked[model][parameter] || {}).length === 0) {
        delete updatedChecked[model][parameter]
      }
      if (Object.keys(updatedChecked[model] || {}).length === 0) {
        delete updatedChecked[model]
      }
    }

    setCheckedWMSLayers(updatedChecked)
  }

  // console.log("checkedWMSLayers", checkedWMSLayers)

  return (
    <TableContainer component={Paper}>
      <Table aria-label="parameter settings">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pr: 0, py: 0 }}>Parameter</TableCell>
            <TableCell align="center" sx={{ px: 0, py: 0 }}>
              Poly
            </TableCell>
            <TableCell align="center" sx={{ px: 0, py: 0 }}>
              Rast
            </TableCell>
            <TableCell align="center" sx={{ px: 0, py: 0 }}>
              Cont
            </TableCell>
            <TableCell align="center" sx={{ px: 0, py: 0 }}>
              Lab
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {geoServerCapabilities &&
            Object.entries(geoServerCapabilities).map(([model, parameters]) => (
              <React.Fragment key={model}>
                {/* Model Header Row, displayed only if model does not include 'hurricane' */}
                {!model.includes("hurricane") && (
                  <TableRow>
                    <TableCell colSpan={5} align="center" sx={{ padding: 0, backgroundColor: "#f5f5f5" }}>
                      <Typography variant="h6">{model}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {model === "hurricane_data"
                  ? Object.entries(parameters).map(([parameter, layers]) =>
                      Object.entries(layers).map(([layer, { Style }]) => (
                        <React.Fragment key={`${parameter}_${layer}`}>
                          {/* Header Row for each layer, unless it includes '_stormtrack' */}
                          {!layer.includes("_stormtrack") && (
                            <TableRow key={`${parameter}_${layer}_header`}>
                              <TableCell colSpan={5} align="center" sx={{ padding: 0, backgroundColor: "#f5f5f5" }}>
                                <Typography variant="h6">{layer.split("_").slice(-2).join(" ")}</Typography>
                              </TableCell>
                            </TableRow>
                          )}

                          {/* Iterate over styles */}
                          {Style.map((style) => {
                            let styleDisplayName = style.split("_").slice(-2, -1)

                            if (layer.includes("_stormtrack")) {
                              styleDisplayName = "track"
                            } else if (style.includes("text")) {
                              styleDisplayName = "info"
                            }

                            return (
                              <TableRow key={`${parameter}_${layer}_${style}`}>
                                <TableCell component="th" scope="row" sx={{ pr: 0, py: 0 }}>
                                  <Typography>{`${styleDisplayName}`}</Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ px: 0, py: 0 }}>
                                  <Checkbox
                                    checked={(checkedWMSLayers[model]?.[parameter]?.[layer] || []).includes(style)}
                                    onChange={() => handleToggle(model, parameter, layer, style)}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </React.Fragment>
                      ))
                    )
                  : Object.entries(parameters).map(([parameter, layers]) => (
                      <TableRow key={parameter}>
                        <TableCell component="th" scope="row" sx={{ pr: 0, py: 0 }}>
                          <Typography>{parameter}</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ px: 0, py: 0 }}>
                          <Checkbox
                            checked={
                              Object.keys(layers).includes(`${parameter}_PColor`) &&
                              (checkedWMSLayers[model]?.[parameter]?.[`${parameter}_PColor`] || []).length > 0
                            }
                            onChange={() => handleToggle(model, parameter, layers, "_PColor")}
                            disabled={!Object.keys(layers).includes(`${parameter}_PColor`)}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ px: 0, py: 0 }}>
                          <Checkbox
                            checked={
                              Object.keys(layers).includes(`${parameter}_RColor`) &&
                              (checkedWMSLayers[model]?.[parameter]?.[`${parameter}_RColor`] || []).length > 0
                            }
                            onChange={() => handleToggle(model, parameter, layers, "_RColor")}
                            disabled={!Object.keys(layers).includes(`${parameter}_RColor`)}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ px: 0, py: 0 }}>
                          <Checkbox
                            checked={
                              Object.keys(layers).includes(`${parameter}_Contour`) &&
                              (checkedWMSLayers[model]?.[parameter]?.[`${parameter}_Contour`] || []).length > 0
                            }
                            onChange={() => handleToggle(model, parameter, layers, "_Contour")}
                            disabled={!Object.keys(layers).includes(`${parameter}_Contour`)}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ px: 0, py: 0 }}>
                          <Checkbox
                            checked={
                              (Object.keys(layers).includes(`${parameter}_Label`) &&
                                (checkedWMSLayers[model]?.[parameter]?.[`${parameter}_Label`] || []).length > 0) ||
                              Object.keys(layers).some(
                                (layer) =>
                                  !(
                                    layer.includes("_PColor") ||
                                    layer.includes("_RColor") ||
                                    layer.includes("_Contour") ||
                                    layer.includes("_Label")
                                  ) && (checkedWMSLayers[model]?.[parameter]?.[layer] || []).length > 0
                              )
                            }
                            onChange={() => handleToggle(model, parameter, layers, "_Label")}
                            disabled={
                              !Object.keys(layers).includes(`${parameter}_Label`) &&
                              !Object.keys(layers).some(
                                (layer) =>
                                  !(
                                    layer.includes("_PColor") ||
                                    layer.includes("_RColor") ||
                                    layer.includes("_Contour") ||
                                    layer.includes("_Label")
                                  )
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
