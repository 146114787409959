import React, { useEffect, useRef } from "react"
import { useMap } from "react-leaflet"
import L from "leaflet"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import Helper from "../../utils/Helper"
import * as turf from "@turf/turf"

const arrow = [
  {
    offset: "100%",
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 10,
      polygon: false,
      pathOptions: { stroke: true, color: "black", pane: "arrowPane" },
    }),
  },
]

function PolylineDecorator({ patterns, line, waveHeight, waveDirection, waypoint }) {
  const map = useMap()
  const layersRef = useRef() // create a ref to store the layers

  const timestamp = dayjs.utc(waypoint.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
  const coordinates = Helper.ConvertDDToDM(waypoint.geometry.coordinates).join("&nbsp;&nbsp;&nbsp;")

  const popupContent = `<p>
  ${timestamp}<br/>
  ${coordinates}<br/><br/>
    <strong>Wave Height:</strong> ${Math.round(waveHeight * 10) / 10} m<br />
    <strong>Wave Direction:</strong> ${Math.round(waveDirection < 0 ? waveDirection + 360 : waveDirection - 360)}°
    </p>`

  useEffect(() => {
    // ensure map and line are not null before continuing
    if (!map || !line) return

    map.createPane("arrowPane")
    map.getPane("arrowPane").style.zIndex = 630

    // Convert coordinates for Leaflet polyline
    const coordinates = line.geometry.coordinates.map((coordPair) => [coordPair[1], coordPair[0]])

    // create a new polyline and polyline decorator and add them to the map
    const polyline = L.polyline(coordinates, { color: "black", pane: "arrowPane" }).addTo(map)
    const decorator = L.polylineDecorator(polyline, { patterns }).addTo(map)

    // Attach the popup to the polyline
    const popup = L.popup().setContent(popupContent)
    polyline.bindPopup(popup)

    // Hover functionality
    polyline.on("mouseover", function () {
      this.openPopup()
    })
    polyline.on("mouseout", function () {
      this.closePopup()
    })

    // store the polyline and decorator in the ref
    layersRef.current = { polyline, decorator }

    // Cleanup function to be run when component unmounts
    return () => {
      if (layersRef.current) {
        // remove the polyline and decorator from the map
        map.removeLayer(layersRef.current.polyline)
        map.removeLayer(layersRef.current.decorator)
      }
    }
  }, [map, line]) // add patterns to the dependencies array

  return null
}

export default function WaveDirection({ meteoGeojson, selectedParameter }) {
  const layers = []
  const map = useMap()

  for (const route of meteoGeojson.features) {
    if (route.length > 0 && selectedParameter === "NWW3:WHSSW") {
      for (let i = 0; i < route.length; i++) {
        const waypoint = route[i]
        if (
          waypoint.properties.W_DMEAN &&
          waypoint.properties.W_HSSW &&
          waypoint.properties.W_DMEAN !== "-" &&
          waypoint.properties.W_HSSW !== "-"
        ) {
          const waveDirection = waypoint.properties.W_DMEAN + 180
          // Define your reference zoom level at that zoom level
          const zoomLevel = map.getZoom()
          const referenceZoom = 9
          const referenceLength = 10
          // Calculate the factor by which the zoom level has changed from the reference
          const zoomFactor = Math.pow(1.6, referenceZoom - zoomLevel)
          const arrowLength = referenceLength * zoomFactor
          const arrowHeadPoint = turf.destination(waypoint.geometry.coordinates, arrowLength, waveDirection)
          const lineStringCoordinates = [waypoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]
          const lineFeature = turf.lineString(lineStringCoordinates)
          layers.push(
            <PolylineDecorator
              key={`${i}-${JSON.stringify(route)}`}
              patterns={arrow}
              line={lineFeature}
              waveHeight={waypoint.properties.W_HSSW}
              waveDirection={waveDirection}
              waypoint={waypoint}
            />
          )
        }
      }
    }
  }
  return <>{layers}</>
}

// import React from "react"
// import { GeoJSON } from "react-leaflet"

// import "leaflet-arrowheads"
// import * as turf from "@turf/turf"

// export default function WaveDirection({ meteoGeojson, selectedParameter }) {
//   //   console.log("waveDirection", meteoGeojson, selectedParameter)

//   const layers = []
//   // Initialize an array to store the LineStrings
//   const lineStrings = []

//   for (const route of meteoGeojson.features) {
//     if (route.length > 0 && selectedParameter === "W_HSSW") {
//       for (let i = 0; i < route.length; i++) {
//         const waypoint = route[i]
//         if (waypoint.properties.CurrentSpeed) {
//           // const lat = waypoint.geometry.coordinates[1]
//           // const lon = waypoint.geometry.coordinates[0]
//           const waveDirection = waypoint.properties.W_DMEAN + 180
//           const arrowLength = 10 // Set the offset distance as per your needs.
//           // arrow without offset
//           const arrowHeadPoint = turf.destination(waypoint.geometry.coordinates, arrowLength, waveDirection) // Calculate the second offset point
//           // Create a LineString with the offset point and the original waypoint
//           const lineStringCoordinates = [waypoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]
//           //  Add LineString feature to the array
//           lineStrings.push(lineStringCoordinates)
//           // console.log("single", waveDirection, arrowHeadPoint, lineStringCoordinates)
//         }
//       }
//       // Create a MultiLineString feature using the LineStrings
//       const multiLineStringFeature = turf.multiLineString(lineStrings)
//       console.log("wave direction", multiLineStringFeature)
//       layers.push(
//         <GeoJSON
//           key={`polyline-${selectedParameter}-${JSON.stringify(multiLineStringFeature)}`}
//           style={() => ({
//             color: "black",
//             fill: false,
//             width: 1.5,
//           })}
//           data={multiLineStringFeature}
//           arrowheads={{ color: "black", size: "10px", frequency: "endonly" }}
//         ></GeoJSON>
//       )
//     }
//   }
//   return <>{layers}</>
// }
