import React, { useState } from "react"
import L from "leaflet" // Ensure you have Leaflet imported to use L.icon
import { Marker, Popup, Polyline, useMapEvents } from "react-leaflet"
import * as turf from "@turf/turf"

const markerRed = L.icon({
  iconUrl: require("../../assets/images/marker/marker-icon-red.png"),
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12.5, 41], // point of the icon which will correspond to marker's location
})

const markerGreen = L.icon({
  iconUrl: require("../../assets/images/marker/marker-icon-green.png"),
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12.5, 41], // point of the icon which will correspond to marker's location
})

const markerBlue = L.icon({
  iconUrl: require("../../assets/images/marker/marker-icon-blue.png"),
  iconSize: [25, 41], // Size of the icon
  iconAnchor: [12.5, 41], // point of the icon which will correspond to marker's location
})

export default function VoyagePlanner({
  voyagePlannerPoints,
  setVoyagePlannerPoints,
  toogleButtonsOnOff,
  voyageActive,
  setVoyageActive,
}) {
  const isVoyagePlannerEnabled = toogleButtonsOnOff.includes("VoyagePlanner")

  const handleMapClick = useMapEvents({
    click: (e) => {
      if (voyageActive && isVoyagePlannerEnabled) {
        if (voyagePlannerPoints.features.length > 1) {
          const lastPoint = voyagePlannerPoints.features[voyagePlannerPoints.features.length - 1].geometry.coordinates
          const clickPoint = [e.latlng.lng, e.latlng.lat]
          const distance = turf.distance(turf.point(lastPoint), turf.point(clickPoint), { units: "meters" })
          if (distance < 10) {
            return
          }
        }
        const newPoint = turf.point([e.latlng.lng, e.latlng.lat], {
          legtype: "greatcircle",
          number: voyagePlannerPoints.features.length + 1,
          extended_number: 0,
        })
        const updatedPoints = [...voyagePlannerPoints.features, newPoint].map((point, index) => {
          return turf.point(point.geometry.coordinates, {
            ...point.properties,
            number: index + 1,
            extended_number: 0,
          })
        })
        setVoyagePlannerPoints(turf.featureCollection(updatedPoints))
      }
    },
  })

  const handleMarkerLeftClick = (index) => {
    if (index === voyagePlannerPoints.features.length - 1) {
      // Check if the last marker was clicked
      setVoyageActive(!voyageActive) // End the voyage creation by setting voyageActive to false
    }
  }

  const handleMarkerRightClick = (index) => {
    const filteredPoints = voyagePlannerPoints.features.filter((_, i) => i !== index)
    const updatedPoints = filteredPoints.map((point, idx) => {
      return turf.point(point.geometry.coordinates, {
        ...point.properties,
        number: idx + 1,
        extended_number: 0,
      })
    })
    setVoyagePlannerPoints(turf.featureCollection(updatedPoints))
  }

  const handleDrag = (index, newLatLng) => {
    if (isVoyagePlannerEnabled) {
      const updatedPoints = [...voyagePlannerPoints.features]
      updatedPoints[index].geometry.coordinates = [newLatLng.lng, newLatLng.lat]
      const reindexedPoints = updatedPoints.map((point, idx) => {
        return turf.point(point.geometry.coordinates, {
          ...point.properties,
          number: idx + 1,
          extended_number: 0,
        })
      })
      setVoyagePlannerPoints(turf.featureCollection(reindexedPoints))
    }
  }

  const handlePolylineClick = (event) => {
    if (!isVoyagePlannerEnabled) return
    const newPoint = turf.point([event.latlng.lng, event.latlng.lat], {
      legtype: "rhumbline",
    })
    const distances = voyagePlannerPoints.features.map((point) => {
      return Math.sqrt(
        Math.pow(point.geometry.coordinates[1] - newPoint.geometry.coordinates[1], 2) +
          Math.pow(point.geometry.coordinates[0] - newPoint.geometry.coordinates[0], 2)
      )
    })
    if (distances.length < 2) return
    let minIndex = 0
    let minDistance = Infinity
    for (let i = 1; i < distances.length; i++) {
      const distance = distances[i - 1] + distances[i]
      if (distance < minDistance) {
        minDistance = distance
        minIndex = i
      }
    }
    const updatedPoints = [...voyagePlannerPoints.features]
    updatedPoints.splice(minIndex, 0, newPoint)
    const reindexedPoints = updatedPoints.map((point, idx) => {
      return turf.point(point.geometry.coordinates, {
        ...point.properties,
        number: idx + 1,
        extended_number: 0,
      })
    })
    setVoyagePlannerPoints(turf.featureCollection(reindexedPoints))
  }

  const handleChangeLegtype = (index, style) => {
    const updatedPoints = [...voyagePlannerPoints.features]
    updatedPoints[index].properties.legtype = style
    const reindexedPoints = updatedPoints.map((point, idx) => {
      return turf.point(point.geometry.coordinates, {
        ...point.properties,
        number: idx + 1,
        extended_number: 0,
      })
    })
    setVoyagePlannerPoints(turf.featureCollection(reindexedPoints))
  }

  const getPolylinePoints = (points) => {
    let lineCoordinates = []
    for (let i = 0; i < points.features.length - 1; i++) {
      const currentPoint = points.features[i].geometry.coordinates
      const nextPoint = points.features[i + 1].geometry.coordinates
      if (points.features[i].properties.legtype === "greatcircle") {
        const { coordinates } = turf.greatCircle([currentPoint[0], currentPoint[1]], [nextPoint[0], nextPoint[1]], {
          npoints: 100,
        }).geometry
        lineCoordinates.push(...coordinates.map((coord) => ({ lng: coord[0], lat: coord[1] })))
      } else {
        lineCoordinates.push({ lng: currentPoint[0], lat: currentPoint[1] })
        lineCoordinates.push({ lng: nextPoint[0], lat: nextPoint[1] })
      }
    }
    return lineCoordinates
  }

  return !isVoyagePlannerEnabled ? null : (
    <>
      {voyagePlannerPoints.features.map((point, idx) => (
        <Marker
          key={point.properties.number}
          position={{ lat: point.geometry.coordinates[1], lng: point.geometry.coordinates[0] }}
          icon={idx === 0 ? markerGreen : idx === voyagePlannerPoints.features.length - 1 ? markerRed : markerBlue}
          draggable={true}
          eventHandlers={{
            click: () => handleMarkerLeftClick(idx),
            contextmenu: () => handleMarkerRightClick(idx),
            dragend: (e) => handleDrag(idx, e.target.getLatLng()),
          }}
        >
          <Popup>
            WP {point.properties.number} <br /> Latitude: {point.geometry.coordinates[1].toFixed(2)}, Longitude:{" "}
            {point.geometry.coordinates[0].toFixed(2)} <br />
            <select value={point.properties.legtype} onChange={(e) => handleChangeLegtype(idx, e.target.value)}>
              <option value="rhumbline">Rhumbline</option>
              <option value="greatcircle">Great Circle</option>
            </select>
          </Popup>
        </Marker>
      ))}
      {voyagePlannerPoints.features.length > 1 && (
        <>
          <Polyline positions={getPolylinePoints(voyagePlannerPoints)} color="blue" weight={4} pane="markerPane" />
          <Polyline
            pane="markerPane"
            positions={getPolylinePoints(voyagePlannerPoints)}
            color="transparent"
            weight={20}
            eventHandlers={{
              click: handlePolylineClick,
            }}
          />
        </>
      )}
    </>
  )
}
