import React, { useEffect, useRef } from "react"
import { GeoJSON, useMap } from "react-leaflet"
import L from "leaflet"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import Helper from "../../utils/Helper"
import * as turf from "@turf/turf"

const arrow = [
  {
    offset: "100%",
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 10,
      polygon: false,
      pathOptions: { stroke: true, color: "black", pane: "arrowPane" },
    }),
  },
]

// Function to calculate the bearing
function calculateBearing(startLon, startLat, speedX, speedY) {
  // Convert speed components to distance (assuming distance = speed * time)
  const time = 1 // Assuming 1 unit of time
  const distanceX = speedX * time
  const distanceY = speedY * time

  // Convert distance to coordinates (assuming 1 unit of distance = 1 degree of latitude/longitude)
  const endLon = startLon + distanceX
  const endLat = startLat + distanceY

  const startPoint = turf.point([startLon, startLat])
  const endPoint = turf.point([endLon, endLat])
  const bearing = turf.bearing(startPoint, endPoint)
  return bearing
}
//
function PolylineDecorator({ patterns, line, currentSpeed, currentDirection, waypoint }) {
  const map = useMap()
  const layersRef = useRef() // create a ref to store the layers

  const timestamp = dayjs.utc(waypoint.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
  const coordinates = Helper.ConvertDDToDM(waypoint.geometry.coordinates).join("&nbsp;&nbsp;&nbsp;")

  const popupContent = `<p>
  ${timestamp}<br/>
  ${coordinates}<br/><br/>
    <strong>Current Speed:</strong> ${
      Math.round(Math.sqrt(currentSpeed[0] ** 2 + currentSpeed[1] ** 2) * 10) / 10
    } kn<br />
    <strong>Current Direction:</strong> ${Math.round(currentDirection < 0 ? currentDirection + 360 : currentDirection)}°
    </p>`

  useEffect(() => {
    // ensure map and line are not null before continuing
    if (!map || !line) return

    map.createPane("arrowPane")
    map.getPane("arrowPane").style.zIndex = 630

    // Convert coordinates for Leaflet polyline
    const coordinates = line.geometry.coordinates.map((coordPair) => [coordPair[1], coordPair[0]])

    // create a new polyline and polyline decorator and add them to the map
    const polyline = L.polyline(coordinates, { color: "black", pane: "arrowPane" }).addTo(map)
    const decorator = L.polylineDecorator(polyline, { patterns }).addTo(map)

    // Attach the popup to the polyline
    const popup = L.popup().setContent(popupContent)
    polyline.bindPopup(popup)

    // Hover functionality
    polyline.on("mouseover", function () {
      this.openPopup()
    })
    polyline.on("mouseout", function () {
      this.closePopup()
    })

    // store the polyline and decorator in the ref
    layersRef.current = { polyline, decorator }

    // Cleanup function to be run when component unmounts
    return () => {
      if (layersRef.current) {
        // remove the polyline and decorator from the map
        map.removeLayer(layersRef.current.polyline)
        map.removeLayer(layersRef.current.decorator)
      }
    }
  }, [map, line]) // add patterns to the dependencies array

  return null
}
export default function CurrentVectors({ meteoGeojson, selectedParameter }) {
  const map = useMap()
  const layers = []
  // Initialize an array to store the LineStrings
  const lineStrings = []

  for (const route of meteoGeojson.features) {
    if (route.length > 0 && selectedParameter === "CurrentSpeed") {
      for (let i = 0; i < route.length; i++) {
        const waypoint = route[i]
        if (
          waypoint.properties.CurrentSpeed[0] !== "-" &&
          waypoint.properties.CurrentSpeed[1] !== "-" &&
          !isNaN(waypoint.properties.CurrentSpeed[0]) &&
          !isNaN(waypoint.properties.CurrentSpeed[1])
        ) {
          const lat = waypoint.geometry.coordinates[1]
          const lon = waypoint.geometry.coordinates[0]
          const currentSpeed = waypoint.properties.CurrentSpeed
          const nextWaypoint = i < route.length - 1 ? route[i + 1] : null
          const referenceWaypoint = i > 0 ? route[i - 1] : nextWaypoint

          if (!referenceWaypoint) continue

          const bearingShip = turf.bearing(referenceWaypoint, waypoint)
          let azimuthAngle = turf.bearingToAzimuth(bearingShip)

          if (referenceWaypoint === nextWaypoint) {
            azimuthAngle = (azimuthAngle + 180) % 360
          }

          // const perpendicularAzimuth = (azimuthAngle + 90) % 360
          const zoomLevel = map.getZoom()

          // Define your reference zoom level and offset distance at that zoom level
          const referenceZoom = 9
          const referenceLength = 10
          // Calculate the factor by which the zoom level has changed from the reference
          const zoomFactor = Math.pow(1.5, referenceZoom - zoomLevel)
          // const arrowOffsetDistance = referenceLength * zoomFactor
          // arrow with offset
          // const arrowTailPoint = turf.destination([lon, lat], arrowOffsetDistance, perpendicularAzimuth)
          // const tailPointLon = waypoint.geometry.coordinates[0]
          // const tailPointLat = waypoint.geometry.coordinates[1]
          const bearingCurrent = calculateBearing(
            waypoint.geometry.coordinates[0],
            waypoint.geometry.coordinates[1],
            currentSpeed[0],
            currentSpeed[1]
          )
          // const arrowHeadPoint = turf.destination(arrowTailPoint, arrowOffsetDistance-2, bearingCurrent) // Calculate the second offset point

          // Calculate the factor by which the zoom level has changed from the reference
          const arrowLength = referenceLength * zoomFactor
          const arrowHeadPoint = turf.destination(waypoint.geometry.coordinates, arrowLength, bearingCurrent) // Calculate the second offset point

          // Create a LineString with the offset point and the original waypoint
          const lineStringCoordinates = [waypoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]
          const lineFeature = turf.lineString(lineStringCoordinates)
          layers.push(
            <PolylineDecorator
              key={`${i}-${JSON.stringify(route)}`}
              patterns={arrow}
              line={lineFeature}
              currentSpeed={currentSpeed}
              currentDirection={bearingCurrent}
              waypoint={waypoint}
            />
          )

          // // arrow without offset
          // const bearingCurrent = calculateBearing(lon, lat, currentSpeed[0], currentSpeed[1])
          // const arrowHeadPoint = turf.destination(waypoint.geometry.coordinates, arrowOffsetDistance, bearingCurrent) // Calculate the second offset point
          // // Create a LineString with the offset point and the original waypoint
          // const lineStringCoordinates = [waypoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]
          //  Add LineString feature to the array
        }
      }
    }
  }
  return <>{layers}</>
}

// // import React from "react"
// // import { GeoJSON } from "react-leaflet"

// import "leaflet-arrowheads"
// // import * as turf from "@turf/turf"

// import React, { useEffect, useRef } from "react"
// import { GeoJSON, useMap } from "react-leaflet"
// // import "leaflet-polylinedecorator"
// import L from "leaflet"

// import * as turf from "@turf/turf"

// // Function to calculate the bearing
// function calculateBearing(startLon, startLat, speedX, speedY) {
//   // Convert speed components to distance (assuming distance = speed * time)
//   const time = 1 // Assuming 1 unit of time
//   const distanceX = speedX * time
//   const distanceY = speedY * time

//   // Convert distance to coordinates (assuming 1 unit of distance = 1 degree of latitude/longitude)
//   const endLon = startLon + distanceX
//   const endLat = startLat + distanceY

//   const startPoint = turf.point([startLon, startLat])
//   const endPoint = turf.point([endLon, endLat])
//   const bearing = turf.bearing(startPoint, endPoint)
//   // return bearing
//   return bearing
// }

// export default function CurrentVectors({ meteoGeojson, selectedParameter }) {
//   // console.log("CurrentVectors", meteoGeojson)
//   const layers = []
//   // Initialize an array to store the LineStrings
//   const lineStrings = []

//   for (const route of meteoGeojson.features) {
//     if (route.length > 0 && selectedParameter === "CurrentSpeed") {
//       for (let i = 0; i < route.length; i++) {
//         const waypoint = route[i]
//         if (
//           waypoint.properties.CurrentSpeed &&
//           waypoint.properties.CurrentSpeed[0] !== "-" &&
//           waypoint.properties.CurrentSpeed[1] !== "-" &&
//           !isNaN(waypoint.properties.CurrentSpeed[0]) &&
//           !isNaN(waypoint.properties.CurrentSpeed[1])
//         ) {
//           // console.log("wp", waypoint)
//           const lat = waypoint.geometry.coordinates[1]
//           const lon = waypoint.geometry.coordinates[0]
//           const currentSpeed = waypoint.properties.CurrentSpeed

//           const nextWaypoint = i < route.length - 1 ? route[i + 1] : null
//           const referenceWaypoint = i > 0 ? route[i - 1] : nextWaypoint

//           if (!referenceWaypoint) continue

//           const bearingShip = turf.bearing(referenceWaypoint, waypoint)
//           let azimuthAngle = turf.bearingToAzimuth(bearingShip)

//           if (referenceWaypoint === nextWaypoint) {
//             azimuthAngle = (azimuthAngle + 180) % 360
//           }

//           const perpendicularAzimuth = (azimuthAngle + 90) % 360
//           const arrowOffsetDistance = 10 // Set the offset distance as per your needs.

//           // arrow with offset
//           const arrowTailPoint = turf.destination([lon, lat], arrowOffsetDistance, perpendicularAzimuth)
//           const tailPointLon = arrowTailPoint.geometry.coordinates[0]
//           const tailPointLat = arrowTailPoint.geometry.coordinates[1]
//           const bearingCurrent = calculateBearing(tailPointLon, tailPointLat, currentSpeed[0], currentSpeed[1])
//           const arrowHeadPoint = turf.destination(arrowTailPoint, 8, bearingCurrent) // Calculate the second offset point
//           // Create a LineString with the offset point and the original waypoint
//           const lineStringCoordinates = [arrowTailPoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]

//           // // arrow without offset
//           // const bearingCurrent = calculateBearing(lon, lat, currentSpeed[0], currentSpeed[1])
//           // const arrowHeadPoint = turf.destination(waypoint.geometry.coordinates, arrowOffsetDistance, bearingCurrent) // Calculate the second offset point
//           // // Create a LineString with the offset point and the original waypoint
//           // const lineStringCoordinates = [waypoint.geometry.coordinates, arrowHeadPoint.geometry.coordinates]
//           //  Add LineString feature to the array
//           lineStrings.push(lineStringCoordinates)
//         }
//       }

//       // Create a MultiLineString feature using the LineStrings
//       const multiLineStringFeature = turf.multiLineString(lineStrings)

//       layers.push(
//         <GeoJSON
//           key={`polyline-${selectedParameter}-${JSON.stringify(multiLineStringFeature)}`}
//           style={() => ({
//             color: "black",
//             fill: false,
//             width: 1.5,
//           })}
//           data={multiLineStringFeature}
//           arrowheads={{ color: "black", size: "10px", frequency: "endonly" }}
//         ></GeoJSON>
//       )
//     }
//   }
//   return <>{layers}</>
// }
