const REPORT_ROUTES1=
[
    {
        "routeId": 222,
        "routeType": "real",
        "vesselName": "Ardmore Cherokee",       
        "geojson": {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "geometry": {"type": "Point","coordinates": [1.9907,40.8865]},
                    "properties": {
                        "id": 615381,
                        "name": "",
                        "active": true,
                        "timestamp": "2023-05-26T12:00:00+00:00",
                        "type": "",
                        "lonlat": [1.9907,40.8865],
                        "number": 10587,
                        "legtype": "rhumbline",
                        "heading": 221,
                        "sog": 0.41816
                    }
                },
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            1.99,
                            40.8856
                        ]
                    },
                    "properties": {
                        "id": 615382,
                        "name": "",
                        "active": true,
                        "timestamp": "2023-05-26T13:00:00+00:00",
                        "type": "",
                        "lonlat": [
                            1.99,
                            40.8856
                        ],
                        "number": 10588,
                        "legtype": "rhumbline",
                        "heading": 224.1,
                        "sog": 0.40141
                    }
                },
                {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [
                            1.98,
                            40.8856
                        ]
                    },
                    "properties": {
                        "id": 615382,
                        "name": "",
                        "active": true,
                        "timestamp": "2023-05-26T14:00:00+00:00",
                        "type": "",
                        "lonlat": [
                            1.98,
                            40.8856
                        ],
                        "number": 10588,
                        "legtype": "rhumbline",
                        "heading": 224.1,
                        "sog": 0.40141
                    }
                }
            ]
        }
    }
]

export default REPORT_ROUTES1;
//exports.REPORT_ROUTES1 = REPORT_ROUTES1;
