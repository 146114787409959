import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"

export default function ToggleButtonsHud({ toogleButtonsOnOff, setToogleButtonsOnOff }) {
  const handleChange = (event) => {
    const value = event.target.name
    if (event.target.checked) {
      // Add the checkbox's name to the array if it's checked
      setToogleButtonsOnOff((prevState) => [...prevState, value])
    } else {
      // Remove the checkbox's name from the array if it's unchecked
      setToogleButtonsOnOff((prevState) => prevState.filter((item) => item !== value))
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("Minimap")}
            onChange={handleChange}
            name="Minimap"
            aria-label="bold"
            color="default"
          />
        }
        label={<Typography>Minimap</Typography>}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("Graticule")}
            onChange={handleChange}
            name="Graticule"
            aria-label="underlined"
            color="default"
          />
        }
        label={<Typography>Graticule</Typography>}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("Terminator")}
            onChange={handleChange}
            name="Terminator"
            aria-label="underlined"
            color="default"
          />
        }
        label={<Typography>Daylight</Typography>}
      />

      {/* <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("PolylineMeasure")}
            onChange={handleChange}
            name="PolylineMeasure"
            aria-label="underlined"
            color="default"
          />
        }
        label={<Typography>Voyage Planner</Typography>}
      /> */}
    </div>
  )
}
// import * as React from "react"
// import FormatBoldIcon from "@mui/icons-material/FormatBold"
// import FormatItalicIcon from "@mui/icons-material/FormatItalic"
// import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
// import ToggleButton from "@mui/material/ToggleButton"
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
// import { Typography } from "@mui/material"

// export default function ToggleButtonsHud({ toogleButtonsOnOff, handleToogleButtonsOnOff }) {
//   return (
//     <ToggleButtonGroup
//       value={toogleButtonsOnOff}
//       onChange={handleToogleButtonsOnOff}
//       aria-label="text formatting"
//       orientation="vertical"
//       fullWidth
//     >
//       <ToggleButton value="Minimap" aria-label="bold">
//         {/*<FormatBoldIcon />*/}
//         <Typography>Minimap</Typography>
//       </ToggleButton>
//       {/* <ToggleButton value="NautiScale" aria-label="italic">
//         <FormatItalicIcon />
//         <Typography>NautiScale</Typography>
//       </ToggleButton> */}
//       {/* <ToggleButton value="ZoomControl" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Zoom Control</Typography>
//       </ToggleButton> */}
//       {/* <ToggleButton value="LatLon" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Coordinates</Typography>
//       </ToggleButton> */}
//       {/* <ToggleButton value="Attribution" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Attribution</Typography>
//       </ToggleButton> */}
//       <ToggleButton value="Graticule" aria-label="underlined">
//         {/*<FormatUnderlinedIcon />*/}
//         <Typography>Graticule</Typography>
//       </ToggleButton>
//       <ToggleButton value="Terminator" aria-label="underlined">
//         {/*<FormatUnderlinedIcon />*/}
//         <Typography>Day Night</Typography>
//       </ToggleButton>
//       <ToggleButton value="PolylineMeasure" aria-label="underlined">
//         {/*<FormatUnderlinedIcon />*/}
//         <Typography>Divider</Typography>
//       </ToggleButton>
//       {/* <ToggleButton value="Pixi" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Pixi</Typography>
//       </ToggleButton>
//       <ToggleButton value="LeafletVelocity" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Wind Flow</Typography>
//       </ToggleButton>
//       <ToggleButton value="color" aria-label="color" disabled>
//         <FormatColorFillIcon />
//         <ArrowDropDownIcon />ToggleButton with checkbo
//       </ToggleButton> */}
//     </ToggleButtonGroup>
//   )
// }
