const wwmOrange = "#f69e00"
const wwlOrange = "#fed389"
const wwBlue = "#004f7d"
const wwmBlue = "#1d8ed4"
const wwlBlue = "#157dc7"
const white = "#ffffff"

const warn1 = "#f0ee61" // "#f3d032"
const warn2 = "#ff0000"

const theme = 
{
    palette: {
      primary: {
        dark: wwBlue,
        main: wwmBlue,
        light: wwlBlue,
      },
      secondary: {
        main: wwmOrange,
        light: wwlOrange,
        contrastText: white,
      },
      text: {
        primary: wwBlue,
        light: white
      },
      warning: {
        main: warn2,
        light: warn1,
        contrastTextMain: white,
        contrastTextLight: wwBlue
      }
    },
    typography: {
        fontSizeSmall: 12
    },
    mixins: {
        drawer: {
            width: 300
        },
        dashboardCardMedia: {
            marginTop: 2,
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            height: 160,
            width: "auto"
        },
    }
}

export default theme