import { useState, useEffect } from "react"

export const useFilterMarkedRoutes = (analysedRoutes, markedVesselNames) => {
  // console.log(analysedRoutes)
  const [vesselRoutes, setVesselRoutes] = useState([])

  useEffect(() => {
    // console.log(`useFilterMarkedRoutes hook effect`)

    if (analysedRoutes) {
      const filteredRoutes = analysedRoutes.filter((route) => {
        // console.log(route)
        return (
          markedVesselNames.length === 0 ||
          markedVesselNames.map((obj) => obj.vesselName).includes(route.points?.vesselName) ||
          markedVesselNames.map((obj) => obj.vesselName).includes(route.vesselName)
        )
      })
      setVesselRoutes(filteredRoutes)
    }
  }, [analysedRoutes, markedVesselNames])

  return vesselRoutes
}
