//@ts-check
// VS Code now reports Typed Javascript types and type errors
// https://dev.to/sumansarkar/how-to-use-jsdoc-annotations-with-vscode-for-intellisense-7co
// https://code.visualstudio.com/docs/languages/javascript#_jsdoc-support

import JSON5 from "json5" // u.a. keine hochkommata um regulaere json-keys, gut fuer PostGraphile-String-Erzeugung

// Beispiel: Holen von Kundendaten (alle Kunden oder mit einer Kunden-Id):
// const query="
// {
//   customers (condition: {id: <id>}) {
//     nodes {id,type,firstName,lastName,organisationName,username,pin,commentary,sapId,webshopCustomerNumber,rdsId,protocolText, }
//   }
// }
// ";
// (-> testen in GraphiQL, s.u.)
// const url=httpRequestUrl(Test/Prod)ServicecenterdbApp; // s.u.
// const responseJSON=PostGraphileApi.fetchRequest(url,PostGraphileApi.httpRequestData(query,accessToken),"customer test");

const PostGraphileApi = {
  // fetch ist eingebaute Browser-Funktion (ersetzbar durch Axios), wird verwendet fuer PostGraphile Calls:
  /**
   * fetchRequest function
   * @param {RequestInfo | URL} fetchUrl
   * @param {RequestInit | undefined} request
   * @param {string} errorText
   * @return {Promise<Response>}
   */
  fetchRequest: (fetchUrl, request, errorText) => {
    return fetch(fetchUrl, request)
      .then((response) => {
        if (response.ok) {
          return response
        } // Shorthand to check for an HTTP 2xx response status; see https://fetch.spec.whatwg.org/#dom-response-ok
        throw Error(errorText + response.statusText) // Raise an exception to reject the promise and trigger the outer .catch() handler; by default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
      })
      .then((res) => {
        return res.json()
      })
  },

  /**
   * Create PostGraphile Request Header
   * @param {string} graphqlQuery - Mit Kommentar 1
   * @param {string | undefined} accessToken - Mit Kommentar 2
   * @return {object} das_ergebnis - Mit Kommentar 3
   */
  httpRequestData: (graphqlQuery, accessToken = undefined) => {
    let headers = {
      Accept: "application/json", // response data format
      "Content-Type": "application/json", // request body data format
      "Accept-Encoding": "gzip, deflate", // kann fehlen, der browser erkennt's trotzdem
    }
    if (accessToken !== undefined) {
      headers["Authorization"] = "Bearer " + accessToken
    }
    return {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ query: graphqlQuery }),
    }
  },

  // vorhandene PostGraphile-Server auf Datenbanken-Schemata
  // <-> OBSOLET, siehe stattdessen http://wiki.local/index.php/PostGraphileServer
  //httpRequestUrlRalfdbApp:              "http://localhost:5000/graphql",                        // fuer schema app in db ralf         -> graphiql (OK): http://localhost:5000/graphiql
  //httpRequestUrlTestServicecenterdbApp: "http://grib-gis-01.local/service-center/graphql",      // fuer schema app in test-servicecenterdb -> graphiql (OK): http://grib-gis-01.local:5000/graphiql
  //httpRequestUrlProdServicecenterdbApp: "http://grib-gis-01.local/prod-service-center/graphql", // fuer schema app in prod-servicecenterdb -> graphiql (OK): http://grib-gis-01.local:5500/graphiql
  //httpRequestUrlGeodbImported:          "http://grib-gis-01.local/geodb/graphql",   // fuer schema imported in geodb      -> graphiql (OK): http://grib-gis-01:5001/graphiql
  //httpRequestUrlMeteodbPdswork:         "http://grib-gis-01.local/meteodb-pds/graphql",  // fuer schema pds_work in meteodb    -> graphiql (OK): http://grib-gis-01.local:5006/graphiql
  //httpRequestUrlMeteodbXXX:       "http://grib-gis-01.local/meteodb/graphql",     // fuer schema wod?? in meteodb       -> graphiql (OK): http://grib-gis-01.local:5005/graphiql
  //httpRequestUrlSynopdbPublic:    "http://grib-gis-01.local/synop/graphql",       // fuer schema public in synopdb      -> graphiql (OK): http://grib-gis-01.local:5002/graphiql
  // USS: http://grib-gis-01.local/prod-uss/graphql und graphiql: http://grib-gis-01.local:5501/graphiql

  // ------------------------------------------------------------------------------------------------

  // damit baue ich meine (speziellen) PostGraphile/Graphql-String zusammen:
  nodesQuery: (graphqlFunction, graphqlDbFields, graphqlDbJoinFields = "", graphqlCondition = {}) => {
    // console.log("JSON.stringify(graphqlCondition)="+JSON5.stringify(graphqlCondition));
    return `{ ${graphqlFunction}(condition: ${JSON5.stringify(
      graphqlCondition
    )}) { nodes {${graphqlDbFields}${graphqlDbJoinFields} }}}`
  },

  // Beispiel: Bestandteile fuer kommplette PostGraphile-Query-Strings in z.B. CustomerTable.js:
  customerPlusInfos: {
    nodesQuery: {
      function: "customers", // entspricht DB-Table customer
      dbFields:
        "id,type,firstName,lastName,organisationName,username,pin,commentary,sapId,webshopCustomerNumber,rdsId,protocolText,", // alle DB-Felder der DB-Table customer
      dbJoinFields: "", // optionale Zusatzfelder zum Kunden, gelöst mit DB-Views (customer join ...), siehe DB-View customer_plus_info
      condition: {}, // einfacher Filter, s.o.
    },
  },

  stateKey: "data",
}

export default PostGraphileApi
