import React, { useState, useEffect, useRef } from "react"

import L from "leaflet"
import { Hotline } from "leaflet-hotline-react" //https://www.npmjs.com/package/leaflet-hotline-react
import { GeoJSON, LayerGroup, useMap } from "react-leaflet"

import { Box } from "@mui/material"

import Legend from "../meteo/legend"
import PostGraphileApi from "../../utils/PostGraphileApi"
import RouteHelper from "../../utils/routeHelper"

import JSON5 from "json5"

import * as turf from "@turf/turf"

// import CurrentVectors from "../meteo/currentVectors"
import CurrentVectors from "../meteo/newCurrentVectors"
// import WindBarbs from "../meteo/windBarbs"
import WindBarbs from "../meteo/newWindBarbs"
// import WaveDirection from "../meteo/waveDirection"
import WaveDirection from "../meteo/newWaveDirection"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

function findColor(feature, colorObject, selectedParameter) {
  // if selectedParameter has two components(speed) compute total speed
  if (feature.properties[selectedParameter].length > 1) {
    const speed = Math.sqrt(
      feature.properties[selectedParameter][0] ** 2 + feature.properties[selectedParameter][1] ** 2
    )
    for (let i = 0; i < colorObject.length - 1; i++) {
      if (speed >= colorObject[i].values[0] && speed < colorObject[i].values[1]) {
        return colorObject[i].color
      }
    }
  } else {
    // console.log(feature.properties[selectedParameter])
    for (let i = 0; i < colorObject.length - 1; i++) {
      if (
        feature.properties[selectedParameter] >= colorObject[i].values[0] &&
        feature.properties[selectedParameter] < colorObject[i].values[1]
      ) {
        // console.log(colorObject[i].color, selectedParameter)
        return colorObject[i].color
      }
    }
  }
}

function createHotlinePalette(jsonObj) {
  const hotlinePalette = {}
  const step = 1 / jsonObj.length
  // Loop through each object in the JSON array
  for (let i = 0; i < jsonObj.length; i++) {
    // Extract the values and color properties
    const obj = jsonObj[i]
    const values = obj.values
    const color = obj.color
    if (i * step < 1) {
      hotlinePalette[i * step + step / 2] = color
    } else if (i * step >= 1) {
      hotlinePalette[i * step] = color
    }
  }
  return hotlinePalette
  // }
}

function getCurrentPosition(route) {
  let point1 = null
  let point2 = null
  // if (dayjs.utc(route.geojson.features[0].properties.timestamp).isBefore(dayjs.utc())) {
  for (let i = 0; i < route.geojson.features.length; i++) {
    if (dayjs.utc(route.geojson.features[i].properties.timestamp).isSameOrAfter(dayjs.utc())) {
      point1 = turf.point(route.geojson.features[i - 1].geometry.coordinates)
      point2 = turf.point(route.geojson.features[i].geometry.coordinates)
      const distance = turf.distance(point1, point2, { units: "kilometers" })
      const timeDiffP2P1 =
        (dayjs.utc(route.geojson.features[i].properties.timestamp) -
          dayjs.utc(route.geojson.features[i - 1].properties.timestamp)) /
        1000
      const timeDiffInterp =
        Math.abs(dayjs.utc() - dayjs.utc(route.geojson.features[i - 1].properties.timestamp)) / 1000
      const speed = distance / timeDiffP2P1
      if (route.geojson.features[i - 1].properties.legtype === "rhumbline") {
        const latlonAlongRoute = RouteHelper.getRhumblinePoint(point1, speed, timeDiffInterp, point2)
        // console.log("currentPosition rhumbline", latlonAlongRoute)
        return latlonAlongRoute
      } else {
        const latlonAlongRoute = RouteHelper.getGreatcirclePoint(point1, point2, speed, timeDiffInterp)
        // console.log("currentPosition great circle", latlonAlongRoute)
        return latlonAlongRoute
      }
    }
  }
  // }
}

function MyHotline({ colorObject, selectedParameter, positions, map }) {
  const hotlineRef = useRef(null)

  useEffect(() => {
    if (colorObject && selectedParameter && positions) {
      const leafletHotlineOptions = {
        weight: 20,
        min: colorObject[0].values[0],
        max: colorObject[colorObject.length - 1].values[0],
        contourWidth: 0,
        palette: createHotlinePalette(colorObject),
      }

      // If there is an existing hotline, remove it
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }
      // Add the new hotline and store a reference to it
      hotlineRef.current = L.hotline(positions, leafletHotlineOptions)
      // Manually add the hotline to the desired pane
      hotlineRef.current.addTo(map)
    }

    // Clean up by removing the hotline when the component unmounts
    return () => {
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }
    }
  }, [colorObject, selectedParameter, positions, map]) // Depend on all the variables used inside useEffect

  return null
}

function extractLastFeatures({ reanalyseGeojson }) {
  let result = {
    type: reanalyseGeojson.type,
    features: [],
  }

  for (let i = 0; i < reanalyseGeojson.features.length; i++) {
    // get the last element from each array
    let lastFeature = reanalyseGeojson.features[i][reanalyseGeojson.features[i].length - 1]
    result.features.push([lastFeature])
  }

  return result
}

function processSelectedParameter({ waypoint, selectedParameter }) {
  if (waypoint.properties[selectedParameter].length > 1) {
    const { coordinates } = waypoint.geometry
    const param = Math.sqrt(
      waypoint.properties[selectedParameter][0] ** 2 + waypoint.properties[selectedParameter][1] ** 2
    )
    return [coordinates[1], coordinates[0], param]
    // positions.push([coordinates[1], coordinates[0], param])
  } else {
    const { coordinates } = waypoint.geometry
    const param = waypoint.properties[selectedParameter]
    return [coordinates[1], coordinates[0], param]
    // positions.push([coordinates[1], coordinates[0], param])
  }
}

function newProcessSelectedParameter({ waypoint, selectedParameter }) {
  if (waypoint.properties.reanalyse_weather) {
    if (selectedParameter === "CurrentSpeed") {
      const { coordinates } = waypoint.geometry
      const param = Math.sqrt(
        waypoint.properties.reanalyse_weather.UCUR ** 2 + waypoint.properties.reanalyse_weather.VCUR ** 2
      )
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "GFS:FFG") {
      const { coordinates } = waypoint.geometry
      const param = Math.sqrt(
        waypoint.properties.reanalyse_weather.U ** 2 + waypoint.properties.reanalyse_weather.V ** 2
      )
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "NWW3:WHSSW") {
      const { coordinates } = waypoint.geometry
      const param = waypoint.properties.reanalyse_weather["W_HSSW"]
      return [coordinates[1], coordinates[0], param]
    } else if (selectedParameter === "COP:SST") {
      const { coordinates } = waypoint.geometry
      const param = waypoint.properties.reanalyse_weather["SST"]
      return [coordinates[1], coordinates[0], param]
    }
  }
}

export default function RouteReanalyse({
  vesselRealRoutes,
  mapBounds,
  switchReanalyse,
  selectedParameter,
  colorObject,
  setFetchingReanalyse,
}) {
  const map = useMap()
  map.createPane("hotlinePane")
  map.getPane("hotlinePane").style.zIndex = 1015
  L.Hotline.renderer({ pane: "hotlinePane" })

  // console.log("Reanalyse Route", vesselRealRoutes)

  // const [reanalyseGeojson, setReanalyseGeojson] = useState(null)
  const layers = []

  // useEffect(() => {
  //   if (vesselRealRoutes.length !== 0) {
  //     setFetchingReanalyse(true)

  //     const filteredSimplifyRoutes = vesselRealRoutes.map((route) => {
  //       let routePoints = route.points
  //       return RouteHelper.markBoundingboxPoints(routePoints, mapBounds)
  //     })

  //     const promises = vesselRealRoutes.map((route) => {
  //       let waypoints = route.points.geojson.features
  //       let newWaypoints = waypoints.map((feature, index) => ({
  //         id: index + 1,
  //         number: index + 1,
  //         timestamp: dayjs.utc(feature.properties.timestamp).format(),
  //         lonlat: feature.geometry.coordinates,
  //       }))
  //       const graphqlQuery = `
  //       query MyQuery {
  //         wwPdsRoutingMainProgram(request: {
  //         route_id: ${route.routeId},
  //         forecast_params: {
  //           SST: ["°C","integer"],
  //           U: ["kn","numeric(5,2)"],
  //           V: ["kn","numeric(5,2)"],
  //           UCUR: ["kn","numeric(5,2)"],
  //           VCUR: ["kn","numeric(5,2)"],
  //           W_HSSW: ["m","numeric(5,2)"],
  //           W_DMEAN: ["°","numeric(5,2)"]
  //         },
  //         waypoints: ${JSON5.stringify(newWaypoints).replace(/'/g, '"')}
  //       })}`
  //       // console.log(graphqlQuery)
  //       return PostGraphileApi.fetchRequest(
  //         REANALYSE_URL,
  //         PostGraphileApi.httpRequestData(graphqlQuery),
  //         ".loadGraphql() fehler_1="
  //       )
  //     })

  //     const start = Date.now()

  //     Promise.all(promises)
  //       .then((responses) => {
  //         const end = Date.now()
  //         // console.log(`reanalyse fetch took ${(end - start)} ms`)
  //         let reanalyseResponse = {
  //           type: "FeatureCollection",
  //           features: Array(vesselRealRoutes.length).fill(null), // Pre-allocate space for each set of features
  //         }
  //         responses.forEach(({ data }, routeIndex) => {
  //           const features = data.wwPdsRoutingMainProgram.waypoints.map((waypoint) => ({
  //             type: "Feature",
  //             geometry: { type: "Point", coordinates: waypoint.lonlat },
  //             properties: {
  //               id: waypoint.id,
  //               number: waypoint.number,
  //               timestamp: waypoint.timestamp,
  //               SST: waypoint.SST,
  //               WindSpeed: [waypoint.U, waypoint.V],
  //               CurrentSpeed: [waypoint.UCUR, waypoint.VCUR],
  //               W_HSSW: waypoint.W_HSSW,
  //               W_DMEAN: waypoint.W_DMEAN,
  //             },
  //           }))
  //           reanalyseResponse.features[routeIndex] = features // Set the features at the correct index
  //         })
  //         setReanalyseGeojson(reanalyseResponse)
  //         setFetchingReanalyse(false)
  //       })
  //       .catch((error) => {
  //         console.log("Reanalyse.useEffect() error")
  //         setFetchingReanalyse(false)
  //       })
  //   }
  // }, [vesselRealRoutes])

  // if (vesselRealRoutes && reanalyseGeojson) {
  //   console.log(vesselRealRoutes)
  //   console.log(reanalyseGeojson)
  // }
  for (const route of vesselRealRoutes) {
    let allPositions = [[]]
    let processedSelectedCoordinates = null
    for (let i = 0; i < route.points.geojson.features.length; i++) {
      processedSelectedCoordinates = newProcessSelectedParameter({
        waypoint: route.points.geojson.features[i],
        selectedParameter,
      })
      if (
        route.points.geojson.features[i].properties.legtype === "rhumbline_antimeridian" &&
        route.points.geojson.features[i + 1] &&
        route.points.geojson.features[i + 1].properties.legtype === "rhumbline_antimeridian"
      ) {
        allPositions[allPositions.length - 1].push(processedSelectedCoordinates)
        allPositions.push([])
      } else {
        allPositions[allPositions.length - 1].push(processedSelectedCoordinates)
      }
    }

    const filteredPositions = []
    allPositions.forEach((linepart, lineIndex) => {
      filteredPositions.push([])
      linepart.forEach((entry) => {
        if (entry && entry[2] !== "-" && !isNaN(entry[2])) {
          filteredPositions[lineIndex].push(entry)
        }
      })
    })

    if (switchReanalyse && filteredPositions !== null) {
      filteredPositions.map((route) => {
        layers.push(
          <LayerGroup
            key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(route)}`}
          >
            return (
            <MyHotline
              key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(route)}`}
              colorObject={colorObject}
              selectedParameter={selectedParameter}
              positions={route}
              map={map}
            />
            )
            <WindBarbs key="windBarbs" vesselRoutes={vesselRealRoutes} selectedParameter={selectedParameter} />
            <CurrentVectors
              key="currentVectors"
              vesselRoutes={vesselRealRoutes}
              selectedParameter={selectedParameter}
            />
            <WaveDirection key="waveDirection" vesselRoutes={vesselRealRoutes} selectedParameter={selectedParameter} />
          </LayerGroup>
        )
      })
    }
  }

  // if (reanalyseGeojson) {
  //   // loop through all routes in reanalyseGeojson¸process vector data and check for MultiLineString (dateline crossing)
  //   // in case of Dateline Crossing construct subarrays to render multiple Hotlines
  //   for (const route of reanalyseGeojson.features) {
  //     let allPositions = []

  //     let processedSelectedCoordinates = null
  //     for (let i = 0; i < route.length; i++) {
  //       processedSelectedCoordinates = processSelectedParameter({ waypoint: route[i], selectedParameter })
  //       allPositions.push(processedSelectedCoordinates)
  //     }
  //     const filteredPositions = [[]]

  //     allPositions.forEach((entry) => {
  //       if (entry[2] !== "-" && !isNaN(entry[2]) && entry[2] !== null) {
  //         // Add to the last nested array within filteredPositions
  //         filteredPositions[filteredPositions.length - 1].push(entry)
  //       } else {
  //         // If the last nested array in filteredPositions has content, add a new nested array
  //         if (filteredPositions[filteredPositions.length - 1].length) {
  //           filteredPositions.push([])
  //         }
  //       }
  //     })

  //     // Ensure that there isn't an empty array at the end
  //     if (!filteredPositions[filteredPositions.length - 1].length) {
  //       filteredPositions.pop()
  //     }
  //     if (switchReanalyse && filteredPositions !== null) {
  //       console.log("filtered positions", filteredPositions)

  //       layers.push(
  //         <LayerGroup
  //           key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(
  //             filteredPositions
  //           )}`}
  //         >
  //           {filteredPositions.map((positions, index) => {
  //             return (
  //               <MyHotline
  //                 key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(
  //                   positions
  //                 )}`}
  //                 colorObject={colorObject}
  //                 selectedParameter={selectedParameter}
  //                 positions={positions}
  //               />
  //             )
  //           })}

  //           <WindBarbs key="windBarbs" meteoGeojson={reanalyseGeojson} selectedParameter={selectedParameter} />
  //           <CurrentVectors
  //             key="currentVectors"
  //             meteoGeojson={reanalyseGeojson}
  //             selectedParameter={selectedParameter}
  //           />
  //           <WaveDirection key="waveDirection" meteoGeojson={reanalyseGeojson} selectedParameter={selectedParameter} />
  //         </LayerGroup>
  //       )
  //     }
  //   }
  // }
  return <Box>{layers}</Box>
}
