const environments = {
  dev: {
    auth: { url: "https://uss-01.local:8080", path: "/auth" },
    data_api: { url: "http://grib-gis-01.local/test-uss/graphql", api: "https://uss-01.local:8080", timeout: 3000 },
    sc_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    meteo_api: { url: "http://grib-gis-01.local/test-meteodb-pds/graphql" },
    meteo_wod_settings_api: { url: "http://grib-gis-01.local/test-meteodb-wod-settings/graphql" },
    geo_api: { url: "http://grib-gis-01.local/geodb/graphql" },
  },
  test: {
    auth: { url: "https://uss-01.local:8080", path: "/auth" },
    data_api: { url: "https://uss-01.local:8080/graphql/uss", api: "https://uss-01.local:8080", timeout: 3000 },
    sc_api: { url: "https://uss-01.local:8080/graphql/sc" },
    meteo_api: { url: "https://uss-01.local:8080/graphql/meteo" },
    meteo_wod_settings_api: { url: "https://uss-01.local:8080/graphql/wodsettings" },
    geo_api: { url: "https://uss-01.local:8080/graphql/geo" },
  },
  prod_intern_without_graphqlroute: {
    auth: { url: "https://uss.local:8080", path: "/auth" },
    data_api: { url: "http://grib-gis-01.local/prod-uss/graphql", api: "https://uss.local:8080", timeout: 3000 },
    sc_api: { url: "http://grib-gis-01.local/prod-service-center/graphql" },
    meteo_api: { url: "http://grib-gis-01.local/meteodb-pds/graphql" },
    meteo_wod_settings_api: { url: "http://grib-gis-01.local/meteodb-wod-settings/graphql" },
    geo_api: { url: "http://grib-gis-01.local/geodb/graphql" },
  },
  prod_intern: {
    auth: { url: "https://uss.local:8080", path: "/auth" },
    data_api: { url: "https://uss.local:8080/graphql/uss", api: "https://uss.local:8080", timeout: 3000 },
    sc_api: { url: "https://uss.local:8080/graphql/sc" },
    meteo_api: { url: "https://uss.local:8080/graphql/meteo" },
    meteo_wod_settings_api: { url: "https://uss.local:8080/graphql/wodsettings" },
    geo_api: { url: "https://uss.local:8080/graphql/geo" },
  },
  prod: {
    auth: { url: "https://auth.wetterwelt.de", path: "/auth" },
    data_api: { url: "https://api.wetterwelt.de/graphql/uss", api: "https://api.wetterwelt.de", timeout: 3000 },
    sc_api: { url: "https://api.wetterwelt.de/graphql/sc" },
    meteo_api: { url: "https://api.wetterwelt.de/graphql/meteo" },
    meteo_wod_settings_api: { url: "https://api.wetterwelt.de/graphql/wodsettings" },
    geo_api: { url: "https://api.wetterwelt.de/graphql/geo" },
  },
  // TODO eventuell brauchen wir noch weitere individuelle Konfigurationen für den Zugriff auf die verschiedenen Systeme
  ralf_test_sc: {
    auth: { url: "https://uss.local:8080", path: "/auth" },
    data_api: { url: "http://grib-gis-01.local/prod-uss/graphql", api: "https://uss.local:8080", timeout: 3000 },
    sc_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    meteo_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    meteo_wod_settings_api: { url: "http://grib-gis-01.local/meteodb-wod-settings/graphql" },
    geo_api: { url: "http://grib-gis-01.local/geodb/graphql" },
  },
  // http://localhost:3000/ <kein login> (index.js: ...applications/local...)
  ralf_local: {
    auth: { url: "https://uss.local:8080", path: "/auth" },
    data_api: { url: "http://grib-gis-01.local/prod-uss/graphql", api: "https://uss.local:8080", timeout: 30000 },
    //sc_api:   { url: 'http://localhost:5000/graphql'},
    sc_api: { url: "http://grib-gis-01.local/service-center/graphql" },
    //meteo_api:{ url: 'http://localhost:5000/graphql'},
    meteo_api: { url: "http://grib-gis-01.local/meteodb-pds/graphql" },
    meteo_wod_settings_api: { url: "http://grib-gis-01.local/test-meteodb-wod-settings/graphql" },
    geo_api: { url: "http://grib-gis-01.local/geodb/graphql" },
  },
  // http://localhost:3000/ dv_dev <keepassxc-passwort> (index.js: ...applications/local...)
  ralf_testy: {
    auth: { url: "https://data.wetterwelt.de:9377/uss", path: "/auth" },
    data_api: {
      url: "https://data.wetterwelt.de:9377/uss/graphql/uss",
      api: "https://data.wetterwelt.de:9377/uss",
      timeout: 30000,
    },
    sc_api: { url: "https://data.wetterwelt.de:9377/uss/graphql/sc" },
    meteo_api: { url: "https://data.wetterwelt.de:9377/uss/graphql/meteo" },
    meteo_wod_settings_api: { url: "https://data.wetterwelt.de:9377/uss/graphql/wodsettings" },
    geo_api: { url: "https://data.wetterwelt.de:9377/uss/graphql/geo" },
  },
}

// gewünschtes environment exportieren:
export default environments.prod
//export default environments.ralf_local
