import React from "react"
import { GeoJSON, LayerGroup, useMap } from "react-leaflet"
import L from "leaflet"

import RouteHelper from "../../utils/routeHelper"

export default function RoutePoints({ vesselRoutes, mapBounds }) {
  const map = useMap()

  return vesselRoutes === null ? null : (
    <LayerGroup>
      {vesselRoutes.map((route) => {
        let routePoints = route.points
        // let filteredData = RouteHelper.markBoundingboxPoints(routePoints, mapBounds).geojson
        let filteredData = routePoints.geojson
        // console.log(`route length: ${routePoints.geojson.features.length}, simplified: ${filteredData.features.length}`);
        return routePoints.geojson.features === null || routePoints.geojson.features.length === null ? null : (
          <GeoJSON
            key={`RoutePoints-${routePoints.routeId}-${mapBounds}-${JSON.stringify(filteredData.features)}`}
            data={filteredData}
            style={{
              // fillColor: RouteHelper.findRouteColor(routePoints.routeType),
              // color: RouteHelper.findRouteColor(routePoints.routeType),
              // weight: 1,
              fillOpacity: 1,
            }}
            pointToLayer={(feature, latlon) => {
              // Determine the color based on the feature's properties
              let markerProps = {
                radius: 3,
                weight: 1,
                opacity: 1,
                fillOpacity: 1,
                fillColor: "blue",
                color: "blue",
                pane: "markerPane",
                zIndex: 1010,
              }

              if (
                route.points.routeType === "real" &&
                feature.properties.albis_reports !== undefined &&
                feature.properties.albis_reports !== null
              ) {
                markerProps.radius = 7
                markerProps.weight = 3
                markerProps.color = "white"
              } else if (route.points.routeType === "plan") {
                markerProps.fillColor = "green"
                markerProps.color = "green"
              } else if (route.points.routeType === "suggest") {
                markerProps.fillColor = "red"
                markerProps.color = "red"
              } else if (route.points.routeType === "Pathfinder") {
                markerProps.fillColor = "green"
                markerProps.color = "green"
              }
              return L.circleMarker(latlon, markerProps)
            }}
            onEachFeature={(feature, layer) => {
              RouteHelper.applyWpPopup({ routePoints, feature, layer, map })
            }}
          />
        )
      })}
    </LayerGroup>
  )
}
