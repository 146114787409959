import React, { useState, useRef } from "react"
import { useMap } from "react-leaflet"
import { Paper, IconButton, Button, Tabs, Tab, Box } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import CircleIcon from "@mui/icons-material/Circle"
import { green, blue } from "@mui/material/colors"
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid"
import Helper from "../../utils/Helper"
import * as turf from "@turf/turf"

function EditToolbar(props) {
  const { setVoyagePlannerPoints, setRowModesModel, voyagePoints, map } = props

  const handleClick = () => {
    // Check if the last row has all fields filled
    const lastRow = voyagePoints[voyagePoints.length - 1]?.properties
    const allFieldsFilled = lastRow && Object.values(lastRow).every((field) => field !== "" && field !== undefined)

    if (!lastRow || allFieldsFilled) {
      const lastId = voyagePoints.length > 0 ? voyagePoints[voyagePoints.length - 1].properties.number : 0
      const number = lastId + 1
      setVoyagePlannerPoints((oldRows) => [
        ...oldRows,
        {
          properties: {
            number,
            legtype: "greatcircle",
            isNew: true,
          },
          geometry: {
            type: "Point",
            coordinates: [map.getCenter().lng, map.getCenter().lat],
          },
        },
      ])
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [number]: { mode: GridRowModes.Edit, fieldToFocus: "lat" },
      }))
    } else {
      alert("Please fill all fields of the current row before adding a new record.")
    }
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add WP
      </Button>
    </GridToolbarContainer>
  )
}

function RouteTable({
  columns,
  rowModesModel,
  setRowModesModel,
  processRowUpdate,
  voyagePoints,
  setVoyagePoints,
  map,
}) {
  const rowsWithIds = voyagePoints.features.filter((point) => point.properties && point.properties.number)

  return (
    <DataGrid
      style={{ height: "80%", width: "100%" }}
      rows={rowsWithIds}
      columns={columns}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
      processRowUpdate={processRowUpdate}
      getRowId={(row) => `${row.properties.number}.${row.properties.extended_number}`}
      // slots={{
      //   toolbar: EditToolbar,
      // }}
      // slotProps={{
      //   toolbar: { setVoyagePoints, setRowModesModel, voyagePoints, map },
      // }}
    />
  )
}

export default function VoyageTable({
  appBarHeight,
  switchVoyagePlanner,
  setSwitchVoyagePlanner,
  voyagePlannerPoints,
  setVoyagePlannerPoints,
  vesselPlanRoutes,
  setVesselPlanRoutes,
  voyageActive,
  setVoyageActive,
  drawerWidth,
  openDrawer,
}) {
  // console.log("voyageTable", voyagePlannerPoints, vesselPlanRoutes)
  const map = useMap()
  const [rowModesModel, setRowModesModel] = useState({})
  const [paperWidth, setPaperWidth] = useState(500)
  const paperRef = useRef()
  const previousVoyageActiveRef = useRef(voyageActive) // Ref to store previous state
  const [selectedTab, setSelectedTab] = useState(0)

  const handleMouseDown = (e) => {
    const startX = e.clientX
    const startWidth = paperWidth
    const padding = 20 // Define the padding value
    const maxWidth = openDrawer ? window.innerWidth - drawerWidth - padding : window.innerWidth - 57 - padding

    const handleMouseMove = (e) => {
      const newWidth = startWidth + startX - e.clientX
      if (newWidth >= 275 && newWidth <= maxWidth) {
        setPaperWidth(newWidth)
      }
    }

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("mouseup", handleMouseUp)
    }

    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("mouseup", handleMouseUp)
  }

  const handleClosePaper = () => {
    setSwitchVoyagePlanner(false)
    if (!map) return
    map.dragging.enable()
    map.scrollWheelZoom.enable()
    map.touchZoom.enable()
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (number) => () => {
    setRowModesModel({ ...rowModesModel, [number]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (number) => () => {
    setRowModesModel({ ...rowModesModel, [number]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (number) => () => {
    const updatedRows = voyagePlannerPoints.features.filter(
      (row) => `${row.properties.number}.${row.properties.extended_number}` !== number
    )
    updatedRows.forEach((row, index) => (row.properties.number = index + 1)) // Reassign IDs sequentially
    setVoyagePlannerPoints(turf.featureCollection(updatedRows))
  }

  const handleCancelClick = (number) => () => {
    setRowModesModel({
      ...rowModesModel,
      [number]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = voyagePlannerPoints.features.find(
      (row) => `${row.properties.number}.${row.properties.extended_number}` === number
    )
    if (editedRow.properties.isNew) {
      setVoyagePlannerPoints(
        voyagePlannerPoints.features.filter(
          (row) => `${row.properties.number}.${row.properties.extended_number}` !== number
        )
      )
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, properties: { ...newRow.properties, isNew: false } }

    setVoyagePlannerPoints(
      turf.featureCollection(
        voyagePlannerPoints.features.map((row) =>
          row.properties.number === newRow.properties.number &&
          row.properties.extended_number === newRow.properties.extended_number
            ? updatedRow
            : row
        )
      )
    )
    return updatedRow
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const voyageColumns = [
    {
      field: "number",
      headerName: "WP",
      width: 100,
      valueGetter: (value, row) => `${row.properties.number}.${row.properties.extended_number || 0}`,
    },
    {
      field: "lat",
      headerName: "Latitude",
      editable: true,
      width: 130,
      valueGetter: (value, row) => row.geometry.coordinates[1].toFixed(2),
      valueSetter: (value, row) => {
        const updatedRow = {
          ...row,
          geometry: {
            ...row.geometry,
            coordinates: [row.geometry.coordinates[0], parseFloat(value)],
          },
        }
        return updatedRow
      },
    },
    {
      field: "lng",
      headerName: "Longitude",
      editable: true,
      width: 140,
      valueGetter: (value, row) => row.geometry.coordinates[0].toFixed(2),
      valueSetter: (value, row) => {
        const updatedRow = {
          ...row,
          geometry: {
            ...row.geometry,
            coordinates: [parseFloat(value), row.geometry.coordinates[1]],
          },
        }
        return updatedRow
      },
    },
    {
      field: "legtype",
      headerName: "Legtype",
      editable: true,
      width: 130,
      type: "singleSelect",
      valueOptions: ["greatcircle", "rhumbline"],
      valueGetter: (value, row) => row.properties.legtype,
      valueSetter: (value, row) => {
        const updatedRow = {
          ...row,
          properties: {
            ...row.properties,
            legtype: value,
          },
        }
        return updatedRow
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ]
      },
    },
  ]

  return !switchVoyagePlanner ? null : (
    <Paper
      ref={paperRef}
      style={{
        position: "absolute",
        top: "66px",
        right: "0%",
        height: `calc(100vh - ${appBarHeight}px)`,
        zIndex: 1000,
        padding: "10px",
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "0 0 0px rgba(0,0,0,0)",
        borderRadius: "0px",
        width: `${paperWidth}px`,
      }}
      onMouseEnter={() => {
        previousVoyageActiveRef.current = voyageActive // Save the current state
        Helper.onMouseEnter(map)
        setVoyageActive(false)
      }}
      onMouseLeave={() => {
        Helper.onMouseLeave(map)
        setVoyageActive(previousVoyageActiveRef.current) // Restore the previous state
      }}
    >
      <IconButton
        onClick={handleClosePaper}
        style={{ position: "absolute", position: "static" }}
        sx={{ pl: 1.5, m: 0.5, color: "#004f7d" }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          width: "5px",
          cursor: "ew-resize",
        }}
        onMouseDown={handleMouseDown}
      />
      <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        <Tab
          label={
            <Box display="flex" alignItems="center">
              <CircleIcon sx={{ color: blue[600], marginRight: 1, fontSize: 12 }} />
              {`Voyage Planner`}
            </Box>
          }
        />
        {vesselPlanRoutes.map((route, index) => (
          <Tab
            key={index}
            label={
              <Box display="flex" alignItems="center">
                <CircleIcon sx={{ color: green[600], marginRight: 1, fontSize: 12 }} />
                {`${route.points.vesselName} ${route.points.routeId}`}
              </Box>
            }
          />
        ))}
      </Tabs>
      {selectedTab == 0 ? (
        <RouteTable
          columns={voyageColumns}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          processRowUpdate={processRowUpdate}
          voyagePoints={voyagePlannerPoints}
          setVoyagePlannerPoints={setVoyagePlannerPoints}
          map={map}
        />
      ) : (
        vesselPlanRoutes.map(
          (route, index) =>
            selectedTab === index + 1 && (
              <RouteTable
                key={index}
                columns={voyageColumns}
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                processRowUpdate={processRowUpdate}
                voyagePoints={route.points.geojson}
                // setVoyagePoints={setVesselPlanRoutes}
                map={map}
              />
            )
        )
      )}
    </Paper>
  )
}
