import { useMapEvents } from "react-leaflet"
import { useState } from "react"

export default function MyMapEvent(props) {
  // const [zoomLevel, setZoomLevel] = useState(initialZoom) // initialZoom needs to be set in MainComponent to pass it to other components (TimeSlider,Route)
  const map = useMapEvents({
    // ACHTUNG: 3 gleichwertige arten die event-funktionen zu definieren (ist eigentlich auch klar):
    zoomend: function () {
      // console.log("MyMapEvent.zoomend="+JSON.stringify(map.getZoom()))
      //zoomLevel.setZoomLevel(map.getZoom())
      props.setZoomLevel(map.getZoom())
    },
    //    click() {
    //      console.log("MyMapEvent.click")
    //      //map.locate()
    //    },
    //    locationfound: (e) => {
    //      console.log("MyMapEvent.locationfound="+JSON.stringify(e.latlng))
    //      //setPosition(e.latlng)
    //map.flyTo(e.latlng, map.getZoom())
    //    },
    dragend: (e) => {
      // console.log("MyMapEvent.dragend=true")
      props.setDragged((prevDragged) => !prevDragged)
    },
    resize: (e) => {
      // console.log("MyMapEvent.resized")
      props.setResized((prevResized) => prevResized + 1)
    },
    moveend: () => {
      const bounds = map.getBounds()
      // console.log("MyMapEvent.moveend")
      const updatedBounds = [
        [bounds._southWest.lng, bounds._southWest.lat],
        [bounds._northEast.lng, bounds._southWest.lat],
        [bounds._northEast.lng, bounds._northEast.lat],
        [bounds._southWest.lng, bounds._northEast.lat],
        [bounds._southWest.lng, bounds._southWest.lat],
      ]
      props.setMapBounds(updatedBounds)
    },
  })

  /*
  layer.on({
    mouseover: (e) => {
      console.log(e)
      layer.openPopup(e.latlng)
    },
    mouseout: (e) => {
      console.log(e) layer.closePopup(e.latlng); 
    },
    click: (e) => {
      console.log(e)
    },
  })
  
  map.on('click', (e) => {
    info.textContent = e.latlng;
  })
  */

  return null
}
