import * as React from "react"
import { useState, useEffect } from "react"
import { useMap } from "react-leaflet"

const RecenterAutomaticallyMap = ({ centerLatLonMap }) => {
  const map = useMap()
  useEffect(() => {
    setTimeout(() => {
      //timeout schlechte lösung muss überarbeitet werden JK 16.6.23
      map.setView(centerLatLonMap, map.getZoom())
    }, 10)
  }, [centerLatLonMap])
  return null
}

export default RecenterAutomaticallyMap
