import React, { useState, useEffect, useMemo } from "react"

export default function WMSLegend({ legendUrl, base64Credentials, drawerWidth, openDrawer, layerName, styleName }) {
  // console.log(legendUrl, "\n", base64Credentials, "\n", drawerWidth, "\n", openDrawer, "\n", layerName, "\n", styleName)
  const [legendImage, setLegendImage] = useState(null)

  useEffect(() => {
    const fetchLegendImage = async () => {
      try {
        const response = await fetch(legendUrl, {
          headers: {
            Authorization: `Basic ${base64Credentials}`,
            "Content-Type": "image/png",
          },
        })
        const blob = await response.blob()
        const imageObjectURL = URL.createObjectURL(blob)
        // console.log("fetch legend", `\nlegend_${layerName}_${styleName}`)

        setLegendImage(imageObjectURL)
      } catch (error) {
        console.error("Failed to fetch legend image:", error)
      }
    }

    fetchLegendImage()
  }, [legendUrl])

  const memoizedLegend = useMemo(() => {
    if (!legendImage) return null
    return (
      <div
        style={{
          position: "absolute",
          top: 75,
          zIndex: 1050,
          background: "white",
          padding: "5px",
          borderRadius: "0.2rem",
          marginLeft: openDrawer ? `${drawerWidth + 10}px` : `67px`,
        }}
      >
        <p>{`${layerName}`}</p>
        <img src={legendImage} alt={`${layerName} - ${styleName} legend`} />
      </div>
    )
  }, [legendImage, drawerWidth, openDrawer, layerName, styleName])

  return memoizedLegend
}
