import { useMemo } from "react"
import { fromJS } from "immutable"
import dayjs from "dayjs"

import { RouteAnalyser } from "../modules/route_analyse/RouteAnalyser2"

function copyReanalyseWeather(analysedPoints) {
  // Extract timestamps from the data objects
  const beforeTimestamp = new Date(analysedPoints.geojson.features[0].properties.timestamp).getTime()
  const interpolatedTimestamp = new Date(analysedPoints.geojson.features[1].properties.timestamp).getTime()
  const afterTimestamp = new Date(analysedPoints.geojson.features[2].properties.timestamp).getTime()

  // Calculate the absolute differences
  const diffBefore = Math.abs(interpolatedTimestamp - beforeTimestamp)
  const diffAfter = Math.abs(interpolatedTimestamp - afterTimestamp)
  // Determine which data object's timestamp is closer and copy reanalyse_weather
  if (diffBefore < diffAfter) {
    analysedPoints.geojson.features[1].properties.reanalyse_weather =
      analysedPoints.geojson.features[0].properties.reanalyse_weather
  } else {
    analysedPoints.geojson.features[1].properties.reanalyse_weather =
      analysedPoints.geojson.features[2].properties.reanalyse_weather
  }
  return analysedPoints
}

export const useAnalyseRealRoutes = (fromPicker, toPicker, tritonRealWaypointsGeojson, toggleButtonsOnOff) => {
  return useMemo(() => {
    if (!toggleButtonsOnOff.includes("RealRoute") || tritonRealWaypointsGeojson === null) {
      return null
    } else {
      // console.log("useAnalyseRealRoutes hook Memo")
      const startTime = performance.now()
      const rawRoutes = tritonRealWaypointsGeojson.tritonWaypointsGeojson.nodes
      if (rawRoutes !== null) {
        const analysedRoutes = rawRoutes.flatMap((route) => {
          if (route.geojson.features.length > 1) {
            let analysedPoints = RouteAnalyser.analyseRoute(route)
            if (analysedPoints.points.geojson.features.length < 2) {
              return { points: analysedPoints.points, lines: {} }
            }

            analysedPoints = RouteAnalyser.setTimeIntervalTree(fromJS(analysedPoints.points))
            analysedPoints = RouteAnalyser.setRouteInterpolatePointByTime(
              analysedPoints,
              dayjs.utc(fromPicker).format(),
              "rhumbline"
            )
            analysedPoints = copyReanalyseWeather(analysedPoints.toJS())

            analysedPoints = RouteAnalyser.setTimeIntervalTree(fromJS(analysedPoints))
            analysedPoints = RouteAnalyser.setRouteInterpolatePointByTime(
              analysedPoints,
              dayjs.utc(toPicker).format(),
              "rhumbline"
            )
            // for (let i = 0; i < analysedPoints.toJS().geojson.features.length; i++) {
            //   let feature = analysedPoints.toJS().geojson.features[i]
            //   if (!feature.properties.reanalyse_weather) {
            //     console.log(analysedPoints.toJS().vesselName, feature)
            //   }
            // }
            analysedPoints = RouteAnalyser.markBetweenTimeRange(analysedPoints, dayjs(fromPicker), dayjs(toPicker))
            analysedPoints = RouteAnalyser.filterRoute(analysedPoints, (wp, i, a) => {
              // i index , a array mit a.get(i-1)
              // If interpolated point in beginning or end of route or antimeridian crossing
              if (
                wp.getIn(["properties", "legtype"]) === "time_interpolated_point" ||
                wp.getIn(["properties", "legtype"]) === "rhumbline_antimeridian" ||
                wp.getIn(["properties", "legtype"]) === "greatcircle_antimeridian"
              ) {
                return true
              }
              // If next WP exists and is_gap_time_by_timestamps and is_in_timerange
              if (
                i + 1 < a.size &&
                a.get(i + 1).getIn(["properties", "analyse", "is_gap_time_by_timestamps"]) &&
                wp.getIn(["properties", "analyse", "is_in_timerange"])
              ) {
                return true
              }
              // If current WP is_low_distance and not is_low_time_by_timestamps and (previous or next waypoint not is_low_distance)
              if (
                wp.getIn(["properties", "analyse", "is_low_distance"]) &&
                !wp.getIn(["properties", "analyse", "is_low_time_by_timestamps"])
                // &&
                // (
                //    (i > 0 && !a.get(i-1).getIn(["properties","analyse","is_low_distance"])) ||
                //    (i + 1 < a.size && !a.get(i+1).getIn(["properties","analyse","is_low_distance"]))
                // )
              ) {
                return true
              }
              // If current WP (is_simplify or is_gap_time_by_timestamps) and is_in_timerange
              if (
                (wp.getIn(["properties", "analyse", "is_simplify"]) ||
                  wp.getIn(["properties", "analyse", "is_gap_time_by_timestamps"]) ||
                  wp.getIn(["properties", "albis_reports"]) !== null) &&
                wp.getIn(["properties", "analyse", "is_in_timerange"])
              ) {
                return true
              }
              // return wp.getIn(["properties","analyse","is_simplify"]) || wp.getIn(["properties","analyse","is_gap_time_by_timestamps"])
            })
            // console.log(analysedPoints.toJS());
            let analysedLines = RouteAnalyser.linestringSegments(
              analysedPoints,
              RouteAnalyser.THRESHOLDS.seg_distance_in_km
            )
            const endTime = performance.now()
            console.log(
              `analyse route took ${endTime - startTime} ms`,
              analysedPoints.toJS().vesselName,
              analysedPoints.toJS().routeType
            )
            return { points: analysedPoints.toJS(), lines: analysedLines.toJS() }
          } else {
            // console.log(`${route.vesselName} has route length ${route.geojson.features.length}`,{points: route, lines: {}});
            return { points: route, lines: {} }
          }
        })
        return analysedRoutes
      } else {
        return null
      }
    }
  }, [tritonRealWaypointsGeojson])
}
