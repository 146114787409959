import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Typography from "@mui/material/Typography"

export default function ToggleButtonsGeoVectorLayers({ toogleButtonsOnOff, setToogleButtonsOnOff }) {
  const handleChange = (event) => {
    const value = event.target.name
    if (event.target.checked) {
      // Add the checkbox's name to the array if it's checked
      setToogleButtonsOnOff((prevState) => [...prevState, value])
    } else {
      // Remove the checkbox's name from the array if it's unchecked
      setToogleButtonsOnOff((prevState) => prevState.filter((item) => item !== value))
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("ECA")}
            onChange={handleChange}
            name="ECA"
            aria-label="bold"
            color="default"
          />
        }
        label={<Typography>ECA Zones</Typography>}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("HighRisk")}
            onChange={handleChange}
            name="HighRisk"
            aria-label="underlined"
            color="default"
          />
        }
        label={<Typography>HighRisk/Piracy Zones</Typography>}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={toogleButtonsOnOff.includes("Port")}
            onChange={handleChange}
            name="Port"
            aria-label="underlined"
            color="default"
          />
        }
        label={<Typography>Ports</Typography>}
      />
    </div>
  )
}
// import * as React from "react"
// import FormatBoldIcon from "@mui/icons-material/FormatBold"
// import FormatItalicIcon from "@mui/icons-material/FormatItalic"
// import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined"
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill"
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
// import ToggleButton from "@mui/material/ToggleButton"
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
// import { Typography } from "@mui/material"

// export default function ToggleButtonsGeoVectorLayers({ toogleButtonsOnOff, handleToogleButtonsOnOff }) {
//   return (
//     <ToggleButtonGroup
//       value={toogleButtonsOnOff}
//       onChange={handleToogleButtonsOnOff}
//       aria-label="text formatting"
//       orientation="vertical"
//       fullWidth
//     >
//       {/* <ToggleButton value="OpenSeaMap" aria-label="bold">
//         <FormatBoldIcon />
//         <Typography>OpenSeaMap</Typography>
//       </ToggleButton> */}

//       {/* RC 2022-12-16 eca */}
//       <ToggleButton value="ECA" aria-label="bold">
//         {/*<FormatBoldIcon />*/}
//         <Typography>ECAs</Typography>
//       </ToggleButton>

//       <ToggleButton value="HighRisk" aria-label="italic">
//         {/*<FormatItalicIcon />*/}
//         <Typography>High Risk/Piracy</Typography>
//       </ToggleButton>

//       <ToggleButton value="Port" aria-label="underlined">
//         {/*<FormatUnderlinedIcon />*/}
//         <Typography>Ports</Typography>
//       </ToggleButton>

//       {/* <ToggleButton value="Marina" aria-label="bold">
//         <Typography>Marinas</Typography>
//       </ToggleButton>

//       <ToggleButton value="ExclusiveEconomicZone" aria-label="bold">
//         <Typography>Excl. Economic Zone</Typography>
//       </ToggleButton>

//       <ToggleButton value="ExtendedContinentalShelf" aria-label="bold">
//         <Typography>Ext. Continental Shelf</Typography>
//       </ToggleButton>

//       <ToggleButton value="WDPA Point" aria-label="bold">
//         <Typography>Protected Points</Typography>
//       </ToggleButton> */}

//       {/* <ToggleButton value="WDPA Polygon" aria-label="bold">
//         <Typography>Protected Areas</Typography>
//       </ToggleButton>

//       <ToggleButton value="Loadline" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Loadlines</Typography>
//       </ToggleButton>

//       <ToggleButton value="City" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Cities</Typography>
//       </ToggleButton>

//       <ToggleButton value="Country" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Countries</Typography>
//       </ToggleButton>
//       <ToggleButton value="River" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Rivers</Typography>
//       </ToggleButton>
//       <ToggleButton value="Lake" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Lakes</Typography>
//       </ToggleButton>
//       <ToggleButton value="Canal" aria-label="underlined">
//         <FormatUnderlinedIcon />
//         <Typography>Canals</Typography>
//       </ToggleButton>
//       <ToggleButton value="DWD" aria-label="italic">
//         <FormatItalicIcon />
//         <Typography>DWD</Typography>
//       </ToggleButton> */}
//     </ToggleButtonGroup>
//   )
// }
