import * as React from "react"
//import FormatBoldIcon from '@mui/icons-material/FormatBold';
//import FormatItalicIcon from '@mui/icons-material/FormatItalic';
//import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
//import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { Typography } from "@mui/material"
import VesselAutocomplete from "../MyAutocomplete/VesselAutocomplete"
import { styled } from "@mui/material/styles"

// Custom ToggleButtonGroup with overridden styles
const CustomToggleButtonGroup = styled(ToggleButtonGroup)({
  "& .MuiToggleButtonGroup-groupedVertical:focus": {
    outline: "none",
  },
})

export default function ToggleButtonsVesselLayers({
  tritonRealWaypointsGeojson,
  vesselPlanRoutes,
  toogleButtonsOnOff,
  handleToogleButtonsOnOff,
  setCenterLatLonMap,
  markedVesselNames,
  setMarkedVesselNames,
  setSwitchReported,
  setSwitchPlan,
  setSwitchSuggest,
  setSwitchForecast,
  setSwitchReanalyse,
}) {
  return (
    <CustomToggleButtonGroup
      value={toogleButtonsOnOff}
      onChange={handleToogleButtonsOnOff}
      aria-label="text formatting"
      orientation="vertical"
      fullWidth
    >
      {/* <ToggleButton value="RealRoute" aria-label="bold">
        <Typography>Ardmore Fleet</Typography>
      </ToggleButton> */}

      <VesselAutocomplete
        tritonRealWaypointsGeojson={tritonRealWaypointsGeojson}
        vesselPlanRoutes={vesselPlanRoutes}
        setCenterLatLonMap={setCenterLatLonMap}
        markedVesselNames={markedVesselNames}
        setMarkedVesselNames={setMarkedVesselNames}
        setSwitchReported={setSwitchReported}
        setSwitchPlan={setSwitchPlan}
        setSwitchSuggest={setSwitchSuggest}
        setSwitchForecast={setSwitchForecast}
        setSwitchReanalyse={setSwitchReanalyse}
      ></VesselAutocomplete>
    </CustomToggleButtonGroup>
  )
}
