import * as React from "react"
import { useState, useEffect } from "react"

import { Paper, IconButton, Button } from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useFilterMarkedRoutes } from "../../hooks/useFilterMarkedRoutes"

import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryGroup,
  VictoryScatter,
  createContainer,
  VictoryCursorContainer,
  Line,
  VictoryLabel,
  VictoryTheme,
} from "victory"

import dayjs from "dayjs"
import { color } from "d3"

export default function VesselGraph({
  tritonRealWaypointsGeojson,
  vesselRealRoutes,
  vesselPlanRoutes,
  markedVesselNames,
  switchReported,
  showChart,
  openDrawer,
  timesliderWidth,
  sliderTicks,
}) {
  // console.log("vessel graph", vesselRealRoutes, vesselPlanRoutes)

  // Initializing the victoryData array
  let victoryDataSOG = []
  let victoryDataMEFCR = []
  let victoryDataAEFCR = []
  let victoryDataEtaSOG = []

  // New state to manage the visibility of parameters
  let [displayParameters, setDisplayParameters] = useState({
    SOG: true,
    EtaSOG: true,
    MEFCR: true,
    AEFCR: true,
  })

  // Function to toggle the visibility of a parameter
  let toggleParameter = (parameter) => {
    setDisplayParameters((prev) => ({
      ...prev,
      [parameter]: !prev[parameter],
    }))
  }

  if (vesselRealRoutes.length > 0) {
    // Assuming vesselRealRoutes[0].points.geojson.features is your array of features
    const features = vesselRealRoutes[0].points.geojson.features
    // Using a for loop to iterate through each feature
    for (let i = 0; i < features.length; i++) {
      const feature = features[i]

      const timestamp = dayjs(feature.properties.timestamp).format("YYYY-MM-DDTHH:mm:ss[Z]")
      // const speed_by_sog = feature.properties.analyse?.speed_by_sog
      const speed_by_sog = feature.properties.albis_sensors?.speedOverGround_kn
      const me_consumption = feature.properties.albis_sensors?.mainEngineFuelConsumptionRate_t_per_d
      const ae_consumption = feature.properties.albis_sensors?.auxEngineFuelConsumptionRate_t_per_d
      // Only adding data to victoryData if all values are truthy
      if (timestamp) {
        // if (speed_by_sog && speed_by_sog[0]) {
        if (speed_by_sog) {
          victoryDataSOG.push({
            x: dayjs.utc(timestamp).toDate(),
            // y: parseFloat(Math.round(speed_by_sog[0] * 10) / 10).toFixed(1),
            y: parseFloat(Math.round(speed_by_sog * 10) / 10).toFixed(1),
            parameter: "SOG",
          })
        }
        if (me_consumption) {
          victoryDataMEFCR.push({
            x: dayjs.utc(timestamp).toDate(),
            y: parseFloat(Math.round(me_consumption * 10) / 10).toFixed(1),
            parameter: "M/E FCR",
          })
        }
        if (ae_consumption) {
          victoryDataAEFCR.push({
            x: dayjs.utc(timestamp).toDate(),
            y: parseFloat(Math.round(ae_consumption * 10) / 10).toFixed(1),
            parameter: "A/E FCR",
          })
        }
      }
    }
  }

  if (vesselPlanRoutes.length > 0) {
    // Assuming vesselRealRoutes[0].points.geojson.features is your array of features
    const features = vesselPlanRoutes[0].points.geojson.features
    // Using a for loop to iterate through each feature
    for (let i = 0; i < features.length; i++) {
      const feature = features[i]
      // console.log(feature.properties.eta_delta_waypoints, feature)
      if (feature.properties.eta_delta_waypoints) {
        for (let ii = 0; ii < feature.properties.eta_delta_waypoints.length; ii++) {
          const waypoint = feature.properties.eta_delta_waypoints[ii]
          // console.log(waypoint)

          const timestamp = dayjs(waypoint.eta_utc).format("YYYY-MM-DDTHH:mm:ss[Z]")
          const speed_by_sog = waypoint.sog * 1.9438444924574
          // // Only adding data to victoryData if all values are truthy
          if (timestamp) {
            //   // if (speed_by_sog && speed_by_sog[0]) {
            if (speed_by_sog) {
              victoryDataEtaSOG.push({
                x: dayjs.utc(timestamp).toDate(),
                y: parseFloat(Math.round(speed_by_sog * 10) / 10).toFixed(1),
                parameter: "SOG",
              })
            }
          }
        }
      }
    }
  }

  const CustomContainer = createContainer("cursor", "voronoi")

  const maxSOG = Math.max(...victoryDataSOG.map((data) => data.y))
  const maxMEFCR = Math.max(...victoryDataMEFCR.map((data) => data.y))
  const maxAEFCR = Math.max(...victoryDataAEFCR.map((data) => data.y))
  const maxEtaSOG = Math.max(...victoryDataEtaSOG.map((data) => data.y))

  // console.log(victoryDataSOG, victoryDataEtaSOG, sliderTicks)
  return switchReported ? (
    <Paper style={{ boxShadow: "0 0 0px rgba(0,0,0,0)", position: "relative", height: "200" }}>
      {/* Buttons to toggle visibility */}
      {showChart && (
        <Paper style={{ width: timesliderWidth }}>
          <Paper
            style={{
              boxShadow: "0 0 0px rgba(0,0,0,0)",
              display: "flex",
              marginBottom: -40,
              position: "relative",
              zIndex: 1,
            }}
          >
            <Button onClick={() => toggleParameter("SOG")}>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              ></div>
              <span style={{ color: "#004f7d" }}>SOG</span>
            </Button>
            <Button onClick={() => toggleParameter("EtaSOG")}>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "magenta",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              ></div>
              <span style={{ color: "magenta" }}>EtaSOG</span>
            </Button>
            <Button onClick={() => toggleParameter("MEFCR")}>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "blue",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              ></div>
              <span style={{ color: "#004f7d" }}>M/E FCR</span>
            </Button>
            <Button onClick={() => toggleParameter("AEFCR")}>
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "green",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "5px",
                }}
              ></div>
              <span style={{ color: "#004f7d" }}>A/E FCR</span>
            </Button>
          </Paper>
          <VictoryChart
            minDomain={{ x: dayjs.utc(sliderTicks[0].label).toDate() }}
            maxDomain={{ x: dayjs.utc(sliderTicks[sliderTicks.length - 1].label).toDate() }}
            key={JSON.stringify(victoryDataSOG) + showChart + openDrawer + JSON.stringify(displayParameters)}
            width={timesliderWidth}
            height={200}
            scale={{ x: "time", y: "linear" }}
            containerComponent={
              <CustomContainer
                responsive={false}
                height={200}
                mouseFollowTooltips
                voronoiDimension="x"
                labels={({ datum }) => {
                  if (
                    datum.childName === "SOG_Scatter" ||
                    datum.childName === "MEFCR_Scatter" ||
                    datum.childName === "AEFCR_Scatter"
                  ) {
                    return null
                  }
                  return datum.parameter === "SOG"
                    ? `${dayjs.utc(datum.x).format("ddd D MMM YYYY HH:mm UTC")}\n${datum.parameter}: ${datum.y} kn`
                    : `${datum.parameter}: ${datum.y} t/d`
                }}
                labelComponent={
                  <VictoryTooltip
                    style={[
                      { fontSize: 15, fill: "#004f7d", textAnchor: "start" }, // text color
                    ]}
                    flyoutStyle={{
                      fill: "white", // background color of the tooltip
                      stroke: "#004f7d", // border color of the tooltip
                    }}
                    cornerRadius={5} // rounded corners
                    flyoutPadding={{ top: 50, bottom: 50, left: 0, right: 0 }}
                    flyoutWidth={210}
                    flyoutHeight={70}
                    centerOffset={{ x: 200, y: 35 }}
                  />
                }
                cursorComponent={<Line style={{ stroke: "grey", strokeDasharray: "4 4" }} />}
              />
            }
          >
            <VictoryAxis
              // tickValues={victoryDataSOG.map((data) => data.x)}
              tickValues={sliderTicks.map((tick) => dayjs.utc(tick.label).toDate())}
              tickFormat={(x, index) => {
                // console.log(x, index)
                // const date = new Date(x)
                // return `${date.getUTCDate()}-${date.getUTCMonth() + 1}` // Adding 1 because months are 0-indexed
                return dayjs.utc(x).format("HH:mm")
              }}
              style={{
                ticks: { stroke: "grey", size: 5 },
                tickLabels: { fontSize: 10, padding: 5 },
                grid: { stroke: "grey", strokeWidth: 0.5 },
              }}
              // tickCount={10}
              tickLabelComponent={<VictoryLabel textAnchor="middle" style={{ fill: "#004f7d" }} />}
            />
            {displayParameters.SOG && (
              <VictoryGroup color="red">
                <VictoryLine
                  name="SOG_Line"
                  data={victoryDataSOG}
                  // normalize data
                  y={(datum) => datum.y / maxSOG}
                />
                <VictoryScatter
                  name="SOG_Scatter"
                  labels={({ datum }) => {
                    return null
                  }}
                  data={victoryDataSOG}
                  // normalize data
                  y={(datum) => datum.y / maxSOG}
                  size={({ active }) => (active ? 6 : 0)}
                />
                <VictoryAxis
                  dependentAxis
                  style={{ axis: { stroke: "red" } }}
                  // Use normalized tickValues (0 - 1)
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  // Re-scale ticks by multiplying by correct maxima
                  tickFormat={(t) => `${Math.round(t * maxSOG)} kn`}
                  tickLabelComponent={<VictoryLabel style={{ fill: "red" }} />}
                />
              </VictoryGroup>
            )}

            {displayParameters.EtaSOG && (
              <VictoryGroup color="magenta">
                <VictoryLine
                  name="EtaSOG_Line"
                  data={victoryDataEtaSOG}
                  // normalize data
                  y={(datum) => datum.y / maxSOG}
                />
                <VictoryScatter
                  name="EtaSOG_Scatter"
                  labels={({ datum }) => {
                    return null
                  }}
                  data={victoryDataEtaSOG}
                  // normalize data
                  y={(datum) => datum.y / maxEtaSOG}
                  size={({ active }) => (active ? 6 : 0)}
                />
                <VictoryAxis
                  dependentAxis
                  style={{ axis: { stroke: "magenta" } }}
                  // Use normalized tickValues (0 - 1)
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  // Re-scale ticks by multiplying by correct maxima
                  tickFormat={(t) => `${Math.round(t * maxEtaSOG)} kn`}
                  tickLabelComponent={<VictoryLabel style={{ fill: "magenta" }} />}
                />
              </VictoryGroup>
            )}

            {displayParameters.MEFCR && (
              <VictoryGroup color="blue">
                <VictoryLine
                  name="MEFCR_Line"
                  data={victoryDataMEFCR}
                  // normalize data
                  y={(datum) => datum.y / maxMEFCR}
                />
                <VictoryScatter
                  name="MEFCR_Scatter"
                  labels={({ datum }) => {
                    return null
                  }}
                  data={victoryDataMEFCR}
                  // normalize data
                  y={(datum) => datum.y / maxMEFCR}
                  size={({ active }) => (active ? 6 : 0)}
                />
                <VictoryAxis
                  dependentAxis
                  offsetX={timesliderWidth - 50}
                  style={{ axis: { stroke: "blue" } }}
                  // Use normalized tickValues (0 - 1)
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  // Re-scale ticks by multiplying by correct maxima
                  tickFormat={(t) => `${Math.round(t * maxMEFCR)} t/d`}
                  tickLabelComponent={<VictoryLabel style={{ fill: "blue" }} />}
                />
              </VictoryGroup>
            )}

            {displayParameters.AEFCR && (
              <VictoryGroup color="green">
                <VictoryLine
                  name="AEFCR_Line"
                  data={victoryDataAEFCR}
                  // normalize data
                  y={(datum) => datum.y / maxAEFCR}
                />
                <VictoryScatter
                  name="AEFCR_Scatter"
                  labels={({ datum }) => {
                    return null
                  }}
                  data={victoryDataAEFCR}
                  // normalize data
                  y={(datum) => datum.y / maxAEFCR}
                  size={({ active }) => (active ? 6 : 0)}
                />
                <VictoryAxis
                  dependentAxis
                  offsetX={timesliderWidth - 50}
                  style={{ axis: { stroke: "green" }, ticks: { padding: 35 } }}
                  // Use normalized tickValues (0 - 1)
                  tickValues={[0, 0.25, 0.5, 0.75, 1]}
                  // Re-scale ticks by multiplying by correct maxima
                  tickFormat={(t) => `${Math.round(t * maxAEFCR)} t/d`}
                  tickLabelComponent={<VictoryLabel dx={20} style={{ fill: "green", textAnchor: "start" }} />}
                />
              </VictoryGroup>
            )}
          </VictoryChart>
        </Paper>
      )}
    </Paper>
  ) : null
}
