import React, { useEffect, useRef } from "react"
import { Marker, useMap, Popup } from "react-leaflet"
import L from "leaflet"
import { D3WindBarb, ConversionFactors } from "d3-wind-barbs"
import { bearing, bearingToAzimuth, destination } from "@turf/turf"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import Helper from "../../utils/Helper"

function WindBarbMarker({ lat, lon, speed, direction, waypoint }) {
  const markerRef = useRef(null) // create reference to the marker DOM element.This reference is used to manipulate the marker's icon after the marker has been rendered

  useEffect(() => {
    // creating a new wind barb SVG
    const windBarbSVG = new D3WindBarb(
      speed,
      direction, // All this configuration is optional. Change it or comment it to check its effects
      {
        bar: {
          angle: 0,
          fullBarClassName: "",
          padding: speed < 10 ? 0 : 6,
          shortBarClassName: "",
          stroke: "#000",
          width: 2,
        },
        circle: {
          fill: "#FFFFFF00",
          stroke: "#000",
          radius: 10,
          strokeWidth: 2,
          className: "wind-barb-zero-knots-circle",
        },
        conversionFactor: ConversionFactors.None,
        rootBarClassName: "",
        size: {
          width: 30,
          height: 15,
        },
        svgId: "",
        triangle: {
          fill: "#000",
          stroke: "#000",
          padding: 6,
          className: "wind-barb-triangle",
        },
      }
    ).draw()

    // create an icon using leaflet with the wind barb SVG's HTML content, and sets this as the icon of the marker.
    const icon = L.divIcon({
      className: "my-div-icon",
      html: windBarbSVG.outerHTML,
    })

    if (markerRef.current) {
      markerRef.current.setIcon(icon)
    }
  }, [speed, direction])

  const timestamp = dayjs.utc(waypoint.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
  const coordinates = Helper.ConvertDDToDM(waypoint.geometry.coordinates)
  const jsxCoordinates = coordinates.map((coord, index) => (
    <React.Fragment key={index}>
      {index !== 0 && <>&nbsp;&nbsp;&nbsp;</>}
      {coord}
    </React.Fragment>
  ))

  const popupContent = (
    <>
      <>{timestamp}</>
      <br />
      <>{jsxCoordinates}</>
      <br />
      <br />
      <strong>Wind Speed:</strong> {Math.round(speed * 10) / 10} kn
      <br />
      <strong>Wind Direction:</strong> {Math.round(direction)}°
    </>
  )

  // return a Marker from react-leaflet, placed at the provided lat and lon coordinates, with markerRef attached
  return (
    <Marker
      position={[lat, lon]}
      ref={markerRef}
      eventHandlers={{
        mouseover: (e) => {
          e.target.openPopup()
        },
        mouseout: (e) => {
          e.target.closePopup()
        },
      }}
      pane="windbarbPane"
    >
      <Popup>{popupContent}</Popup>
    </Marker>
  )
}

export default function WindBarbs({ meteoGeojson, selectedParameter }) {
  // console.log("WindBarbs", meteoGeojson, selectedParameter)
  const map = useMap()
  map.createPane("windbarbPane")
  map.getPane("windbarbPane").style.zIndex = 630

  let markers = []

  // loop through all routes in meteoGeojson
  for (const route of meteoGeojson.features) {
    if (route.length > 0 && selectedParameter === "GFS:FFG") {
      // loop through all Wps and compute marker positions perpendicular to waypoints
      for (let i = 0; i < route.length; i++) {
        const waypoint = route[i]
        if (!isNaN(waypoint.properties.WindSpeed[0]) && !isNaN(waypoint.properties.WindSpeed[1])) {
          const lat = waypoint.geometry.coordinates[1]
          const lon = waypoint.geometry.coordinates[0]
          const WindSpeed = waypoint.properties.WindSpeed
          const speed = Math.sqrt(WindSpeed[0] ** 2 + WindSpeed[1] ** 2)
          const direction = 180 + Math.atan2(WindSpeed[0], WindSpeed[1]) * (180 / Math.PI) // in degrees

          const nextWaypoint = i < route.length - 1 ? route[i + 1] : null
          const referenceWaypoint = i > 0 ? route[i - 1] : nextWaypoint

          if (!referenceWaypoint) continue

          const bearingAngle = bearing(referenceWaypoint, waypoint)
          let azimuth = bearingToAzimuth(bearingAngle)

          if (referenceWaypoint === nextWaypoint) {
            azimuth = (azimuth + 180) % 360
          }

          const zoomLevel = map.getZoom()
          // Define your reference zoom level and offset distance at that zoom level
          const referenceZoom = 10 // example value
          const referenceDistance = 10 // example value
          // Calculate the factor by which the zoom level has changed from the reference
          const zoomFactor = Math.pow(1.6, referenceZoom - zoomLevel)
          // The factor of 2 is chosen for typical map implementations like Leaflet. Adjust accordingly for others.

          const offsetDistance = referenceDistance * zoomFactor
          const perpendicularAzimuth = (azimuth + 90) % 360
          // const offsetDistance = 2 // Set the offset distance as per your needs.
          const offsetPoint = destination([lon, lat], offsetDistance, perpendicularAzimuth)

          const offsetLon = offsetPoint.geometry.coordinates[0]
          const offsetLat = offsetPoint.geometry.coordinates[1]
          markers.push(
            <WindBarbMarker
              key={`${i}-${JSON.stringify(route)}`}
              // lat={offsetLat}
              // lon={offsetLon}
              lat={lat}
              lon={lon}
              speed={speed}
              direction={direction}
              waypoint={waypoint}
            />
          )
        }
      }
    }
  }
  return <>{markers}</>
}
