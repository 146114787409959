import * as React from "react"
import { useState, useEffect } from "react"

import { GeoJSON } from "react-leaflet"

//import Helper from '../../modules/Helper';//.js';
import PostGraphileApi from "../../utils/PostGraphileApi"

export default function Eca({ fetch_url, toogleButtonsOnOff }) {
  const [ecaGeojsons, setecaGeojsons] = useState(null)
  useEffect(() => {
    const graphqlQuery = "query MyQuery { ecaGeojsons { nodes { geojson}}}"
    PostGraphileApi.fetchRequest(
      fetch_url,
      PostGraphileApi.httpRequestData(graphqlQuery /*, this.props.accessToken*/),
      ".loadGraphql() fehler_1="
    )
      .then(({ data }) => {
        // console.log('###eca',data.ecaGeojsons.nodes);
        // console.warn(JSON.stringify(data))
        setecaGeojsons(data) // setData(data[graphqlKey].nodes);
      })
      .catch((error) => {
        console.log("Eca.useEffect() error")
      })
  }, [])

  return !toogleButtonsOnOff.includes("ECA") || ecaGeojsons === null ? null : (
    <GeoJSON // extends FeatureGroup
      interactive={true} // keine mouse events
      transparent={true} // hintergrund zeichnen bei alpha-transparenz-bereichen dieses bildes
      data={ecaGeojsons.ecaGeojsons.nodes[0].geojson.features}
      style={{ fillColor: "green", color: "green", weight: 2 }} // style alternative 2
      onEachFeature={(feature, layer) => {
        layer.bindPopup("<div>ECA Area: " + feature.properties.name + "</div") // interactive={false} -> inaktiv
      }}
    />
  )
}
