import React, { useState, useEffect, useRef } from "react"

import L from "leaflet"
import { Hotline } from "leaflet-hotline-react" //https://www.npmjs.com/package/leaflet-hotline-react
import { GeoJSON, LayerGroup, useMap } from "react-leaflet"

import { Box } from "@mui/material"

import Legend from "../meteo/legend"
import PostGraphileApi from "../../utils/PostGraphileApi"
import RouteHelper from "../../utils/routeHelper"
import Helper from "../../utils/Helper"

import JSON5 from "json5"

import * as turf from "@turf/turf"

import CurrentVectors from "../meteo/currentVectors"
import WindBarbs from "../meteo/windBarbs"
import WaveDirection from "../meteo/waveDirection"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

const colorBars = {
  SST: [
    { color: "#000d40", text: "< 0", values: [0] },
    { color: "#002066", text: "0-2", values: [0, 2] },
    { color: "#004d93", text: "2-4", values: [2, 4] },
    { color: "#0093cc", text: "4-6", values: [4, 6] },
    { color: "#00b9d9", text: "6-8", values: [6, 8] },
    { color: "#009f13", text: "8-10", values: [8, 10] },
    { color: "#53b900", text: "10-12", values: [10, 12] },
    { color: "#acdf00", text: "12-14", values: [12, 14] },
    { color: "#fff24d", text: "14-16", values: [14, 16] },
    { color: "#e68600", text: "16-18", values: [16, 18] },
    { color: "#d22600", text: "18-20", values: [18, 20] },
    { color: "#a60000", text: "20-22", values: [20, 22] },
    { color: "#660000", text: "22-24", values: [22, 24] },
    { color: "#330000", text: "> 24", values: [24] },
  ],
  W_HSSW: [
    { color: "white", text: "0–0.5", values: [0, 0.5] },
    { color: "#e2f9f7", text: "0.5-1", values: [0.5, 1] },
    { color: "#b1f0e9", text: "1-1.5", values: [1, 1.5] },
    { color: "#9aece2", text: "1.5-2", values: [1.5, 2] },
    { color: "#69decc", text: "2-2.5", values: [2, 2.5] },
    { color: "#85b9f1", text: "2.5-3", values: [2.5, 3] },
    { color: "#66a4ed", text: "3-3.5", values: [3, 3.5] },
    { color: "#4d85e7", text: "3.5-4", values: [3.5, 4] },
    { color: "#f5df22", text: "4-4.5", values: [4, 4.5] },
    { color: "#f1ba1e", text: "4.5-5", values: [4.5, 5] },
    { color: "#ee9656", text: "5-6", values: [5, 6] },
    { color: "#eb6055", text: "6-7", values: [6, 7] },
    { color: "#d34a3c", text: "7-8", values: [7, 8] },
    { color: "#bb3524", text: "8-9", values: [8, 9] },
    { color: "#741605", text: "9-10", values: [9, 10] },
    { color: "#ff4bff", text: "> 10", values: [10] },
  ],
  WindSpeed: [
    { color: "white", text: "0–4", values: [0, 4] },
    { color: "#9fcce6", text: "4-8", values: [4, 8] },
    { color: "#3386bf", text: "8-12", values: [8, 12] },
    { color: "#79c679", text: "12-16", values: [12, 16] },
    { color: "#33a653", text: "16-20", values: [16, 20] },
    { color: "#fff24d", text: "20-24", values: [20, 24] },
    { color: "#ffb34d", text: "24-30", values: [24, 30] },
    { color: "#ff6626", text: "30-34", values: [30, 34] },
    { color: "#e61a1a", text: "34-38", values: [34, 38] },
    { color: "#b31a6c", text: "38-42", values: [38, 42] },
    { color: "#66006c", text: "> 42", values: [42] },
  ],
  CurrentSpeed: [
    { color: "white", text: "0–0.25", values: [0, 0.25] },
    { color: "#c6e6bf", text: "0.25–0.5", values: [0.25, 0.5] },
    { color: "#99d993", text: "0.5-0.75", values: [0.5, 1] },
    { color: "#6cbf6c", text: "0.75-1", values: [0.75, 1] },
    { color: "#ffff40", text: "1-1.25", values: [1, 1.25] },
    { color: "#ffbf2d", text: "1.25-1.5", values: [1.25, 1.5] },
    { color: "#f9802d", text: "1.5-1.75", values: [1.5, 1.75] },
    { color: "#d9332d", text: "1.75-2", values: [1.75, 2] },
    { color: "#a61a26", text: "2-2.25", values: [2, 2.25] },
    { color: "#730d20", text: "2.25-2.5", values: [2.25, 2.5] },
    { color: "#46001a", text: "2.5-2.75", values: [2.5, 2.75] },
    { color: "#8f2a8f", text: "2.75-3", values: [2.75, 3] },
    { color: "#ff4bff", text: "> 3", values: [3] },
  ],
}

function findColorFromCBar(feature, colorObject, selectedParameter) {
  // if selectedParameter has two components(speed) compute total speed
  if (feature.properties[selectedParameter].length > 1) {
    const speed = Math.sqrt(
      feature.properties[selectedParameter][0] ** 2 + feature.properties[selectedParameter][1] ** 2
    )
    for (let i = 0; i < colorObject.length - 1; i++) {
      if (speed >= colorObject[i].values[0] && speed < colorObject[i].values[1]) {
        return colorObject[i].color
      }
    }
  } else {
    // console.log(feature.properties[selectedParameter])
    for (let i = 0; i < colorObject.length - 1; i++) {
      if (
        feature.properties[selectedParameter] >= colorObject[i].values[0] &&
        feature.properties[selectedParameter] < colorObject[i].values[1]
      ) {
        // console.log(colorObject[i].color, selectedParameter)
        return colorObject[i].color
      }
    }
  }
}

function createHotlinePalette(jsonObj, selectedParameter) {
  // console.log(jsonObj)
  const hotlinePalette = {}
  const step = 1 / (jsonObj.length + 1)
  // Loop through each object in the JSON array
  for (let i = 0; i < jsonObj.length; i++) {
    // Extract the values and color properties
    const obj = jsonObj[i]
    const values = obj.values
    const color = obj.color
    // console.log("hotlinePalette", i, values, color)
    if (i * step < 1) {
      hotlinePalette[i * step + step / 2] = color
    } else if (i * step >= 1) {
      hotlinePalette[i * step] = color
    }
  }
  return hotlinePalette
  // }
}

function MyHotline({ colorObject, selectedParameter, positions }) {
  // console.log("mymapconsumer", colorObject, selectedParameter, positions)
  const map = useMap()
  map.createPane("hotline")
  map.getPane("hotline").style.zIndex = 1004
  const hotlineRef = useRef(null)

  useEffect(() => {
    if (colorObject && selectedParameter && positions) {
      const leafletHotlineOptions = {
        weight: 20,
        min: colorObject[0].values[0],
        max: colorObject[colorObject.length - 1].values[0],
        contourWidth: 0,
        palette: createHotlinePalette(colorObject, selectedParameter),
      }

      // If there is an existing hotline, remove it
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }

      // Add the new hotline and store a reference to it
      hotlineRef.current = L.hotline(positions, leafletHotlineOptions).addTo(map)
    }

    // Clean up by removing the hotline when the component unmounts
    return () => {
      if (hotlineRef.current) {
        hotlineRef.current.removeFrom(map)
      }
    }
  }, [colorObject, selectedParameter, positions, map]) // Depend on all the variables used inside useEffect

  return null
}

function processSelectedParameter({ waypoint, selectedParameter }) {
  let parameterName
  if (selectedParameter === "GFS:FFG") {
    parameterName = "WindSpeed"
  } else if (selectedParameter === "NWW3:WHSSW") {
    parameterName = "W_HSSW"
  } else if (selectedParameter === "COP:SST") {
    parameterName = "SST"
  } else if (selectedParameter === "CurrentSpeed") {
    parameterName = "CurrentSpeed"
  }

  if (waypoint.properties[parameterName].length > 1) {
    const { coordinates } = waypoint.geometry
    const param = Math.sqrt(waypoint.properties[parameterName][0] ** 2 + waypoint.properties[parameterName][1] ** 2)
    return [coordinates[1], coordinates[0], param]
    // positions.push([coordinates[1], coordinates[0], param])
  } else {
    const { coordinates } = waypoint.geometry
    const param = waypoint.properties[parameterName]
    return [coordinates[1], coordinates[0], param]
    // positions.push([coordinates[1], coordinates[0], param])
  }
}

export default function RouteForecast({
  METEO_URL,
  forecastRoutes,
  switchForecast,
  fromPicker,
  toPicker,
  selectedParameter,
  colorObject,
  setFetchingForecast,
}) {
  // console.log("RouteForecast")
  const [meteoGeojson, setMeteoGeojson] = useState(null)

  useEffect(() => {
    if (forecastRoutes.length !== 0) {
      setFetchingForecast(true) // Set isFetching to true when starting the fetch
      const promises = forecastRoutes.map((route) => {
        route = route.points
        let waypoints = route.geojson.features.filter(
          (feature) =>
            dayjs.utc(feature.properties.timestamp).isSameOrAfter(dayjs.utc()) &&
            dayjs.utc(feature.properties.timestamp).isSameOrAfter(fromPicker) &&
            dayjs.utc(feature.properties.timestamp).isSameOrBefore(toPicker)
        )

        // get current vessel position and push into first place of waypoints if route is already active
        if (dayjs.utc(route.geojson.features[0].properties.timestamp).isSameOrBefore(dayjs.utc())) {
          const currentPosition = RouteHelper.getCurrentPlanPosition(route)
          if (currentPosition) {
            waypoints.unshift(
              turf.point([currentPosition.lng, currentPosition.lat], { timestamp: dayjs.utc().format() })
            )
          }
        }
        // get interpolated vessel position if route is not active yet
        else {
          for (let i = 1; i < route.geojson.features.length; i++) {
            // if complete route is in the future
            if (dayjs.utc(route.geojson.features[i - 1].properties.timestamp).isAfter(fromPicker)) {
              break
            }
            // if only a part of the route is in the future
            else if (dayjs.utc(route.geojson.features[i].properties.timestamp).isAfter(fromPicker)) {
              let point1 = route.geojson.features[i - 1].geometry.coordinates
              let point2 = route.geojson.features[i].geometry.coordinates
              const { speed, timeDiffInterp } = RouteHelper.calculateDynamics(
                point1,
                point2,
                route.geojson.features[i - 1].properties.timestamp,
                route.geojson.features[i].properties.timestamp,
                dayjs.utc(fromPicker).format()
              )
              let latlonAlongForecastRoute = RouteHelper.determineLegTypeAndCallFunction(
                route.geojson.features[i - 1].properties.legtype,
                point1,
                speed,
                timeDiffInterp,
                point2
              )
              waypoints.unshift(
                turf.point([latlonAlongForecastRoute.lng, latlonAlongForecastRoute.lat], {
                  timestamp: dayjs.utc(fromPicker).format(),
                })
              )
              break
            }
          }
        }
        if (waypoints.length === 0) {
          return
        }
        // check if any MultiLineStrings exist (dateline crossing) and in case find additional WPs to do reanalyse request
        let newWaypoints = []
        for (let i = 0; i < waypoints.length - 1; i++) {
          newWaypoints.push(waypoints[i]) // always push the current waypoint

          const waypoint1 = waypoints[i].geometry.coordinates
          const waypoint2 = waypoints[i + 1].geometry.coordinates
          const timestamp1 = waypoints[i].properties.timestamp
          const timestamp2 = waypoints[i + 1].properties.timestamp

          const from = turf.point(waypoint1)
          const to = turf.point(waypoint2)
          const options = { steps: 100, units: "kilometers" }
          const greatCircleLine = turf.greatCircle(from, to, options)
          // console.log("greatCircle", greatCircleLine)
          if (greatCircleLine.geometry.type === "MultiLineString") {
            // Take the last coordinates of the first LineString and the first coordinates of the second LineString
            const firstLineStringLastCoords =
              greatCircleLine.geometry.coordinates[0][greatCircleLine.geometry.coordinates[0].length - 1]
            const secondLineStringFirstCoords = greatCircleLine.geometry.coordinates[1][0]
            // timestamp interpolation
            const timeDiffP2P1 = (dayjs.utc(timestamp2) - dayjs.utc(timestamp1)) / 1000 / 60 / 60 // hours
            const lengthP2P1 = turf.length(greatCircleLine, { units: "kilometers" })
            const speedP2P1 = lengthP2P1 / timeDiffP2P1 // km/h
            const lengthInterp = turf.length(turf.lineString(greatCircleLine.geometry.coordinates[0]), {
              units: "kilometers",
            })
            const timeInterp = lengthInterp / speedP2P1
            const datelineCrossingTime =
              dayjs.utc(timestamp1).add(timeInterp, "hours").format("YYYY-MM-DDTHH:mm:ss") + "+00:00"

            newWaypoints.push(turf.point(firstLineStringLastCoords, { timestamp: datelineCrossingTime }))
            newWaypoints.push(turf.point(secondLineStringFirstCoords, { timestamp: datelineCrossingTime }))
          }
        }
        newWaypoints.push(waypoints[waypoints.length - 1])
        waypoints = newWaypoints.map((feature, index) => ({
          id: index + 1,
          number: index + 1,
          timestamp: dayjs.utc(feature.properties.timestamp).format(),
          lonlat: feature.geometry.coordinates,
        }))

        // use route_id to mark 1 plan and 2 suggest
        const graphqlQuery = `
        query MyQuery {
          wwPdsRoutingTest(request: {
          route_id: ${route.routeType === "plan" ? 1 : route.routeType === "suggest" ? 2 : undefined} 
          forecast_params: {
            SST: ["°C","integer"],
            U: ["kn","numeric(5,2)"],
            V: ["kn","numeric(5,2)"],
            UCUR: ["kn","numeric(5,2)"],
            VCUR: ["kn","numeric(5,2)"],
            W_HSSW: ["m","numeric(5,2)"],
            W_DMEAN: ["°","numeric(5,2)"]
          },
          waypoints: ${JSON5.stringify(waypoints).replace(/'/g, '"')}
        })}`
        return PostGraphileApi.fetchRequest(
          METEO_URL,
          PostGraphileApi.httpRequestData(graphqlQuery),
          ".loadGraphql() fehler_1="
        )
      })
      const filteredPromises = promises.filter(Boolean)

      // console.log(`start ${filteredPromises.length} meteoDB fetches`)
      const t0 = performance.now()

      Promise.all(filteredPromises)
        .then((responses) => {
          const t1 = performance.now()
          // console.log(`meteo fetch took ${(t1 - t0) / 1000} seconds.`, responses)
          let meteoResponse = {
            type: "FeatureCollection",
            features: Array(filteredPromises.length - 1).fill(null), // Pre-allocate space for each set of features
          }
          responses.forEach(({ data }, routeIndex) => {
            // console.log(data, routeIndex)
            const features = data.wwPdsRoutingTest.waypoints.map((waypoint) => ({
              type: "Feature",
              geometry: { type: "Point", coordinates: waypoint.lonlat },
              properties: {
                id: waypoint.id,
                number: waypoint.number,
                routeType: `${
                  data.wwPdsRoutingTest.route_id === 1
                    ? "plan"
                    : data.wwPdsRoutingTest.route_id === 2
                    ? "suggest"
                    : undefined
                }`,
                timestamp: waypoint.timestamp,
                SST: waypoint.SST,
                WindSpeed: [waypoint.U, waypoint.V],
                CurrentSpeed: [waypoint.UCUR, waypoint.VCUR],
                W_HSSW: waypoint.W_HSSW,
                W_DMEAN: waypoint.W_DMEAN,
              },
            }))
            meteoResponse.features[routeIndex] = features // Set the features at the correct index
          })
          // console.log("meteoGeojson", meteoGeojson)
          setMeteoGeojson(meteoResponse)
          setFetchingForecast(false)
        })
        .catch((error) => {
          console.log("Meteo.useEffect() error")
          setFetchingForecast(false)
        })
    }
  }, [forecastRoutes, switchForecast])

  const layers = []
  if (meteoGeojson) {
    // loop through all routes in reanalyseGeojson¸process vector data and check for MultiLineString (dateline crossing)
    // in case of Dateline Crossing construct subarrays to render multiple Hotlines
    let allPositions = []
    for (const route of meteoGeojson.features) {
      let positions = []
      let processedSelectedCoordinates = null
      for (let i = 0; i < route.length - 1; i++) {
        const waypoint1 = route[i].geometry.coordinates
        const waypoint2 = route[i + 1].geometry.coordinates

        const from = turf.point(waypoint1)
        const to = turf.point(waypoint2)
        const options = { steps: 100, units: "kilometers" }
        const greatCircleLine = turf.greatCircle(from, to, options)
        if (greatCircleLine.geometry.type === "LineString") {
          processedSelectedCoordinates = processSelectedParameter({ waypoint: route[i], selectedParameter })
          positions.push(processedSelectedCoordinates)
          // console.log("linestring", processedSelectedCoordinates, positions)
        } else if (greatCircleLine.geometry.type === "MultiLineString") {
          processedSelectedCoordinates = processSelectedParameter({ waypoint: route[i], selectedParameter })
          positions.push(processedSelectedCoordinates)
          if (positions.length > 0) {
            let filteredPositions = positions.filter(
              (entry) => entry[2] !== "-" && !isNaN(entry[2]) && entry[2] !== null
            )
            allPositions.push(filteredPositions)
          }
          // allPositions.push(positions)
          // console.log("positions", positions, allPositions)
          positions = []
        }
      }
      // process and push last waypoint
      const processedLastWaypoint = processSelectedParameter({ waypoint: route[route.length - 1], selectedParameter })
      positions.push(processedLastWaypoint)
      if (positions.length > 0) {
        let filteredPositions = positions.filter((entry) => entry[2] !== "-" && !isNaN(entry[2]) && entry[2] !== null)
        allPositions.push(filteredPositions)
      }

      // let positions = []
      // // loop through all Wps in route and perform
      // for (const waypoint of route) {
      //   // Process features if selectedParameter has two components(speed) compute total speed
      //   if (waypoint.properties[selectedParameter].length > 1) {
      //     const { coordinates } = waypoint.geometry
      //     const param = Math.sqrt(
      //       waypoint.properties[selectedParameter][0] ** 2 + waypoint.properties[selectedParameter][1] ** 2
      //     )
      //     positions.push([coordinates[1], coordinates[0], param])
      //   } else {
      //     const { coordinates } = waypoint.geometry
      //     const param = waypoint.properties[selectedParameter]
      //     positions.push([coordinates[1], coordinates[0], param])
      //   }
      // }

      // filter out "undefined" values
      // positions = positions.filter((entry) => entry[2] !== "-" && !isNaN(entry[2]) && entry[2] !== null)
      // console.log("positions", positions)
      // console.log("forecast", meteoGeojson)
      if (switchForecast && meteoGeojson !== null) {
        layers.push(
          <LayerGroup key={`routeForecast-layerGroup-${selectedParameter}-${JSON.stringify(route)}`}>
            {/* <Hotline
              key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(
                positions
              )}`}
              positions={positions} //The positions to draw the line for. An array of [lat, lon, value]
              weight={12} //The width of the drawn line. should be related to zoomLevel
              min={colorObject[0].values[0]} //The minimal number for which the color palette shall be calculated for.
              max={colorObject[colorObject.length - 1].values[0]} //The maximal number for which the color palette shall be calculated for.
              palette={createHotlinePalette(colorObject, selectedParameter)}
            /> */}
            {/* <MyHotline
              key={`routeForecast-myHotline-${selectedParameter}-${JSON.stringify(
                createHotlinePalette(colorObject)
              )}-${JSON.stringify(positions)}`}
              colorObject={colorObject}
              selectedParameter={selectedParameter}
              positions={positions}
            /> */}
            {allPositions.map((positions, index) => (
              <MyHotline
                key={`${selectedParameter}-${JSON.stringify(createHotlinePalette(colorObject))}-${JSON.stringify(
                  positions
                )}`}
                colorObject={colorObject}
                selectedParameter={selectedParameter}
                positions={positions}
              />
            ))}
            <GeoJSON
              key={`routeForecast-GeoJSON-${selectedParameter}-${JSON.stringify(colorObject)}-${JSON.stringify(route)}`}
              data={route}
              pointToLayer={(feature, latlon) => {
                // console.log(feature)
                if (selectedParameter === "SST") {
                  let featuresLayer = L.circleMarker(latlon, {
                    pane: "markerPane",
                    radius: 6,
                    fillOpacity: 1,
                    color: `${
                      feature.properties.routeType === "plan"
                        ? "green"
                        : feature.properties.routeType === "suggest"
                        ? "red"
                        : undefined
                    }`,
                    stroke: false,
                  })
                  return featuresLayer
                }
              }}
              onEachFeature={(feature, layer) => {
                if (selectedParameter === "SST") {
                  const timestamp = dayjs.utc(feature.properties.timestamp).format("ddd D MMM YYYY HH:mm UTC")
                  const coordinates = Helper.ConvertDDToDM(feature.geometry.coordinates).join("&nbsp;&nbsp;&nbsp;")
                  let popupContent = `${timestamp}<br/>${coordinates}<br/><br/><b>Sea Temperature:</b> ${feature.properties.SST} \u00B0C`
                  layer.bindPopup(popupContent)
                  layer.on({
                    mouseover: (e) => {
                      layer.openPopup(e.latlng)
                    },
                    mouseout: (e) => {
                      layer.closePopup()
                    },
                    click: (e) => {},
                  })
                }
              }}
            />
            <WindBarbs
              key={`routeForecast-windbarbs-${selectedParameter}-${JSON.stringify(colorObject)}-${JSON.stringify(
                route
              )}`}
              meteoGeojson={meteoGeojson}
              selectedParameter={selectedParameter}
            />
            <CurrentVectors
              key={`routeForecast-currentVectors-${selectedParameter}-${JSON.stringify(colorObject)}-${JSON.stringify(
                route
              )}`}
              meteoGeojson={meteoGeojson}
              selectedParameter={selectedParameter}
            />
            <WaveDirection
              key={`routeForecast-waveDirection-${selectedParameter}-${JSON.stringify(colorObject)}-${JSON.stringify(
                route
              )}`}
              meteoGeojson={meteoGeojson}
              selectedParameter={selectedParameter}
            />
          </LayerGroup>
        )
      }
    }
  }
  return <Box>{layers}</Box>
}
