import arc from "arc"
import * as turf from "@turf/turf"

import RouteHelper from "./routeHelper"
import { line } from "d3"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const Helper = {
  ConvertDDToDMS(D) {
    let lat = D[1]
    let lon = D[0]
    let lat_dir = "N "
    let lon_dir = "E"
    if (lat < 0) {
      lat_dir = "S "
    }
    if (lon < 0) {
      lon_dir = "W"
    }
    return [
      [
        0 | (lat = lat < 0 ? -lat : lat),
        "\u00B0 ",
        0 | (((lat = (lat < 0 ? -lat : lat) + 1e-4) % 1) * 60),
        "' ",
        0 | (((lat * 60) % 1) * 60),
        '" ',
        lat_dir,
      ].join(""),
      [
        0 | (lon = lon < 0 ? -lon : lon),
        "\u00B0 ",
        0 | (((lon = (lon < 0 ? -lon : lon) + 1e-4) % 1) * 60),
        "' ",
        0 | (((lon * 60) % 1) * 60),
        '" ',
        lon_dir,
      ].join(""),
    ]
  },

  ConvertDDToDM(D) {
    let lat = D[1]
    let lon = D[0]
    let lat_dir = "N"
    let lon_dir = "E"

    if (lat < 0) {
      lat_dir = "S"
      lat = -lat
    }

    if (lon < 0) {
      lon_dir = "W"
      lon = -lon
    }

    let latDegree = Math.floor(lat)
    let latMinute = (lat - latDegree) * 60

    let lonDegree = Math.floor(lon)
    let lonMinute = (lon - lonDegree) * 60

    return [
      latDegree + "\u00B0 " + latMinute.toFixed(1) + "' " + lat_dir,
      lonDegree + "\u00B0 " + lonMinute.toFixed(1) + "' " + lon_dir,
    ]
  },

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const RADIUS_OF_EARTH_IN_KM = 6371

    const dLat = (lat2 - lat1) * (Math.PI / 180)
    const dLon = (lon2 - lon1) * (Math.PI / 180)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return RADIUS_OF_EARTH_IN_KM * c
  },

  newWpFilter(vesselRoutes, zoomLevel, switchReported, fromPicker, toPicker) {
    let filteredVesselData = []
    if (switchReported) {
      for (let node of vesselRoutes) {
        let newGeojson = { ...node.geojson }
        newGeojson.features = []
        // console.log("newGeoJSON", newGeojson)
        let firstDate = null
        let startIndex = null
        for (let i = 0; i < node.geojson.features.length; i++) {
          if (
            dayjs.utc(node.geojson.features[i].properties.timestamp).isAfter(fromPicker) &&
            newGeojson.features.length === 0
          ) {
            newGeojson.features.push(node.geojson.features[i - 1])
          } else if (
            dayjs.utc(node.geojson.features[i].properties.timestamp).isAfter(fromPicker) &&
            dayjs.utc(node.geojson.features[i].properties.timestamp).isBefore(toPicker)
          ) {
            newGeojson.features.push(node.geojson.features[i])
          } else if (dayjs.utc(node.geojson.features[i].properties.timestamp).isAfter(toPicker)) {
            newGeojson.features.push(node.geojson.features[i])
            break
          }
        }
        let filteredVessel = {
          geojson: newGeojson,
          routeType: node.routeType,
          vesselName: node.vesselName,
        }
        filteredVesselData.push(filteredVessel)
        // console.log("filteredVesselDAta", filteredVesselData)
      }
      return filteredVesselData
    } else {
      for (let node of vesselRoutes) {
        let newGeojson = { ...node.geojson }
        newGeojson.features = [node.geojson.features[node.geojson.features.length - 1]]
        let filteredVessel = {
          geojson: newGeojson,
          routeType: node.routeType,
          vesselName: node.vesselName,
        }
        filteredVesselData.push(filteredVessel)
        // console.log("filterWp", filteredVesselData)
      }
      return filteredVesselData
    }
  },

  filterWp(vesselRoutes, zoomLevel, switchReported, fromPicker, toPicker) {
    let filteredVesselData = []
    if (switchReported) {
      const zoomWpMinThreshold = {
        0: 2880,
        1: 1440,
        2: 720,
        3: 720,
        4: 360,
        5: 360,
        6: 360,
        7: 360,
        8: 360,
        9: 180,
        10: 180,
      }

      for (let node of vesselRoutes) {
        let firstDate = dayjs.utc(node.geojson.features[0].properties.timestamp)
        let updatedDate = firstDate.add(zoomWpMinThreshold[zoomLevel], "minute")
        // console.log("filterWP\n", fromPicker.format(), "\n", toPicker.format(), "\n", firstDate.format())
        let newGeojson = { ...node.geojson }
        newGeojson.features = [node.geojson.features[0]]
        for (let i = 1; i < node.geojson.features.length; i++) {
          let firstDiffDate = Math.abs(dayjs.utc(node.geojson.features[i - 1].properties.timestamp) - updatedDate)
          let secDiffDate = Math.abs(dayjs.utc(node.geojson.features[i].properties.timestamp) - updatedDate)
          // let firstDiffDate = Math.abs(
          //   dayjs(node.geojson.features[i - 1].properties.timestamp).diff(updatedDate, "minute")
          // )
          // let secDiffDate = Math.abs(dayjs(node.geojson.features[i].properties.timestamp).diff(updatedDate, "minute"))
          if (secDiffDate > firstDiffDate) {
            // console.log(secDiffDate, firstDiffDate, i, node.vesselName)
            newGeojson.features.push(node.geojson.features[i - 1])
            firstDate = dayjs.utc(node.geojson.features[i - 1].properties.timestamp)
            updatedDate = firstDate.add(zoomWpMinThreshold[zoomLevel], "minute")
          }
        }
        newGeojson.features.push(node.geojson.features[node.geojson.features.length - 1])
        let filteredVessel = {
          geojson: newGeojson,
          routeType: node.routeType,
          vesselName: node.vesselName,
        }
        filteredVesselData.push(filteredVessel)
      }
      return filteredVesselData
    } else {
      for (let node of vesselRoutes) {
        let newGeojson = { ...node.geojson }
        newGeojson.features = [node.geojson.features[node.geojson.features.length - 1]]
        let filteredVessel = {
          geojson: newGeojson,
          routeType: node.routeType,
          vesselName: node.vesselName,
        }
        filteredVesselData.push(filteredVessel)
        // console.log("filterWp", filteredVesselData)
      }
      return filteredVesselData
    }
  },

  planRouteExists(markedVesselNames, vesselPlanRoutes) {
    if (markedVesselNames.length === 0) return true
    for (let markedVesselName of markedVesselNames) {
      for (let vesselPlanRoute of vesselPlanRoutes) {
        if (
          markedVesselName.vesselName === vesselPlanRoute.points.vesselName &&
          vesselPlanRoute.points.geojson.features.length > 0
        ) {
          return true
        }
      }
    }
    return false
  },

  onMouseEnter(map) {
    if (!map) return
    map.dragging.disable()
    map.scrollWheelZoom.disable()
    map.touchZoom.disable()
  },

  onMouseLeave(map) {
    if (!map) return
    map.dragging.enable()
    map.scrollWheelZoom.enable()
    map.touchZoom.enable()
    map.doubleClickZoom.enable()
    map.doubleClickZoom.disable()
  },
}

export default Helper
