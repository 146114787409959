import React from "react"
import { useMap } from "react-leaflet"

import { Paper, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import dayjs from "dayjs"

import Helper from "../../utils/Helper"

export default function VesselTable({ selectedVessel, setIsPaperOpen, setSelectedVessel }) {
  const map = useMap()

  const report = selectedVessel.geojson.features[0].properties.albis_reports
  const sensor = selectedVessel.geojson.features[0].properties.albis_sensors
  const analyse = selectedVessel.geojson.features[0].properties.analyse
  const etaCalc = selectedVessel.geojson.features[0].properties.eta_delta_waypoints
  let etaMP4 = null
  if (selectedVessel.routeType === "plan") {
    etaMP4 = selectedVessel.geojson.features[0].properties.timestamp
  }

  const noDataAvailable = "N/A"

  const handleClosePaper = () => {
    setIsPaperOpen(false)
    setSelectedVessel(null)
    if (!map) return
    map.dragging.enable()
  }

  return (
    <Paper
      style={{
        position: "absolute",
        top: "66px",
        right: "0%",
        height: "100%",
        zIndex: 1000,
        padding: "10px",
        minWidth: "275px",
        maxWidth: "400px",
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "0 0 0px rgba(0,0,0,0)",
        borderRadius: "0px",
      }}
      onMouseEnter={() => Helper.onMouseEnter(map)}
      onMouseLeave={() => Helper.onMouseLeave(map)}
    >
      <div style={{ position: "static" }}>
        <IconButton
          onClick={handleClosePaper}
          style={{ position: "absolute", top: 0 }}
          sx={{ pl: 1.5, mt: 1.5, color: "#004f7d" }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" style={{ marginBottom: "20px", marginTop: "30px" }} sx={{ pl: 2, pt: 2 }}>
          {selectedVessel.vesselName}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Next Port
              </TableCell>
              <TableCell>{report?.nextPort_LOCODE || noDataAvailable}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                ETA Report
              </TableCell>
              <TableCell>
                {report?.plannedETA_local_ISO
                  ? dayjs.utc(report.plannedETA_local_ISO).format("ddd D MMM YYYY HH:mm UTC")
                  : noDataAvailable}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell component="th" scope="row">
                ETA MP4
              </TableCell>
              <TableCell>{etaMP4 ? dayjs.utc(etaMP4).format("ddd D MMM YYYY HH:mm UTC") : noDataAvailable}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                ETA Calc.
              </TableCell>
              <TableCell>
                {etaCalc
                  ? dayjs.utc(etaCalc[etaCalc.length - 1].eta_utc).format("ddd D MMM YYYY HH:mm UTC")
                  : noDataAvailable}
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell component="th" scope="row">
                SOG
              </TableCell>
              <TableCell>
                {sensor?.speedOverGround_kn
                  ? parseFloat(Math.round(sensor.speedOverGround_kn * 10) / 10).toFixed(1) + " kn"
                  : noDataAvailable}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                COG
              </TableCell>
              <TableCell>
                {analyse?.rhumbline_bearing !== undefined
                  ? ((bearing) => {
                      let result = Math.round(bearing)
                      return result < 0 ? result + 360 + " °" : result + " °"
                    })(analyse.rhumbline_bearing)
                  : "No data available."}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Center Draft
              </TableCell>
              <TableCell>
                {report?.draftAft_m && report?.draftForward_m
                  ? parseFloat((Math.round(((report.draftAft_m + report.draftForward_m) / 2) * 10) / 10).toFixed(1)) +
                    " m"
                  : noDataAvailable}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                M/E FCR
              </TableCell>
              <TableCell>
                {sensor?.mainEngineFuelConsumptionRate_t_per_d
                  ? parseFloat(Math.round(sensor.mainEngineFuelConsumptionRate_t_per_d * 10) / 10).toFixed(1) + " t/d"
                  : noDataAvailable}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                A/E FCR
              </TableCell>
              <TableCell>
                {sensor?.auxEngineFuelConsumptionRate_t_per_d
                  ? parseFloat(Math.round(sensor.auxEngineFuelConsumptionRate_t_per_d * 10) / 10).toFixed(1) + " t/d"
                  : noDataAvailable}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Sailing Type
              </TableCell>
              <TableCell>{report?.typeOfSailing ? report.typeOfSailing : noDataAvailable}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}
