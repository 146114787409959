import * as turf from "@turf/turf" // GIS/GeoJSON library

import { LayerGroup, GeoJSON } from "react-leaflet"
import RouteLines from "../route/RouteLines"
import RoutePoints from "../route/RoutePoints"
import RouteHelper from "../../utils/routeHelper"
import VesselIcon from "../vessel/VesselIcon"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

function pathfinderResponseToRouteFormat(pathfinderResponse) {
  const waypoints = pathfinderResponse.ETA.baseRoute.wps
  // Use a single .map to generate both LineString and Point features
  const lineAndPointFeatures = waypoints.map((wp, index, array) => {
    // Convert Unix timestamp to ISO 8601 format
    const isoTimestamp = wp.timestamp ? dayjs.unix(wp.timestamp).utc().format("YYYY-MM-DDTHH:mm:ss[+00:00]") : null
    // Create Point feature for the current waypoint
    const point = turf.point([wp.lon, wp.lat], {
      dmean: wp.dmean,
      hssw: wp.hssw,
      legType: wp.legType,
      timestamp: isoTimestamp,
      u10: wp.u10,
      ucur: wp.ucur,
      v10: wp.v10,
      vcur: wp.vcur,
    })

    // Create LineString feature, ensuring we're not on the last waypoint
    const line =
      index < array.length - 1
        ? turf.lineString(
            [
              [wp.lon, wp.lat],
              [array[index + 1].lon, array[index + 1].lat],
            ],
            {
              analyse: {
                wp1: point,
                wp2: turf.point([array[index + 1].lon, array[index + 1].lat], {
                  dmean: array[index + 1].dmean,
                  hssw: array[index + 1].hssw,
                  legType: array[index + 1].legType,
                  timestamp: isoTimestamp,
                  u10: array[index + 1].u10,
                  ucur: array[index + 1].ucur,
                  v10: array[index + 1].v10,
                  vcur: array[index + 1].vcur,
                }),
              },
            }
          )
        : null

    return { point, line }
  })

  // Construct the final object with all steps included in the return statement
  return [
    {
      points: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.point)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
      lines: {
        geojson: turf.featureCollection(lineAndPointFeatures.map((item) => item.line).filter(Boolean)),
        routeId: "Pathfinder",
        routeType: "Pathfinder",
        vesselName: "Pathfinder",
      },
    },
  ]
}

export default function PathfinderController({
  pathfinderResponse,
  mapBounds,
  sliderPosition,
  markedVesselNames,
  setCenterLatLonMap,
  setMarkedVesselNames,
  setOpenDrawer,
  setOpenVesselList,
  map,
}) {
  if (!pathfinderResponse || !pathfinderResponse.ETA || !sliderPosition.label) {
    return
  }

  let convertedRoute = pathfinderResponseToRouteFormat(pathfinderResponse)

  let vessels = RouteHelper.buildVesselsGeojson({ routes: convertedRoute, sliderPosition })
  //   console.log("vessel", vessels, sliderPosition)

  return (
    <LayerGroup key={"Pathfinder"}>
      <RoutePoints vesselRoutes={convertedRoute} mapBounds={mapBounds} />
      <RouteLines vesselRoutes={convertedRoute} mapBounds={mapBounds} />
      {vessels.map((vessel) => {
        let vesselPoints = vessel.points
        return vesselPoints.geojson.features === null || vesselPoints.geojson.features.length === null ? null : (
          <GeoJSON
            key={JSON.stringify(vesselPoints.geojson.features)}
            data={vesselPoints.geojson}
            style={{
              fillColor: RouteHelper.findRouteColor("plan"),
              color: RouteHelper.findRouteColor("plan"),
              weight: 1,
            }}
            pointToLayer={(feature, latlon) => {
              return RouteHelper.renderRotatedIcon({
                vesselPoints,
                markedVesselNames,
                setCenterLatLonMap,
                setMarkedVesselNames,
                setOpenDrawer,
                setOpenVesselList,
                map,
              })
            }}
            onEachFeature={(feature, layer) => RouteHelper.applyVesselPopup({ vesselPoints, feature, layer })}
          />
        )
      })}
    </LayerGroup>
  )
}

//   function convertToObjectFormat(data) {
//     const lineFeatures = data.geometry.coordinates
//       .map((coords, index, array) => {
//         if (index < array.length - 1) {
//           return turf.lineString([coords, array[index + 1]])
//         }
//       })
//       .filter(Boolean)
//     return [
//       {
//         lines: {
//           geojson: turf.featureCollection(lineFeatures),
//           routeId: "pathfinder",
//           routeType: "pathfinder",
//           vesselName: "pathfinder",
//         },
//         points: {
//           geojson: turf.featureCollection(data.geometry.coordinates.map(([lng, lat]) => turf.point([lng, lat]))),
//           routeId: "pathfinder",
//           routeType: "pathfinder",
//           vesselName: "pathfinder",
//         },
//       },
//     ]
//   }
