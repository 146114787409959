import React from "react"
import { useMapEvents } from "react-leaflet"
import Helper from "../../utils/Helper"

// https://angelos.dev/2021/07/mouse-coordinates-component-for-react-leaflet/
// weitere info: https://newdevzone.com/posts/how-to-get-coordinates-of-current-mouse-click-in-leaflet-react-js

function round(number, precision = 0) {
  return Math.round(number * Math.pow(10, precision) + Number.EPSILON) / Math.pow(10, precision)
}

function formatLatitude(latitude) {
  const direction = latitude > 0 ? "N" : "S"
  return `${round(Math.abs(latitude), 1)}° ${direction}`
}

function formatLongitude(longitude) {
  const direction = longitude > 0 ? "E" : "W"
  return `${round(Math.abs(longitude), 1)}° ${direction}`
}

function MouseCoordinates() {
  const [mousePoint, setMousePoint] = React.useState(null)

  const formattedCoordinates =
    mousePoint === null
      ? ""
      : [
          Helper.ConvertDDToDM([mousePoint.lng, mousePoint.lat])[0],
          Helper.ConvertDDToDM([mousePoint.lng, mousePoint.lat])[1],
        ]

  React.useEffect(
    function copyToClipboard() {
      function handleCtrlCKeydown(event) {
        if (event.key === "c" && event.ctrlKey && formattedCoordinates.length > 0 && navigator.clipboard) {
          navigator.clipboard.writeText(formattedCoordinates)
        }
      }

      document.addEventListener("keydown", handleCtrlCKeydown)

      return function cleanup() {
        document.removeEventListener("keydown", handleCtrlCKeydown)
      }
    },
    [formattedCoordinates]
  )

  useMapEvents({
    mousemove(event) {
      setMousePoint(event.latlng)
    },
    mouseout() {
      setMousePoint(null)
    },
  })

  if (formattedCoordinates.length === 0) return null
  return (
    <div
      key={"MouseCoordinates inner div"}
      className="leaflet-control-attribution leaflet-control"
      style={{ fontSize: "12px" }}
    >
      {formattedCoordinates.map((coordinate, index) => (
        <span key={index}>
          {coordinate}
          {index === 0 && <>&nbsp;&nbsp;&nbsp;</>}
        </span>
      ))}
    </div>
  )
}

export default MouseCoordinates
